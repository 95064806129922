import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Submit to Vendor "),_c(VSpacer),_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close")])],1),_c(VCardSubtitle,{domProps:{"textContent":_vm._s(_vm.request.summary)}}),(_vm.config.loading)?_c(VCardText,{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":""}})],1):_c(VCardText,[_c(VSheet,{staticClass:"px-4 pt-6 pb-2",attrs:{"outlined":"","rounded":""}},[_c('RequestItems',{attrs:{"request":_vm.pr,"elevation":""}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.submit}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-cart-arrow-right")]),_vm._v(" Submit to Vendor ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }