<template>
    <div>
        <v-sheet
            v-if="files.length"
            class="px-3 pb-3 pt-1 mb-n1"
            outlined
            rounded="t-lg"
        >
            <v-row no-gutters>
                <v-col class="body-1 grey--text">
                    {{ fileCountLabel }} to upload:
                </v-col>
            </v-row>
            <v-list
                rounded
            >
                <template v-for="(f, i) in files">
                    <v-hover v-slot:default="{ hover }" :key="i">
                        <v-row
                            :class="['rounded-lg pa-1 d-flex align-center', {'grey lighten-3': hover}]"
                            dense
                        >
                            <v-col cols="auto">
                                <v-icon
                                    v-text="attachmentIcon(f.name)"
                                />
                            </v-col>
                            <v-col v-text="f.name" />
                            <v-col cols="auto">
                                <v-menu
                                    absolute
                                    offset-y
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            v-on="on"
                                            v-text="cmpAttachmentTypes[f.type || 'Other'].name"
                                            small
                                        />
                                    </template>
                                    <v-list
                                        dense
                                    >
                                        <v-list-item
                                            v-for="(v, k, t) in cmpAttachmentTypes"
                                            :key="`att${t}`"
                                            @click="chgAttachmentType(i, k)"
                                        >
                                            <v-list-item-title
                                                v-text="v.name"
                                            />
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn
                                    color="error"
                                    @click="removeFile(i)"
                                    icon
                                    small
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-hover>
                </template>
            </v-list>
            <div class="text-center">
                <v-btn
                    class="my-2"
                    color="primary"
                    :loading="loadingUpload"
                    :disabled="loadingUpload"
                    @click="$emit('upload', getFormData())"
                    small
                >
                    Upload {{ fileCountLabel }}
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet
            id="dropzone"
            ref="dzone"
            tabindex="0"
            title="Click to upload a file"
            :class="['pa-3', {'dragover-active': config.dragover}]"
            :rounded="!files.length ? 'lg' : 'lg-b'"
            @dragenter.prevent="config.dragover = true"
            @dragover.prevent="config.dragover = true"
            @dragleave.prevent="config.dragover = false"
            @drop.prevent="dropEvent"
            @click="$refs.upload.click()"
            @keypress.enter="$refs.upload.click()"
            outlined
        >
            <v-row
                justify="center"
                class="mx-2"
                no-guttters
            >
                <v-icon
                    color="grey" 
                    size="50"
                >mdi-cloud-upload-outline</v-icon>
            </v-row>
            <v-row
                justify="center"
                class="mx-5 mt-2 body-1 grey--text text--darken-2"
                no-gutters
            >
                Drop or click to upload file
            </v-row>
            <input
                id="fileUpload"
                ref="upload"
                type="file"
                :multiple="multiple"
                @change="changeEvent"
            />
        </v-sheet>
    </div>
</template>
<script>

import { reactive, ref, computed } from '@vue/composition-api';

import stringHelper from '@/composables/common/string';
import attachmentHelper from '@/composables/common/attachment';


export default {
    name: 'FileDrop',
    props: {
        multiple: {
            type: Boolean,
            default: true
        },
        attachmentTypes: {
            type: Object,
            default() {
                return {}
            }
        },
        loadingUpload: {
            type: Boolean,
            default: false
        }
    },
    emits: ['selectFiles', 'upload'],
    setup(props, { emit }) {

        const config = reactive({
            dragover: false
        });

        const { pluralize } = stringHelper();
        const { attachmentIcon } = attachmentHelper();

        const files = ref([]);

        const changeEvent = (e) => {
            const target = e.target;
            if (target.files) {
                filesSelected(target.files);
            }
        };

        const dropEvent = (e) => {
            if (e.dataTransfer) {
                filesSelected(e.dataTransfer.files);
            }
        };
        
        const fileCountLabel = computed(() => pluralize('file', files.value.length));

        const getFormData = () => {
            // combine the attachment data and text data into a FormData object to be sent to server
            let formData = new FormData();

            for (const a of files.value) {
                formData.append('files[]', a.file, a.name);
            }

            formData.append('data', JSON.stringify({
                files: files.value
            }));

            return formData;
        };


        const filesSelected = (f) => {
            config.dragover = false;
            
            files.value = files.value.concat([...f].map(x => {
                return {
                    name: x.name,
                    file: x
                }
            }));
            emit('selectFiles', f);
        };

        const removeFile = (i) => {
            files.value.splice(i, 1);
        };

        const chgAttachmentType = (i, k) => {
            files.value.splice(i, 1, {...files.value[i], type: k});
        };

        const cmpAttachmentTypes = computed(() => {
            return {
                // uncat: {
                //     name: 'Uncategorized'
                // },
                ...props.attachmentTypes
            };
        });

        const reset = () => {
            files.value = [];
        };

        return {
            config,
            changeEvent,
            dropEvent,
            files,
            pluralize,
            attachmentIcon,
            removeFile,
            cmpAttachmentTypes,
            chgAttachmentType,
            fileCountLabel,
            getFormData,
            reset
        }
    }
};
</script>
<style scoped>
#dropzone {
    cursor: pointer;
    outline: 0;
}
#fileUpload {
    display: none;
}
</style>