<template>
    <v-dialog
        v-model="config.showDialog"
        transition="dialog-bottom-transition"
        :width="!fullscreen ? width : width"
        :fullscreen="fullscreen"
        hide-overlay
        eager
    >
        <v-toolbar
            color="grey darken-3"
            dark
            dense
        >
            <v-spacer />
            <v-btn
                icon
                dark
                small
                @click="config.showDialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <div
            id="pspdfkit"
            :style="`'width: 100%;height: ${!fullscreen ? '800px' : '100%'}`"
        />
    </v-dialog>
</template>
<script>

import PSPDFKit from 'pspdfkit';

import { reactive } from '@vue/composition-api';

export default {
    name: 'PDFViewer',
    props: {
        fullscreen: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: '800px'
        },
        width: {
            type: String,
            default: '900'
        }
    },
    setup() {
        const config = reactive({
            showDialog: false
        });

        const loadPDF = async (pdf) => {
            config.showDialog = true;
            PSPDFKit.unload('#pspdfkit'); // unload any existing containers
            let pspdfInstance = await PSPDFKit.load({
                locale: 'en',
                baseUrl: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PSPDFKIT_BASEURL : process.env.VUE_APP_PSPDFKIT_BASEURL_DEV,
                container: '#pspdfkit',
                toolbarItems: [
                    {'type': 'pan'},
                    {'type': 'zoom-out'},
                    {'type': 'zoom-in'},
                    {'type': 'zoom-mode'},
                    {'type':'print'},
                    {'type': 'export-pdf'}
                ],
                styleSheets: [`${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASEURL : process.env.VUE_APP_BASEURL_DEV}/static/css/pspdfkit.css`],
                document: pdf,
                licenseKey: `${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PSPDFKIT_LICENSE_KEY : process.env.VUE_APP_PSPDFKIT_LICENSE_KEY_DEV}`,
            });
            pspdfInstance.setViewState(v => v.set('readOnly', true));
        };

        return {
            config,
            loadPDF
        }
    }
};
</script>