<template>
    <v-card>
        <v-card-title>
            Submit to Purchasing
            <v-spacer />
            <v-icon
                @click="$emit('close')"
            >mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle
            class="py-0"
            v-text="request.summary"
        />
        <v-card-text
            v-if="config.loading"
        >
            <ProgressLoader />
        </v-card-text>
        <v-card-text class="pt-0" v-else-if="pr.pk">
            <v-sheet>
                <div :class="config.hClass">
                    <v-icon color="warning darken-1" left>mdi-basket-outline</v-icon>
                    Purchase Request Items
                </div>
                <RequestQuote
                    ref="reqQuote"
                    class="mb-4"
                    :request="pr"
                    @pendingChange="config.disableApprove=$event"
                    outlined
                />


                <div :class="config.hClass">
                    <v-icon color="warning darken-1" left>mdi-paperclip</v-icon>
                    Attachments
                </div>
                <RequestAttachments
                    class="mb-4"
                    :request="pr"
                />

                <div :class="config.hClass">
                    <v-icon color="warning darken-1" left>mdi-note-multiple-outline</v-icon>
                    Purchase Order
                </div>
                <v-text-field
                    class="mb-4"
                    label="PO #"
                    hide-details
                    dense
                    single-line
                />



                <div :class="config.hClass">
                    <v-icon color="warning darken-1" left>mdi-message-outline</v-icon>
                    Comment
                </div>
                <MentionWidget
                    ref="newComment"
                    default-text="Comment (optional)"
                    @valid="$emit('newCommentValid', $event)"
                    outlined
                />
            </v-sheet>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="green darken-1"
                @click="submitToPurchasing"
                :disabled="config.disableApprove"
                text
                block
                large
            >
                <v-icon left>mdi-check-circle</v-icon>
                Submit to Purchasing
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>

import { reactive, onMounted } from '@vue/composition-api';


import RequestQuote from '@/components/purchasing/request/RequestQuote';
import RequestAttachments from '@/components/purchasing/request/RequestAttachments';
import MentionWidget from '@/components/common/MentionWidget';

import ProgressLoader from '@/components/common/ProgressLoader';

import requestHelper from '@/composables/purchasing/request';

export default {
    name: 'RequestITApprove',
    components: {
        RequestQuote,
        RequestAttachments,
        MentionWidget,
        ProgressLoader
    },
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        },
        filter: {
            type: String
        }
    },
    emits: ['close'],
    setup(props, { emit, root, refs }) {

        const store = root.$store;

        const config = reactive({
            hClass: 'font-weight-light text-h6 warning--text text--darken-1 mb-2 d-flex align-center',
            loading: false,
            disableApprove: false
        });

        const {
            loadRequest,
            loadedRequest
        } = requestHelper();

        const load = async () => {
            config.loading = true;

            await loadRequest(props.request.pk);

            config.loading = false;
        };

        const submitToPurchasing = async () => {

            store.dispatch('purchasing/requestAction', {
                pk: props.request.pk,
                pr_pk: props.request.pr_pk,
                filter: props.filter,
                action: 'it_approve',
                comment: refs.newComment.getComment(),
                ...refs.reqQuote.getChanges()
            });

            emit('close');
        };

        onMounted(() => load());

        return {
            config,
            pr: loadedRequest,
            submitToPurchasing
        }
    }
};
</script>