import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" IT Approval "),_c(VSpacer),_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close")])],1),_c(VCardSubtitle,{staticClass:"py-0",domProps:{"textContent":_vm._s(_vm.request.summary)}}),(_vm.config.loading)?_c(VCardText,[_c(VContainer,[_c(VRow,{staticStyle:{"height":"200px"},attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"6"}},[_c(VProgressLinear,{attrs:{"color":"primary","height":"7","indeterminate":"","rounded":""}})],1)],1)],1)],1):(_vm.pr.pk)?_c(VCardText,{staticClass:"pt-0"},[_c(VSheet,[_c('div',{class:_vm.config.hClass},[_c(VIcon,{attrs:{"color":"warning darken-1","left":""}},[_vm._v("mdi-note-multiple-outline")]),_vm._v(" Purchase Request Items ")],1),_c('RequestQuote',{ref:"reqQuote",staticClass:"mb-4",attrs:{"request":_vm.pr,"outlined":""},on:{"pendingChange":function($event){_vm.config.disableApprove=$event}}}),_c('div',{class:_vm.config.hClass},[_c(VIcon,{attrs:{"color":"warning darken-1","left":""}},[_vm._v("mdi-paperclip")]),_vm._v(" Attachments ")],1),_c('RequestAttachments',{staticClass:"mb-4",attrs:{"request":_vm.pr}}),_c('div',{class:_vm.config.hClass},[_c(VIcon,{attrs:{"color":"warning darken-1","left":""}},[_vm._v("mdi-message-outline")]),_vm._v(" Comment ")],1),_c('MentionWidget',{ref:"newComment",attrs:{"default-text":"Comment (optional)","outlined":""},on:{"valid":function($event){return _vm.$emit('newCommentValid', $event)}}})],1)],1):_vm._e(),_c(VCardActions,[_c(VBtn,{attrs:{"color":"green darken-1","disabled":_vm.config.disableApprove,"text":"","block":"","large":""},on:{"click":_vm.itApprove}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-check-circle")]),_vm._v(" IT Approve Purchase Request ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }