<template>
    <v-form
        v-if="configuredItem.item"
        v-model="formValid"
        ref="form"
        lazy-validation
    >
        <div
            v-if="configuredItem.item.related['Software']"
            class="mb-5"
        >
            <div class="text-h5 font-weight-light mb-1">Additional Software</div>
            <!-- <pre v-text="configuredItem.config && configuredItem.item.related['Software'].filter(x => configuredItem.config['Software'].some(y => y.slug === x.related_item.slug)).map(x => x.related_item)" /> -->
            <v-item-group
                :value="configuredItem.config && configuredItem.config['Software'] && configuredItem.item.related['Software'].filter(x => configuredItem.config['Software'].some(y => y.slug === x.related_item.slug)).map(x => x.related_item)"
                @change="selectConfig('Software', $event)"
                multiple
            >
                <div
                    v-for="(relItem, i) in configuredItem.item.related['Software']"
                    :key="i"
                >
                    <v-item
                        v-slot="{ active, toggle }"
                        :value="relItem.related_item"
                    >
                        <v-sheet
                            class="mb-2"
                            :style="`cursor: pointer;${active ? 'border: 2px solid #0070c9' : 'padding: 1px'}`"
                            :ripple="false"
                            @click="toggle"
                            outlined
                            rounded
                        >
                            <v-row class="pa-2 d-flex align-center">
                                <v-col cols="2">
                                    <v-img
                                        height="40"
                                        :src="relItem.related_item.image"
                                        contain
                                    />
                                </v-col>
                                <v-col class="text-h6 font-weight-light" v-text="relItem.related_item.name" />
                                <v-col class="text-h6 font-weight-light text-right">
                                    + ${{ fmtNumber(relItem.related_item.est_price) }}
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-item>
                </div>
            </v-item-group>
        </div>
        <div class="text-h5 font-weight-light mb-1">Software Notes</div>
        <v-textarea
            :value="configuredItem.config && configuredItem.config['Software Notes']"
            @change="selectConfig('Software Notes', $event)"
            outlined
            hide-details
        />
    </v-form>
</template>
<script>

import { ref } from '@vue/composition-api';
import numberHelper from '@/composables/common/number';
import useItem from '@/composables/purchasing/useItem';

export default {
    name: 'SoftwareStep',
    components: {},
    props: {},
    setup(props, context) {

        const { configuredItem, selectConfig } = useItem(context);

        const { fmtNumber } = numberHelper();

        const formValid = ref(true);

        const validate = () => true;

        return {
            configuredItem,
            formValid,
            fmtNumber,
            validate,
            selectConfig
        }
    },
};
</script>