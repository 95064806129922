<template>
    <v-dialog
        v-model="config.showDialog"
        width="unset"
        persistent
    >
        <template
            v-if="buttonText"
            v-slot:activator="{ on, attrs }"
        >
            <v-btn
                v-bind="attrs"
                v-on="on"
                :color="buttonColor"
                :large="buttonLarge"
                :x-large="buttonXlarge"
                :outlined="buttonOutlined"
                :loading="buttonLoading"
                :disabled="buttonDisabled"
                :block="buttonBlock"
            >
                <v-icon
                    v-if="buttonIcon"
                    v-text="buttonIcon"
                    left
                />
                {{ buttonText }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title
                v-text="title"
            />
            <slot name="dialog" />
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    @click.stop="config.showDialog = false"
                    text
                >
                    Cancel
                </v-btn>
                <slot name="middleButton" />
                <v-btn
                    :color="confirmButtonColor"
                    :disabled="confirmButtonDisabled"
                    @click.stop="confirm"
                    text
                >
                    <v-icon
                        v-if="confirmButtonIcon"
                        v-text="confirmButtonIcon"
                    /> {{ confirmButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { reactive, ref } from '@vue/composition-api';

export default {
    name: 'ConfirmDialog',
    props: {
        title: {
            type: String
        },
        buttonText: {
            type: String,
        },
        buttonLarge: {
            type: Boolean
        },
        buttonXlarge: {
            type: Boolean,
        },
        buttonOutlined: {
            type: Boolean,
        },
        buttonColor: {
            type: String,
        },
        buttonIcon: {
            type: String,
        },
        buttonBlock: {
            type: Boolean,
        },
        buttonLoading: {
            type: Boolean,
        },
        buttonDisabled: {
            type: Boolean,
        },
        confirmButtonText: {
            type: String,
        },
        confirmButtonIcon: {
            type: String,
        },
        confirmButtonColor: {
            type: String,
            default: 'red darken-1',
        },
        confirmButtonDisabled: {
            type: Boolean
        }
    },
    emits: ['confirm'],
    setup(props, { emit }) {

        const config = reactive({
            showDialog: false
        });

        const payload = ref({});

        const show = (p) => {
            payload.value = p;
            config.showDialog = true;
        };

        const close = () => {
            payload.value = {};
            config.showDialog = false;
        };

        const confirm = () => {
            config.showDialog = false;
            emit('confirm', payload.value);
        };

        return {
            config,
            confirm,
            show,
            close,
        }
    }
};
</script>