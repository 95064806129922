import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCardTitle,{staticClass:"headline mb-2"},[_c(VSpacer),_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close")])],1),_c(VCardText,{staticClass:"text-center"},[_c('div',{staticClass:"text-h6 font-weight-light green--text text--darken-2"},[_c(VIcon,{attrs:{"color":"green darken-2","size":"50"}},[_vm._v("mdi-cart-arrow-down")]),_vm._v(" Item(s) added to purchase request. ")],1),_c('div',{staticClass:"mt-10"},[_c(VBtn,{staticClass:"mr-4",attrs:{"color":"primary","to":{name: 'purchasingSubmit'},"outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-right-circle")]),_vm._v(" Submit Purchase Request ")],1),_c(VBtn,{attrs:{"color":"primary","to":{name: 'purchasingCart'},"outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-cart")]),_vm._v(" View Purchase Cart ")],1)],1)]),_c(VCardActions)],1)}
var staticRenderFns = []

export { render, staticRenderFns }