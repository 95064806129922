<template>
    <v-dialog
        v-model="config.showDialog"
        max-width="1000"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                @click="selectItem"
                block
                text
            >
                Select
            </v-btn>
        </template>
        <ItemStepper
            v-if="config.showDialog"
            :item="item"
            :loading="config.loading"
            @cancel="cancel"
            @finish="addToPurchase"
            show-added
        />
    </v-dialog>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

import { reactive, ref, inject } from '@vue/composition-api';

import numberHelper from '@/composables/common/number';
import useItem from '@/composables/purchasing/useItem';

import ItemStepper from '@/components/purchasing/ItemStepper';

export default {
    name: 'SelectItemDialog',
    components: {
        ItemStepper
    },
    props: {
        item: {
            type: Object, 
            default() {
                return {}
            }
        },
        edit: {
            type: Boolean,
            default: false
        }
    },

    setup(props, context) {

        const store = context.root.$store;

        const { fmtNumber } = numberHelper();

        const curStep = ref(1);

        const config = reactive({
            showDialog: false,
            loading: false,
            loadingSubmit: false,
        });

        const {
            category,
            itemFull,
            configuredItem,
            configuredItemPrice,
            selectOption,
            setConfiguredItem
        } = useItem(context);

        const slugParams = inject('slugParams');


        const selectItem = async () => {
            config.loading = true;
            await store.dispatch('purchasing/loadItem', {id: props.item.pk});

            /*
            context.root.$router.push({
                name: 'purchasingCategoryItem',
                params: {
                    type: 'it',
                    category: 'accessories',
                    slug: itemFull.value.slug
                }
            });
            */

            if (!configuredItem.value.option && itemFull.value.default_option) {
                selectOption(itemFull.value.options.find(x => x.pk === itemFull.value.default_option));
            }

            config.loading = false;
        };

        const cancel = () => {
            config.showDialog = false;
            config.addedToPurchase = false;
            curStep.value = 1;
            store.dispatch('purchasing/resetItem');
        };

        const addToPurchase = async () => {
            //generate uuid
            setConfiguredItem({id: uuidv4()});
            setConfiguredItem({item: {...configuredItem.value.item, totalPrice: configuredItemPrice.value}});
            
            await store.dispatch('purchasing/addPurchaseRequest', {
                type: slugParams.requestType,
                item: configuredItem.value
            });
        };

        return {
            config,
            itemFull,
            selectItem,
            selectOption,
            configuredItem,
            configuredItemPrice,
            cancel,
            curStep,
            fmtNumber,
            category,
            addToPurchase
        }
    },
};
</script>