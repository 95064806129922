<template>
    <v-card>
        <v-card-title>
            Submit to Vendor
            <v-spacer />
            <v-icon
                @click="$emit('close')"
            >mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle
            v-text="request.summary"
        />
        
        <v-card-text
            v-if="config.loading"
            class="text-center"
        >
            <v-progress-circular
                color="primary"
                indeterminate
            />
        </v-card-text>
        <v-card-text v-else>
            <v-sheet
                class="px-4 pt-6 pb-2"
                outlined
                rounded
            >
                <RequestItems
                    :request="pr"
                    elevation
                />
            </v-sheet>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <v-btn
                color="blue darken-1"
                @click="$emit('close')"
                text
            >
                Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                @click="submit"
                text
            >
                <v-icon left>mdi-cart-arrow-right</v-icon>
                Submit to Vendor
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>

import axios from 'axios';

import { ref, reactive, onMounted } from '@vue/composition-api';

import RequestItems from '@/components/purchasing/request/RequestItems';


export default {
    name: 'RequestSubmitToVendor',
    components: {
        RequestItems
    },
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        },
        filter: {
            type: String
        }
    },
    emits: ['close'],
    setup(props, { emit, root }) {

        const store = root.$store;

        const config = reactive({
            loading: false,
        });

        const charges = ref({});

        const pr = ref({});

        const load = async () => {
            config.loading = true;

            const response = await axios.get(`/purchasing/load_charges/${props.request.pk}/?full=1`);

            charges.value = response.data.charges.map(x => {
                return {
                    ...x.chart_string,
                    amount: x.amount,
                    percent: x.percent,
                    processed: x.processed
                };
            });

            pr.value = response.data.request;

            config.loading = false;
        };



        const submit = async () => {

            store.dispatch('purchasing/requestAction', {
                pk: props.request.pk,
                filter: props.filter,
                action: 'submit_to_vendor'
            });

            emit('close');
        };

        onMounted(() => load());

        return {
            config,
            pr,
            submit
        }
    }
};
</script>