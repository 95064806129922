<template>
    <v-row>
        <v-col
            v-if="loading"
        > 
            <v-sheet
                class="mx-auto d-flex align-center"
                height="400"
                width="700"
            >
                <v-progress-linear
                    height="5"
                    indeterminate
                    rounded
                />
            </v-sheet>
        </v-col>
        <v-col
            v-else-if="!loading && !items.length"
            class="mx-auto"
        >
            <v-sheet
                class="pa-6 text-center text-h6 font-weight-light"
                outlined
                rounded
            >
                <v-icon x-large left>mdi-information-outline</v-icon>No items found for this category!
            </v-sheet>
        </v-col>
        <v-col
            v-else
            v-for="(item, i) in items"
            xs="2" sm="6" md="4" cols="12"
            :key="i"
        >
            <v-card
                class="pt-2 d-flex flex-column"
                height="300"
                width="350"
            >
                <ItemImage
                    class="ml-2 mr-2 mb-3"
                    :item="item"
                />
                
                <v-card-title
                    v-text="item.name"
                    class="pb-0 mt-1 subtitle-2"
                />
                <v-card-subtitle
                    class="mt-1 body-1"
                    v-text="getPrice(item)"
                />
                <v-spacer />
                <v-card-actions class="mb-1">
                    <v-spacer />
                    <SelectItemDialog
                        :item="item"
                    />
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { computed } from '@vue/composition-api';
import SelectItemDialog from '@/components/purchasing/SelectItemDialog';
import ItemImage from '@/components/purchasing/ItemImage';

import useItem from '@/composables/purchasing/useItem';


export default {
    name: 'ItemList',
    components: {
        SelectItemDialog,
        ItemImage
    },
    props: {
        loading: {
            type: Boolean
        }
    },
    setup(props, context) {

        const store = context.root.$store;

        const items = computed(() => store.getters['purchasing/getItems']);

        const {
            category,
            getPrice
        } = useItem(context);

        return {
            items,
            category,
            getPrice
        }
    },
};
</script>