import { computed } from '@vue/composition-api';
import numberHelper from '@/composables/common/number';

export default function cartHelper({ root }) {
    const store = root.$store;

    const purchaseRequest = computed(() => store.getters['purchasing/getPurchaseRequest']);

    const purchaseRequestItemTotal = computed(() => store.getters['purchasing/getPurchaseRequestItemTotal']);

    const purchaseRequestDivisions = computed(() => store.getters['purchasing/getPurchaseRequestDivisions']);

    const { fmtNumber } = numberHelper();

    const loadCart = () => {
        if (!purchaseRequest.value.length) store.dispatch('purchasing/loadCart');
    };

    const getItemPrice = item => {
        let price = item.option.est_price ? parseFloat(item.option.est_price) : parseFloat(item.option.base_price);
        if (item.config && Object.keys(item.config).length !== 0) {
            for (let addOn in item.config) {
                if (addOn == "Software") {
                    item.config.Software.forEach(sw => {
                        if (sw.est_price) {
                            price += parseFloat(sw.est_price);
                        }
                    });
                    continue;
                }
                if (item.config[addOn].price) {
                    price += parseFloat(item.config[addOn].price);
                    continue;
                }
            }
        }
        if (item.accessories && Object.keys(item.accessories).length !== 0) {
            for (let acc in item.accessories) {
                let tempPrice = parseFloat(item.accessories[acc].item.est_price) * parseFloat(item.accessories[acc].qty);
                price += tempPrice;
            }
        }
        return price;
    };

    const totalPrice = computed(() => store.getters['purchasing/getTotalPrice']);

    const getTotalPrice = (opt={}) => {
        const total = totalPrice.value;
        if (total === 0 && opt.tbd) {
            // check if only tbd price
            let onlyTbd = [];
            Object.values(purchaseRequest.value).map(i => {
                onlyTbd.push(i.every(x => x.item.meta?.tbd_price));
            });

            if (onlyTbd.every(x => x)) return '$TBD';
        }
        return opt.format !== false ? fmtNumber(total, {currency: opt.currency}) : total;
    };


    const getSoftware = (item) => {
        let data = JSON.parse(JSON.stringify(item));
        if (data.config && data.config.Software) {
            return data.config.Software;
        }
        return {};
    };

    const getHardware = (item) => {
        let data = JSON.parse(JSON.stringify(item));
        if (data.config && data.config.Software) {
            delete data.config.Software;
            return data.config;
        }
        return {};
    };

    const removeItem = (id) => {
        store.dispatch('purchasing/removeCartItem', id);
    };

    const editItem = async (item) => {
        await store.dispatch('purchasing/editPurchaseRequest', {
            item: item
        });
    };

    return {
        purchaseRequest,
        totalPrice,
        purchaseRequestItemTotal,
        purchaseRequestDivisions,
        getItemPrice,
        getTotalPrice,
        getSoftware,
        getHardware,
        loadCart,
        removeItem,
        editItem
    };
}
