import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"contents"}},[_vm._l((_vm.purchaseRequest),function(v,k,i){return _c('div',{key:("pr" + i)},[_c('div',{staticClass:"text-h5 font-weight-light grey--text text--darken-2",domProps:{"textContent":_vm._s(k)}}),_vm._l((v),function(cartItem,i2){return _c('div',{key:("ci" + i2),class:[{'mb-12': !_vm.dense}, 'my-4'],style:({'background: #000': _vm.dense})},[_c('CartItem',{attrs:{"item":cartItem,"dense":_vm.dense,"editable":_vm.editable},on:{"removeItem":function($event){return _vm.$emit('removeItem', $event)},"editItem":function($event){return _vm.$emit('editItem')},"openLoc":_vm.openLoc}}),(i2 < _vm.purchaseRequestItemTotal - 1)?_c(VDivider,{class:[!_vm.dense ? 'mt-12 mb-12' : 'mt-3 mb-3', 'grey lighten-3']}):_vm._e()],1)})],2)}),_c(VDialog,{attrs:{"transition":"dialog-bottom-transition","width":"800","eager":"","scrollable":""},on:{"click:outside":_vm.closeLoc},model:{value:(_vm.config.locDlg),callback:function ($$v) {_vm.$set(_vm.config, "locDlg", $$v)},expression:"config.locDlg"}},[_c(VCard,{attrs:{"height":"700px"}},[_c(VToolbar,{attrs:{"dark":"","color":"grey darken-3"}},[_c('span',{staticClass:"text-h5 font-weight-light",domProps:{"textContent":_vm._s(_vm.config.locDlgTitle)}}),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.closeLoc}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('div',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"pspdfkit"}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }