<template>
    <v-card>
        <v-card-title>
            Finance Approval
            <v-spacer />
            <v-icon
                @click="$emit('close')"
            >mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle
            v-text="request.summary"
        />
        
        <v-card-text
            v-if="config.loading"
            class="text-center"
        >
            <v-container>
                <v-row align="center" justify="center" style="height: 200px">
                    <v-col class="text-center" cols="6">
                        <v-progress-linear
                            color="primary"
                            height="7"
                            indeterminate
                            rounded
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-text v-else>
            <v-sheet
                class="px-4 pt-6 pb-2"
                outlined
                rounded
				v-if="pr.tickets"
            >
                <RequestItems
                    :request="pr.tickets[selected]"
                    elevation
                >
                    <template v-slot:total="{ total }">
                        <div class="text-right pa-2 text-h5 orange--text text--darken-2 font-weight-light mb-10">
                            Total
                            <strong
                                class="pa-1 font-weight-bold"
                                v-text="fmtNumber(total, {currency: true})"
                            />
                        </div>
                    </template>
                </RequestItems>
                <ChartStringWidget
                    ref="cs"
                    class="pt-3"
                    v-if="charges.length"
                    v-model="charges"
                    :amount="Number(request.total_est_price)"
                    show-add
                />
            </v-sheet>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <v-btn
                color="blue darken-1"
                @click="$emit('close')"
                text
            >
                Close
            </v-btn>
            <v-btn
                color="green darken-1"
                @click="submit"
                text
            >
                <v-icon left>mdi-check-circle</v-icon>
                Finance Approve
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>


import { reactive, onMounted } from '@vue/composition-api';

import numberHelper from '@/composables/common/number';
import requestHelper from '@/composables/purchasing/request';

import ChartStringWidget from '@/components/purchasing/ChartStringWidget';
import RequestItems from '@/components/purchasing/request/RequestItems';


export default {
    name: 'RequestFinanceApprove',
    components: {
        ChartStringWidget,
        RequestItems
    },
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        },
        filter: {
            type: String
        },
        selected: {
            type: Number
        }
    },
    emits: ['close'],
    setup(props, { refs, emit, root }) {

        const store = root.$store;

        const config = reactive({
            loading: false,
        });

        const { fmtNumber } = numberHelper();

        const {
            loadRequest,
            loadedRequest: pr,
            requestCharges: charges
        } = requestHelper();

        const load = async () => {
            config.loading = true;

            await loadRequest(props.request.pr_pk, {loadCharges: true});
            
            config.loading = false;
        };

        const submit = async () => {
			console.log(props.request)
            const isValid = refs.cs.validate();

            if (!isValid) return;
			console.log(pr.value.tickets[props.selected]);
            store.dispatch('purchasing/requestAction', {
				pr_pk: props.request.pr_pk,
                pk: props.request.pk,
				jira_key: pr.value.tickets[props.selected].jira_key,
                filter: props.filter,
                action: 'finance_approve',
                chartStrings: charges.value
            });

            emit('close');
        };

        onMounted(async () => await load());

        return {
            config,
            fmtNumber,
            pr,
            submit,
            charges,
        }
    }
};
</script>