export default function statusHelper() {
    const statusColor = (s) => {
        switch (s) {
            case 'Division Review':
                return 'blue-grey'
            case 'Canceled':
                return 'red darken-2'
            case 'IT Review':
            case 'Finance Review':
                return 'orange darken-2'
            case 'Procurement':
                return 'primary'
        }
    };

    return {
        statusColor
    }
}