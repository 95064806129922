<template>
    <v-main>
        <v-container>
            <div class="text-h4 mb-6">Purchasing</div>

            <v-row>
                
                <v-col
                    v-for="(t, i) in requestTypes"
                    cols="3"
                    :key="i"
                >
                    <v-card
                        class="text-center"
                        rounded="xl"
                        :to="{name: 'purchasingType', params: {type: t.slug}}"
                    >
                        <v-card-text class="text-center">
                            <v-avatar
                                color="orange darken-2"
                                size="75"
                            >
                                <v-icon
                                    v-text="t.icon"
                                    color="white"
                                    x-large
                                />
                            </v-avatar>
                            <div class="text-h5 mt-2" v-text="t.name" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
import { onMounted, computed } from '@vue/composition-api';

export default {
    setup(props, { root }) {

        const store = root.$store;

        const requestTypes = computed(() => store.getters['purchasing/getRequestTypes']);
        
        onMounted(async () => {
            await store.dispatch('purchasing/loadRequestTypes');
        });

        return {
            requestTypes
        }
    },
};
</script>