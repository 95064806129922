import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{attrs:{"nav":""}},[_c(VSubheader,{staticClass:"overline"},[_vm._v("Categories")]),_c(VListItemGroup,{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.categories),function(cat,i){return [_c(VListItem,{key:i,attrs:{"to":{name: 'purchasingCategory', params: {to: _vm.slugParams.type, category: cat.slug}}}},[(cat.icon)?_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(cat.icon)}})],1):_vm._e(),_c(VListItemTitle,{domProps:{"textContent":_vm._s(cat.name)}})],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }