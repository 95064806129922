import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.endpoints.length)?_c('div',{staticStyle:{"display":"contents"}},[(_vm.caption)?_c('div',{staticClass:"caption",domProps:{"textContent":_vm._s(_vm.caption)}}):_vm._e(),_c(VItemGroup,{attrs:{"multiple":_vm.multiple,"value":_vm.select.length && _vm.endpoints.filter(function (x) { return _vm.select.some(function (y) { return y.pk === x.pk; }); }) || []},on:{"change":function($event){return _vm.selectEndpoint($event)}}},_vm._l((_vm.endpoints),function(ep,i){return _c('div',{key:i},[_c(VItem,{attrs:{"value":ep},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var epActive = ref.active;
var toggle = ref.toggle;
return [_c(VSheet,{staticClass:"mb-2 pa-1",style:(("cursor: pointer;" + (epActive ? 'border: 2px solid #0070c9' : 'padding: 1px'))),attrs:{"ripple":false,"outlined":"","rounded":""},on:{"click":toggle}},[_c('div',{staticClass:"pa-2",domProps:{"textContent":_vm._s(ep.full_name)}})])]}}],null,true)})],1)}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }