<template>
    <v-main>
        <v-container class="stretch-container">
            <v-row>
                <v-col>
                <div class="text-h4 mb-1" v-text="requestType.name" />
                </v-col>
                <v-col
                    class="d-flex align-end justify-end mr-5"
                    cols="auto"
                >
                    <CartWidget />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2" style="min-width: 230px">
                    <PurchaseCategories />
                </v-col>
                <v-col>
                    <template v-if="category.name">
                        <div class="text-h5" v-text="category.name" />
                        <v-divider class="mt-2 mb-4" />
                        <ItemList
                            :loading="loading"
                        />
                    </template>
                    <template v-else>
                         <v-col
                            class="mx-auto"
                        >
                            <v-sheet
                                class="pa-6 text-h6 font-weight-light d-flex align-center justify-center"
                                height="450"
                                outlined
                                rounded
                            >
                                <v-icon left>mdi-cursor-default-click-outline</v-icon>Select a category from the list 
                            </v-sheet>
                        </v-col>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
import { computed, onMounted, provide, reactive, watch, ref } from '@vue/composition-api';

import PurchaseCategories from '@/components/purchasing/PurchaseCategories'
import ItemList from '@/components/purchasing/ItemList'
import CartWidget from '@/components/purchasing/CartWidget'

export default {
    components: {
        PurchaseCategories,
        ItemList,
        CartWidget
    },
    setup(_, { root }) {

        const store = root.$store;

        const category = computed(() => store.getters['purchasing/getCategory']);

        const requestType = computed(() => store.getters['purchasing/getRequestType']);

        const loading = ref(false);

        const slugParams = reactive({
            type: computed(() => root.$route.params.type),
            category: computed(() => root.$route.params.category),
            requestType: requestType,
        });

        provide('slugParams', slugParams);

        const loadCategory = async () => {
            loading.value = true;
            if (slugParams.category) {
                await store.dispatch('purchasing/loadCategory', slugParams.category);
                await store.dispatch('purchasing/loadItems', {category: slugParams.category});
            }
            loading.value = false;
        };

        watch(slugParams, () => loadCategory());

        onMounted(async () => {
            await store.dispatch('purchasing/loadCategories', {purchaseTypeSlug: slugParams.type});
            loadCategory();
        });

        return {
            slugParams,
            category,
            loading,
            requestType
        }
    },
};
</script>