<template>
    <v-main>
        <v-container class="stretch-container">
            <div class="text-h4 mb-1 mt-3">Purchase Request Cart</div>
            <template v-if="purchaseRequestItemTotal > 0">

                <CartItems
                    @removeItem="removeItem"
                    @editItem="editCartItem"
                    editable
                />

                <CartTotal />

                <div class="ma-10 text-center pt-5">
                    <v-btn
                        color="primary"
                        :to="{name: 'purchasingSubmit'}"
                        large
                    >
                        <v-icon left>mdi-arrow-right-circle</v-icon>
                        Review &amp; Submit Purchase Request
                    </v-btn>
                </div>

            </template>
            <template v-else>
                <v-sheet
                    class="mt-3 d-flex justify-center align-center text-h6 font-weight-light grey--text"
                    height="400"
                    outlined
                    rounded
                >
                    No items in cart
                </v-sheet>
            </template>
        </v-container>
    </v-main>
</template>
<script>

import { onBeforeMount } from '@vue/composition-api';

import cartHelper from '@/composables/purchasing/cartHelper';
import useItem from '@/composables/purchasing/useItem';

import CartItems from '@/components/purchasing/cart/CartItems';
import CartTotal from '@/components/purchasing/cart/CartTotal';


export default {
    components: {
        CartItems,
        CartTotal
    },
    setup(_, context) {

        const {
            loadCart,
            purchaseRequestItemTotal,
            removeItem,
            editItem
        } = cartHelper(context);
        
        const {
            configuredItem,
            resetItem
        } = useItem(context);


        const editCartItem = () => {
            editItem(configuredItem.value);
            resetItem();
        }

        onBeforeMount(() => {
            loadCart();
        });
        
        return {
            removeItem,
            purchaseRequestItemTotal,
            editCartItem
        };
    }
};
</script>