<template>
    <v-row
        :class="[dense ? 'caption' : 'body-2', 'd-flex align-center']"
        dense
    >
        <v-col cols="auto">
            <v-icon>mdi-account</v-icon>
        </v-col>
        <v-col v-text="userText" />

        <v-col cols="auto">
            <v-icon>mdi-account-group</v-icon>
        </v-col>
        <v-col v-text="divNameOnly(assignment.division.name)" />

        <v-col>
            
            <template v-if="hasLocation">
                <v-hover v-slot="{ hover }">
                    <span
                        style="cursor: pointer"
                        :class="[{'elevation-1 rounded-lg': hover && hasLocation}, 'pa-2']"
                        @click="openLoc"
                    >
                        <v-icon
                            :color="hover ? 'green' : 'blue'"
                        >mdi-map-marker</v-icon>
                        {{ locationText }}
                    </span>
                </v-hover>
            </template>
            <template v-else-if="!hasLocation && locationText.length < 15">
                <span class="pa-2">
                    <v-icon>mdi-map-marker</v-icon>
                    {{ locationText }}
                </span>
            </template>
            <template v-else>
                <v-row no-gutters>
                    <v-col cols="auto" class="pa-2">
                        <v-icon>mdi-map-marker</v-icon>
                    </v-col>
                    <v-col
                        style="white-space: pre-line"
                        v-text="locationText"
                    />
                </v-row>
            </template>
        </v-col>
    </v-row>
</template>
<script>
import { computed } from '@vue/composition-api';

import stringHelper from '@/composables/common/string';

export default {
    components: {},
    props: {
        assignment: {
            type: Object,
        },
        dense: {
            type: Boolean,
            default: false
        }
    },
    emits: ['openLoc'],
    setup(props, { emit }) {
        const locationText = computed(() => {
            const loc = props.assignment.location;

            if (loc.building.name !== 'Remote / Other') {
                return `${loc.building.short_name}-${loc.floor}-${loc.room}`;
            } else {
                const address = loc.address2 ? `${loc.address}, ${loc.address2}` : loc.address;
                return `${address}\n${loc.city}, ${loc.state}, ${loc.zip_code}`;
            }
        });

        const userText = computed(() => {
            if (props.assignment.assignTo) {

                return (
                    props.assignment.assignTo.name ||
                    `${props.assignment.assignTo.user.first_name} ${props.assignment.assignTo.user.last_name} (${props.assignment.assignTo.user.username})`
                );
            }
            return 'Multiple Users';
        });

        const hasLocation = computed(() => props.assignment.location.annotation);

        const openLoc = () => {
            emit('openLoc', {...props.assignment.location, name: locationText.value})
        };

        const { divNameOnly } = stringHelper();

        return {
            locationText,
            userText,
            openLoc,
            divNameOnly,
            hasLocation
        };
    },
};
</script>
