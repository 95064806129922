<template>
    <v-sheet
        class="px-6 pt-6"
        outlined
        rounded
    >
        <v-timeline
            align-top
            dense
            clipped
        >
            <v-timeline-item
                class="mb-4"
                color="orange"
                icon="mdi-circle"
            >
                <v-row
                    justify="space-between"
                    class="text-h6 font-weight-light pt-1"
                >
                    <v-col cols="7" class="">
                        Request Created
                    </v-col>
                    <v-col
                        class="text-right"
                        cols="5"
                    >
                        {{ getDateFormat(request.created_at, 'PP') }}
                        <div class="caption grey--text mt-n1">
                            By {{ request.created_by.full_name || request.created_by.username }}
                        </div>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item
                v-for="event in events"
                :key="event.pk"
                small
            >
                <v-row
                    justify="space-between"
                    align="start"
                    class="text-h6 font-weight-light mt-n4"
                >
                    <v-col
                        cols="7"
                    >
                        {{ getEventName(event.event_type, event.pk) }}
                        <div
                            v-if="event.meta.from_status"
                            class="caption"
                        >
                            <v-chip
                                    :color="statusColor(event.meta.from_status)"
                                    v-text="event.meta.from_status.toUpperCase()"
                                    outlined
                                    x-small
                                    label
                                />
                            <v-icon
                                class="px-2"
                                small
                            >mdi-arrow-right</v-icon>
                            <v-chip
                                :color="statusColor(event.meta.to_status)"
                                v-text="event.meta.to_status.toUpperCase()"
                                outlined
                                x-small
                                label
                            />
                        </div>
                    </v-col>
                    <v-col
                        class="text-right"
                        cols="5"
                    >
                        {{ getDateFormat(event.created_at, 'PP') }}
                        <div class="caption grey--text mt-n1">
                            By {{ event.created_by.full_name || event.created_by.username }}
                        </div>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item
                :color="statusColor(request.status)"
                icon="mdi-star"
            >
                <v-row
                    justify="space-between"
                    align="start"
                    class="text-h6 font-weight-light mt-n2"
                >

                    <v-col
                        cols="7"
                    >
                        {{ request.status }}
                    </v-col>
                </v-row>

            </v-timeline-item>
                
        </v-timeline>
        <!--
<pre v-text="request" />
        <pre v-text="events" />-->
    </v-sheet>
</template>
<script>



import axios from 'axios';
import { ref, onMounted } from '@vue/composition-api';

import dateTimeHelper from '@/composables/common/dateTime';
import statusHelper from '@/composables/purchasing/status';


export default {
    name: 'RequestTimeline',
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        },
    },
    setup(props) {

        const { getDateFormat, getDateDistanceIso } = dateTimeHelper();
        const { statusColor } = statusHelper();


        const events = ref([]);

        const getEventName = (name, pk) => {
            if (events.value.some(x => x.event_type === name && x.pk < pk)) {
                return name.replace('Approval', 'Reapproval');
            }
            return name;
        };

        const load = async () => {

            const response = await axios.get(`/purchasing/load_events/${props.request.pk}/`);

            events.value = response.data.events;
        };

        onMounted(() => load());

        return {
            load,
            events,
            getDateFormat,
            getDateDistanceIso,
            statusColor,
            getEventName
        }
    }
};
</script>
<style scoped>
.v-timeline::before {
    top: 55px;
    height: calc(100% - 110px)
}
</style>