<template>
    <div v-if="request.notes">
        <div class="text-h6 grey--text text--darken-1 font-weight-light">Notes</div>
        <div
            class="body-2"
            style="white-space: pre-line"
            v-text="request.notes"
        />
    </div>
</template>
<script>

//import axios from 'axios';
import { reactive } from '@vue/composition-api';

export default {
    name: 'RequestNote',
    components: {},
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        }
    },
    setup() {

        const config = reactive({

        });

        return {
            config,
        }
    }
};
</script>