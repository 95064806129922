<template>
    <v-dialog
        v-model="config.showDialog"
        width="unset"
        persistent
    >
        <v-card>
			<template v-if="loading">
				<v-card-title
					v-text="loadingTitle"
				/>
				<v-card-text>
					<ProgressLoader />
				</v-card-text>
			</template>
			<template v-else>
				<v-card-title 
					v-if="finishLoadingTitle"
					v-text="finishLoadingTitle"
				/>
				<v-card-text>
					<slot name="dialog" />
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn
						:color="confirmButtonColor"
						:disabled="confirmButtonDisabled"
						@click.stop="confirm"
						text
					>
						<v-icon
							v-if="confirmButtonIcon"
							v-text="confirmButtonIcon"
						/> {{ confirmButtonText }}
					</v-btn>
				</v-card-actions>
			</template>
        </v-card>
    </v-dialog>
</template>
<script>
import { reactive, ref } from '@vue/composition-api';

import ProgressLoader from '@/components/common/ProgressLoader';

export default {
    name: 'LoaderDialog',
	components: {
		ProgressLoader
	},
    props: {
        loadingTitle: {
            type: String
        },
		finishLoadingTitle: {
			type: String
		},
		loading: {
			type: Boolean
		},
        confirmButtonText: {
            type: String,
        },
        confirmButtonIcon: {
            type: String,
        },
        confirmButtonColor: {
            type: String,
            default: 'red darken-1',
        },
        confirmButtonDisabled: {
            type: Boolean
        }
    },
    emits: ['confirm'],
    setup(props, { emit }) {

        const config = reactive({
            showDialog: false,
			loading: true
        });

        const payload = ref({});

        const show = (p) => {
            payload.value = p;
            config.showDialog = true;
        };

        const close = () => {
            payload.value = {};
            config.showDialog = false;
        };

        const confirm = () => {
            config.showDialog = false;
            emit('confirm', payload.value);
        };

        return {
            config,
            confirm,
            show,
            close,
        }
    }
};
</script>