import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[(_vm.loading)?_c(VCol,[_c(VSheet,{staticClass:"mx-auto d-flex align-center",attrs:{"height":"400","width":"700"}},[_c(VProgressLinear,{attrs:{"height":"5","indeterminate":"","rounded":""}})],1)],1):(!_vm.loading && !_vm.items.length)?_c(VCol,{staticClass:"mx-auto"},[_c(VSheet,{staticClass:"pa-6 text-center text-h6 font-weight-light",attrs:{"outlined":"","rounded":""}},[_c(VIcon,{attrs:{"x-large":"","left":""}},[_vm._v("mdi-information-outline")]),_vm._v("No items found for this category! ")],1)],1):_vm._l((_vm.items),function(item,i){return _c(VCol,{key:i,attrs:{"xs":"2","sm":"6","md":"4","cols":"12"}},[_c(VCard,{staticClass:"pt-2 d-flex flex-column",attrs:{"height":"300","width":"350"}},[_c('ItemImage',{staticClass:"ml-2 mr-2 mb-3",attrs:{"item":item}}),_c(VCardTitle,{staticClass:"pb-0 mt-1 subtitle-2",domProps:{"textContent":_vm._s(item.name)}}),_c(VCardSubtitle,{staticClass:"mt-1 body-1",domProps:{"textContent":_vm._s(_vm.getPrice(item))}}),_c(VSpacer),_c(VCardActions,{staticClass:"mb-1"},[_c(VSpacer),_c('SelectItemDialog',{attrs:{"item":item}}),_c(VSpacer)],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }