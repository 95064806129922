<template>
    <v-form
        v-if="configuredItem.item"
        v-model="formValid"
        ref="form"
        lazy-validation
    >
        <div
            v-if="configuredItem.item.related['Accounts']"
            class="mb-5"
        >
            <div class="text-h5 font-weight-light mb-1">Accounts</div>

            <!-- <pre v-text="configuredItem.config && configuredItem.item.related['Accounts'].filter(x => configuredItem.config['Accounts'].some(y => y.slug === x.related_item.slug)).map(x => x.related_item)"></pre> -->

            <v-item-group
                :value="configuredItem.config && configuredItem.config['Accounts'] && configuredItem.item.related['Accounts'].filter(x => configuredItem.config['Accounts'].some(y => y.slug === x.related_item.slug)).map(x => x.related_item)"
                @change="selectConfig('Accounts', $event)"
                multiple
            >
                <div
                    v-for="(relItem, i) in configuredItem.item.related['Accounts']"
                    :key="i"
                >
                    <v-item
                        v-slot="{ active, toggle }"
                        :value="relItem.related_item"
                    >
                        <v-sheet
                            class="mb-2"
                            :style="`cursor: pointer;${active ? 'border: 2px solid #0070c9' : 'padding: 1px'}`"
                            :ripple="false"
                            @click="toggle"
                            outlined
                            rounded
                        >
                            <v-row class="pa-2 d-flex align-center">
                                <v-col cols="2">
                                    <v-img
                                        height="40"
                                        :src="relItem.related_item.image"
                                        contain
                                    />
                                </v-col>
                                <v-col class="text-h6 font-weight-light" v-text="relItem.related_item.name" />
                                <v-col class="text-h6 font-weight-light text-right">
                                    + ${{ fmtNumber(relItem.related_item.est_price) }}
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-item>
                </div>
            </v-item-group>
        </div>
        <v-dialog
            v-model="prompt.show"
            max-width="500px"
        >
            <v-card>
                <v-card-title v-text="prompt.text" />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        @click="continuePrompt"
                        text
                    >Yes, continue</v-btn>
                    <v-btn
                        color="primary"
                        @click="prompt.show = false"
                        text
                    >Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div class="text-h5 font-weight-light mb-1">Account Notes</div>
        <v-textarea
            :value="configuredItem.config && configuredItem.config['Account Notes']"
            @change="selectConfig('Account Notes', $event)"
            outlined
            hide-details
        />
    </v-form>
</template>
<script>

import { ref, reactive } from '@vue/composition-api';
import numberHelper from '@/composables/common/number';
import useItem from '@/composables/purchasing/useItem';

export default {
    name: 'AccountStep',
    components: {},
    props: {},
    setup(props, context) {

        const { configuredItem, selectConfig } = useItem(context);

        const { fmtNumber } = numberHelper();

        const formValid = ref(true);

        const validate = () => {
            return checkPrompts();
        };

        const prompt = reactive({
            slug: null,
            show: false,
            text: '',
            continued: [],
        });

        const checkPrompts = () => {
            const ci = configuredItem.value;
            if (!ci.item.related['Accounts']) return;
            
            for (const relItem of ci.item.related['Accounts']) {
                if (
                    relItem.related_item.meta.prompt_none && (
                        !ci.config ||
                        !ci.config.Accounts ||
                        !ci.config.Accounts.some(x => x.slug === relItem.related_item.slug)
                    ) && !prompt.continued.includes(relItem.related_item.slug)
                ){
                    prompt.text = relItem.related_item.meta.prompt_none;
                    prompt.slug = relItem.related_item.slug;
                    prompt.show = true;
                    return false;
                }
            }
            return true;
        };

        const continuePrompt = () => {
            if (!prompt.continued.includes(prompt.slug)) {
                prompt.continued.push(prompt.slug);
            }

            if (checkPrompts()) {
                prompt.show = false;
                context.emit('nextStep');
            }
        }


        return {
            configuredItem,
            formValid,
            fmtNumber,
            validate,
            selectConfig,
            prompt,
            continuePrompt
        }
    },
};
</script>