<template>
    <v-dialog
        v-model="config.showDialog"
        width="unset"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="red darken-1"
                small
                text
                block
            >
                <v-icon left>mdi-trash-can-outline</v-icon>
                Remove
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="pa-10 font-weight-regular">
                Are you sure you want to remove {{ item.item.name }}?
            </v-card-title>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    @click="config.showDialog = false"
                    text
                >Cancel</v-btn>
                <v-btn
                    color="red darken-1"
                    @click="removeItem"
                    text
                ><v-icon
                    color="red darken-1"
                    left
                >mdi-trash-can-outline</v-icon>Remove</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { reactive } from '@vue/composition-api';

export default {
    name: 'RemoveCartItem',
    components: {
    },
    props: {
        item: {
            type: Object, 
            default() {
                return {}
            }
        }
    },
    emits: ['removeItem'],
    setup(props, { emit }) {

        //const store = context.root.$store;

        const config = reactive({
            showDialog: false
        });

        const removeItem = async () => {
            emit('removeItem');
            config.showDialog = false;
        };

        return {
            config,
            removeItem
        }
    },
};
</script>