import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.configuredItem.item)?_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[(_vm.configuredItem.item.related['Software'])?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"text-h5 font-weight-light mb-1"},[_vm._v("Additional Software")]),_c(VItemGroup,{attrs:{"value":_vm.configuredItem.config && _vm.configuredItem.config['Software'] && _vm.configuredItem.item.related['Software'].filter(function (x) { return _vm.configuredItem.config['Software'].some(function (y) { return y.slug === x.related_item.slug; }); }).map(function (x) { return x.related_item; }),"multiple":""},on:{"change":function($event){return _vm.selectConfig('Software', $event)}}},_vm._l((_vm.configuredItem.item.related['Software']),function(relItem,i){return _c('div',{key:i},[_c(VItem,{attrs:{"value":relItem.related_item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VSheet,{staticClass:"mb-2",style:(("cursor: pointer;" + (active ? 'border: 2px solid #0070c9' : 'padding: 1px'))),attrs:{"ripple":false,"outlined":"","rounded":""},on:{"click":toggle}},[_c(VRow,{staticClass:"pa-2 d-flex align-center"},[_c(VCol,{attrs:{"cols":"2"}},[_c(VImg,{attrs:{"height":"40","src":relItem.related_item.image,"contain":""}})],1),_c(VCol,{staticClass:"text-h6 font-weight-light",domProps:{"textContent":_vm._s(relItem.related_item.name)}}),_c(VCol,{staticClass:"text-h6 font-weight-light text-right"},[_vm._v(" + $"+_vm._s(_vm.fmtNumber(relItem.related_item.est_price))+" ")])],1)],1)]}}],null,true)})],1)}),0)],1):_vm._e(),_c('div',{staticClass:"text-h5 font-weight-light mb-1"},[_vm._v("Software Notes")]),_c(VTextarea,{attrs:{"value":_vm.configuredItem.config && _vm.configuredItem.config['Software Notes'],"outlined":"","hide-details":""},on:{"change":function($event){return _vm.selectConfig('Software Notes', $event)}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }