import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Finance Approval "),_c(VSpacer),_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close")])],1),_c(VCardSubtitle,{domProps:{"textContent":_vm._s(_vm.request.summary)}}),(_vm.config.loading)?_c(VCardText,{staticClass:"text-center"},[_c(VContainer,[_c(VRow,{staticStyle:{"height":"200px"},attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"6"}},[_c(VProgressLinear,{attrs:{"color":"primary","height":"7","indeterminate":"","rounded":""}})],1)],1)],1)],1):_c(VCardText,[(_vm.pr.tickets)?_c(VSheet,{staticClass:"px-4 pt-6 pb-2",attrs:{"outlined":"","rounded":""}},[_c('RequestItems',{attrs:{"request":_vm.pr.tickets[_vm.selected],"elevation":""},scopedSlots:_vm._u([{key:"total",fn:function(ref){
var total = ref.total;
return [_c('div',{staticClass:"text-right pa-2 text-h5 orange--text text--darken-2 font-weight-light mb-10"},[_vm._v(" Total "),_c('strong',{staticClass:"pa-1 font-weight-bold",domProps:{"textContent":_vm._s(_vm.fmtNumber(total, {currency: true}))}})])]}}],null,false,1665658944)}),(_vm.charges.length)?_c('ChartStringWidget',{ref:"cs",staticClass:"pt-3",attrs:{"amount":Number(_vm.request.total_est_price),"show-add":""},model:{value:(_vm.charges),callback:function ($$v) {_vm.charges=$$v},expression:"charges"}}):_vm._e()],1):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.submit}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-check-circle")]),_vm._v(" Finance Approve ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }