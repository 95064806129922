<template>
    <div>
        <v-card-title class="headline mb-2">
            <v-spacer />
            <v-icon
                @click="$emit('close')"
            >mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="text-center">
            <div class="text-h6 font-weight-light green--text text--darken-2">
                <v-icon color="green darken-2" size="50">mdi-cart-arrow-down</v-icon>
                Item(s) added to purchase request.
            </div>
            <div class="mt-10">
                <v-btn
                    class="mr-4"
                    color="primary"
                    :to="{name: 'purchasingSubmit'}"
                    outlined
                >
                    <v-icon left>mdi-arrow-right-circle</v-icon>
                    Submit Purchase Request
                </v-btn>
                <v-btn
                    color="primary"
                    :to="{name: 'purchasingCart'}"
                    outlined
                >
                    <v-icon left>mdi-cart</v-icon>
                    View Purchase Cart
                </v-btn>
            </div>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
    </div>
</template>
<script>


export default {
    name: 'AddToPurchaseDialog',
    emits: ['close'],
    setup() {
        return {
        }
    },
};
</script>