<template>
    <v-select
        item-value="id"
        item-text="long_name"
        :items="divisionList"
        :label="label !== undefined ? label || 'Division' : undefined"
        :rules="rules"
        :hide-details="hideDetails"
        :dense="dense"
        :value="value"
        @input="$emit('input', $event)"
        return-object
    />
</template>

<script>
import axios from 'axios';
import { ref, computed, onMounted } from '@vue/composition-api';

export default {
    name: 'DivisionSelect',
    props: {
        items: {
            type: Array,
            default() {
                return [];
            }
        },
        value: {
            type: Object,
            default() {
                return {};
            }
        },
        label: {
            type: [String, Boolean]
        },
        rules: {type: Array},
        hideDetails: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        }
    },
    setup(props){
        //  Data
        const loadedDivisions = ref(null);

        //  Computed
        const divisionList = computed(() => {
            return !loadedDivisions.value ? props.items : loadedDivisions.value;
        });

        //  Methods
        const loadDivisions = async () => {
            let url = `/population/divisions/`;
            const response = await axios.get(url);
            loadedDivisions.value = response.data;
        }

        //  Mounted
        onMounted(() => {
            if (!props.items.length) loadDivisions();
        });

        return {
            loadedDivisions,
            divisionList,
            loadDivisions
        }
    }
}
</script>