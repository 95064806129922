import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid.form),callback:function ($$v) {_vm.$set(_vm.valid, "form", $$v)},expression:"valid.form"}},[(_vm.itemFull.features)?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"text-h5 font-weight-light mb-1"},[_vm._v("Features")]),(_vm.itemFull.features)?_c('ul',_vm._l((_vm.itemFull.features.split('\n')),function(f,i){return _c('li',{key:i,domProps:{"textContent":_vm._s(f)}})}),0):_vm._e()]):_vm._e(),(_vm.itemFull.options && _vm.itemFull.options.length)?_c('div',[_c(VSelect,{staticClass:"mb-4",attrs:{"label":"Base Configuration","item-value":"pk","items":_vm.itemFull.options,"value":_vm.configuredItem.option,"rules":_vm.$rules.required,"disabled":_vm.onlyOneOption,"return-object":"","hide-details":""},on:{"input":function($event){return _vm.selectOption($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" — $"+_vm._s(_vm.fmtNumber(data.item.est_price))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "),_c(VSpacer),_vm._v(" $"+_vm._s(_vm.fmtNumber(data.item.est_price))+" ")]}}],null,false,1049335088)}),(_vm.configuredItem.option && _vm.configuredItem.option.meta)?[(_vm.configuredItem.option.meta.colors)?_c('div',{staticClass:"mb-2"},[_c('div',{class:[{'error-text shake': !_vm.valid.colors}, 'text-h5 font-weight-light mb-1']},[_vm._v("Color")]),_c(VItemGroup,{attrs:{"value":_vm.configuredItem.config && _vm.configuredItem.config.Color,"mandatory":_vm.configuredItem.config && !!_vm.configuredItem.config.Color},on:{"change":_vm.chgColor}},[_c(VRow,_vm._l((_vm.configuredItem.option.meta.colors),function(colorOpt,k,i){return _c(VCol,{key:i,staticClass:"caption text-center",attrs:{"cols":"2"}},[_c(VItem,{attrs:{"value":k},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VAvatar,{staticClass:"pa-1",style:(active ? 'border: 2px solid #0070c9; cursor: pointer' : 'cursor: pointer'),attrs:{"size":"40","color":colorOpt.color},on:{"click":toggle}})]}}],null,true)}),_c('div',{class:[{'error-text shake': !_vm.valid.colors}, 'caption'],domProps:{"textContent":_vm._s(k)}})],1)}),1)],1)],1):_vm._e(),_vm._l((_vm.configuredItem.option.meta.config || {}),function(configOpt,k,i){return _c('div',{key:i,staticClass:"mb-3"},[_c('div',{staticClass:"text-h5 font-weight-light",domProps:{"textContent":_vm._s(k)}}),_c(VItemGroup,{attrs:{"value":_vm.configuredItem.config && _vm.configuredItem.config[k] && _vm.configuredItem.option.meta.config[k].find(function (x) { return x.name === _vm.configuredItem.config[k].name; }),"mandatory":""},on:{"change":function($event){return _vm.selectConfig(k, $event)}}},_vm._l((_vm.configuredItem.option.meta.config[k]),function(configOpt2,i2){return _c('div',{key:i2},[_c(VItem,{attrs:{"value":configOpt2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VSheet,{staticClass:"mb-2",style:(("cursor: pointer;" + (active ? 'border: 2px solid #0070c9' : 'padding: 1px'))),attrs:{"ripple":false,"outlined":"","rounded":""},on:{"click":toggle}},[_c(VRow,{staticClass:"pa-2"},[_c(VCol,{domProps:{"textContent":_vm._s(configOpt2.name)}}),(configOpt2.price)?_c(VCol,{staticClass:"text-right",attrs:{"cols":"auto"}},[_vm._v(" + $"+_vm._s(configOpt2.price)+" ")]):_vm._e()],1)],1)]}}],null,true)})],1)}),0)],1)})]:_vm._e()],2):_vm._e(),(_vm.configuredItem && 
                ((_vm.configuredItem.option && _vm.configuredItem.option.meta && _vm.configuredItem.option.meta.Specifications) || 
                (_vm.configuredItem.item && _vm.configuredItem.item.meta && _vm.configuredItem.item.meta.Specifications))
    )?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"text-h5 font-weight-light mb-1"},[_vm._v(_vm._s(_vm.configuredItem.option ? _vm.configuredItem.option.meta.Specifications.label : _vm.configuredItem.item.meta.Specifications.label))]),_c(VTextarea,{attrs:{"value":_vm.configuredItem.config && _vm.configuredItem.config.Specifications,"rules":_vm.$rules.required,"outlined":"","hide-details":""},on:{"change":function($event){return _vm.selectConfig('specification', $event)}}})],1):_vm._e(),(_vm.itemFull.meta && _vm.itemFull.meta.config)?_c('div',_vm._l((_vm.itemFull.meta.config || {}),function(baseConfigOpt,key,index){return _c('div',{key:index,staticClass:"mb-3"},[_c('div',{staticClass:"text-h5 font-weight-light",domProps:{"textContent":_vm._s(key)}}),_c(VItemGroup,{attrs:{"value":_vm.configuredItem.config && _vm.configuredItem.config[key] && _vm.itemFull.meta.config[key].find(function (x) { return x.name === _vm.configuredItem.config[key].name; }),"mandatory":""},on:{"change":function($event){return _vm.selectConfig(key, $event)}}},_vm._l((_vm.itemFull.meta.config[key]),function(configOpt2,i2){return _c('div',{key:i2},[_c(VItem,{attrs:{"value":configOpt2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var active = ref.active;
    var toggle = ref.toggle;
return [_c(VSheet,{staticClass:"mb-2",style:(("cursor: pointer;" + (active ? 'border: 2px solid #0070c9' : 'padding: 1px'))),attrs:{"ripple":false,"outlined":"","rounded":""},on:{"click":toggle}},[_c(VRow,{staticClass:"pa-2"},[_c(VCol,{domProps:{"textContent":_vm._s(configOpt2.name)}}),(configOpt2.price)?_c(VCol,{staticClass:"text-right",attrs:{"cols":"auto"}},[_vm._v(" + $"+_vm._s(configOpt2.price)+" ")]):_vm._e()],1)],1)]}}],null,true)})],1)}),0)],1)}),0):_vm._e(),(_vm.applecare)?_c('div',[_c(VSheet,{staticClass:"mb-2",attrs:{"outlined":"","rounded":""}},[_c(VRow,{staticClass:"pa-2"},[_c(VCol,[_c('div',{staticClass:"grey--text text--darken-2 text-h5 font-weight-light"},[_vm._v(" AppleCare+ "),_c(VChip,{attrs:{"small":"","label":"","outlined":""}},[_vm._v("Required")])],1),_vm._v(" 3 years of accidental damage protection and expert technical support from Apple ")]),_c(VCol,{staticClass:"text-right d-flex align-center",attrs:{"cols":"auto"}},[_vm._v(" + $"+_vm._s(_vm.applecare.price)+" ")])],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }