import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"unset","persistent":""},scopedSlots:_vm._u([(_vm.buttonText)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":_vm.buttonColor,"large":_vm.buttonLarge,"x-large":_vm.buttonXlarge,"outlined":_vm.buttonOutlined,"loading":_vm.buttonLoading,"disabled":_vm.buttonDisabled,"block":_vm.buttonBlock}},'v-btn',attrs,false),on),[(_vm.buttonIcon)?_c(VIcon,{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.buttonIcon)}}):_vm._e(),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1)]}}:null],null,true),model:{value:(_vm.config.showDialog),callback:function ($$v) {_vm.$set(_vm.config, "showDialog", $$v)},expression:"config.showDialog"}},[_c(VCard,[_c(VCardTitle,{domProps:{"textContent":_vm._s(_vm.title)}}),_vm._t("dialog"),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();_vm.config.showDialog = false}}},[_vm._v(" Cancel ")]),_vm._t("middleButton"),_c(VBtn,{attrs:{"color":_vm.confirmButtonColor,"disabled":_vm.confirmButtonDisabled,"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirm.apply(null, arguments)}}},[(_vm.confirmButtonIcon)?_c(VIcon,{domProps:{"textContent":_vm._s(_vm.confirmButtonIcon)}}):_vm._e(),_vm._v(" "+_vm._s(_vm.confirmButtonText)+" ")],1)],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }