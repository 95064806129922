<template>
    <div style="display: contents" v-if="endpoints.length">
        <div class="caption" v-if="caption" v-text="caption" />
        <v-item-group
            :multiple="multiple"
            :value="select.length && endpoints.filter(x => select.some(y => y.pk === x.pk)) || []"
            @change="selectEndpoint($event)"
        >
            <div
                v-for="(ep, i) in endpoints"
                :key="i"
            >
                <v-item
                    v-slot="{ active:epActive, toggle }"
                    :value="ep"
                >
                    <v-sheet
                        class="mb-2 pa-1"
                        :style="`cursor: pointer;${epActive ? 'border: 2px solid #0070c9' : 'padding: 1px'}`"
                        :ripple="false"
                        @click="toggle"
                        outlined
                        rounded
                    >
                        <div class="pa-2" v-text="ep.full_name" />
                        <!--
                        <v-row align="center" no-gutters>
                            <v-col cols="auto">
                                <v-icon color="grey">mdi-cellphone</v-icon>
                            </v-col>
                            <v-col>
                                <div class="pa-2" v-text="ep.full_name" />
                            </v-col>
                        </v-row>
                        -->
                    </v-sheet>
                </v-item>
            </div>
        </v-item-group>
    </div>
</template>
<script>

import axios from 'axios';
import { computed, watch, toRefs, onMounted, ref } from '@vue/composition-api';

export default {
    name: 'ExistingItems',
    components: {
    },
    props: {
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        active: {
            type: Boolean,
            default: true
        },
        endpointType: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: true
        },
        caption: {
            type: String
        },
        select: {
            type: Array,
            default() {
                return []
            }
        }
    },
    emits: ['input', 'foundEndpoints'],
    setup(props, { root, emit }) {

        const store = root.$store;

        const user = computed(() => store.getters['user']);

        const { value } = toRefs(props);

        const endpoints = ref([]);

        const loadEndpoints = async () => {
            let payload = value.value;
            if (!payload) return;
            if (payload.employee_id) payload = {employee: payload};

            payload = {
                ...payload,
                active: props.active,
                type: props.endpointType.replace(/desktops|laptops/, 'computers')
            };

            const response = await axios.post('/inventory/load_user_endpoints/', payload);

            endpoints.value = response.data;

            if (endpoints.value.length) {
                emit('foundEndpoints', true);
            }
        };

        watch(value, () => loadEndpoints());

        onMounted(() => loadEndpoints());

        const selectEndpoint = (e) => {
            emit('input', e);
        };

        return {
            user,
            endpoints,
            selectEndpoint
        }
    },
};
</script>