<template>
    <v-sheet
        class="px-6 pt-6"
        outlined
        rounded
    >
        <ChartStringWidget
            v-if="charges.show.length"
            v-model="charges.show"
            :amount="Number(request.total_est_price)"
            disabled
        >
            <template v-slot:extra="chartString">
                <v-col cols="auto" class="pl-4">
                    <v-chip
                        v-text="chartString.processed ? 'PROCESSED' : 'PENDING'"
                        :color="chartString.procssed ? 'green' : 'grey'"
                        label
                        small
                        outlined
                    />
                </v-col>
            </template>
        </ChartStringWidget>
    </v-sheet>
</template>
<script>

import axios from 'axios';
import { reactive, onMounted } from '@vue/composition-api';

import ChartStringWidget from '@/components/purchasing/ChartStringWidget';

import numberHelper from '@/composables/common/number';


export default {
    name: 'ViewPurchaseDialog',
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        },
    },
    components: {
        ChartStringWidget
    },
    setup(props) {

        const { fmtNumber } = numberHelper();

        const charges = reactive({
            original: null,
            show: []
        });

        const load = async () => {

            const response = await axios.get(`/purchasing/load_charges/${props.request.pk}/`);

            charges.original = response.data.charges;

            charges.show = charges.original.map(x => {
                return {
                    ...x.chart_string,
                    amount: x.amount,
                    percent: x.percent,
                    processed: x.processed
                };
            });
        };

        onMounted(() => load());

        return {
            load,
            fmtNumber,
            charges
        }
    }
};
</script>