<template>
    <v-form
        v-model="formValid"
        ref="form"
        lazy-validation
    >
        <div class="text-h5 font-weight-light mb-1">Notes</div>
        <div
            v-if="category.meta.notes && category.meta.notes.caption"
            v-text="category.meta.notes.caption"
            class="caption"
        />
        <v-textarea
            :value="configuredItem.notes"
            :rules="category.meta.notes && category.meta.notes.required ? $rules.required : []"
            @change="setConfiguredItem({notes: $event})"
            outlined
            hide-details
        />
    </v-form>
</template>
<script>

import { ref } from '@vue/composition-api';
import numberHelper from '@/composables/common/number';
import useItem from '@/composables/purchasing/useItem';

export default {
    name: 'NotesStep',
    components: {},
    props: {},
    setup(props, context) {

        const { configuredItem, category, setConfiguredItem } = useItem(context);

        const { fmtNumber } = numberHelper();

        const formValid = ref(true);

        const validate = () => context.refs.form.validate();

        return {
            configuredItem,
            category,
            formValid,
            fmtNumber,
            validate,
            setConfiguredItem
        }
    },
};
</script>