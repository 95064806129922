<template>
    <v-form
        v-model="valid.form"
        ref="form"
        lazy-validation
    >
        <div v-if="itemFull.features" class="mb-3">
            <div class="text-h5 font-weight-light mb-1">Features</div>
            <ul v-if="itemFull.features">
                <li
                    v-for="(f, i) in itemFull.features.split('\n')"
                    v-text="f"
                    :key="i"
                />
            </ul>
        </div>
        
        <div v-if="itemFull.options && itemFull.options.length">
            <v-select
                label="Base Configuration"
                item-value="pk"
                class="mb-4"
                :items="itemFull.options"
                :value="configuredItem.option"
                :rules="$rules.required"
                :disabled="onlyOneOption"
                @input="selectOption($event)"
                return-object
                hide-details
            >
                <template v-slot:selection="data">
                    {{ data.item.name }}
                    &mdash;
                    ${{ fmtNumber(data.item.est_price) }}
                </template>
                <template v-slot:item="data">
                    {{ data.item.name }}
                    <v-spacer />
                    ${{ fmtNumber(data.item.est_price) }}
                </template>
            </v-select>
            
            <template v-if="configuredItem.option && configuredItem.option.meta">
                <div
                    v-if="configuredItem.option.meta.colors"
                    class="mb-2"
                >
                    <div
                        :class="[{'error-text shake': !valid.colors}, 'text-h5 font-weight-light mb-1']"
                    >Color</div>
                    <v-item-group
                        :value="configuredItem.config && configuredItem.config.Color"
                        :mandatory="configuredItem.config && !!configuredItem.config.Color"
                        @change="chgColor"
                    >
                        <v-row>
                            <v-col
                                v-for="(colorOpt, k, i) in configuredItem.option.meta.colors"
                                cols="2"
                                class="caption text-center"
                                :key="i"
                            >
                                <v-item
                                    v-slot="{ active, toggle }"
                                    :value="k"
                                >
                                    <v-avatar
                                        class="pa-1"
                                        size="40"
                                        @click="toggle"
                                        :style="active ? 'border: 2px solid #0070c9; cursor: pointer' : 'cursor: pointer'"
                                        :color="colorOpt.color"
                                    >
                                    </v-avatar>
                                </v-item>
                                <div
                                    v-text="k"
                                    :class="[{'error-text shake': !valid.colors}, 'caption']"
                                />
                            </v-col>
                        </v-row>
                    </v-item-group>
                </div>
                
                <div
                    v-for="(configOpt, k, i) in configuredItem.option.meta.config || {}"
                    class="mb-3"
                    :key="i"
                >
                    <div class="text-h5 font-weight-light" v-text="k" />
                    <v-item-group
                        :value="configuredItem.config && configuredItem.config[k] && configuredItem.option.meta.config[k].find(x => x.name === configuredItem.config[k].name)"
                        @change="selectConfig(k, $event)"
                        mandatory
                    >
                        <div
                            v-for="(configOpt2, i2) in configuredItem.option.meta.config[k]"
                            :key="i2"
                        >
                            <v-item
                                v-slot="{ active, toggle }"
                                :value="configOpt2"
                            >
                                <v-sheet
                                    class="mb-2"
                                    :style="`cursor: pointer;${active ? 'border: 2px solid #0070c9' : 'padding: 1px'}`"
                                    :ripple="false"
                                    @click="toggle"
                                    outlined
                                    rounded
                                >
                                    <v-row class="pa-2">
                                        <v-col v-text="configOpt2.name" />
                                        <v-col
                                            cols="auto"
                                            v-if="configOpt2.price"
                                            class="text-right"
                                        >
                                            + ${{ configOpt2.price }}
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-item>
                        </div>
                    </v-item-group>
                </div>
            </template>
        </div>
        <!-- <div v-else-if="itemFull.features">
            <div class="text-h5 font-weight-light mb-1">Features</div>
            <ul v-if="itemFull.features">
                <li
                    v-for="(f, i) in itemFull.features.split('\n')"
                    v-text="f"
                    :key="i"
                />
            </ul>
        </div> -->
        <div v-if="configuredItem && 
                    ((configuredItem.option && configuredItem.option.meta && configuredItem.option.meta.Specifications) || 
                    (configuredItem.item && configuredItem.item.meta && configuredItem.item.meta.Specifications))
        "
            class="mb-3"
        >
            <div class="text-h5 font-weight-light mb-1">{{ configuredItem.option ? configuredItem.option.meta.Specifications.label : configuredItem.item.meta.Specifications.label }}</div>
            <v-textarea
                :value="configuredItem.config && configuredItem.config.Specifications"
                :rules="$rules.required"
                @change="selectConfig('specification', $event)"
                outlined
                hide-details
            />
        </div>
        <div v-if="itemFull.meta && itemFull.meta.config">
            <div
                v-for="(baseConfigOpt, key, index) in itemFull.meta.config || {}"
                class="mb-3"
                :key="index"
            >
                <div class="text-h5 font-weight-light" v-text="key" />
                <v-item-group
                    :value="configuredItem.config && configuredItem.config[key] && itemFull.meta.config[key].find(x => x.name === configuredItem.config[key].name)"
                    @change="selectConfig(key, $event)"
                    mandatory
                >
                    <div
                        v-for="(configOpt2, i2) in itemFull.meta.config[key]"
                        :key="i2"
                    >
                        <v-item
                            v-slot="{ active, toggle }"
                            :value="configOpt2"
                        >
                            <v-sheet
                                class="mb-2"
                                :style="`cursor: pointer;${active ? 'border: 2px solid #0070c9' : 'padding: 1px'}`"
                                :ripple="false"
                                @click="toggle"
                                outlined
                                rounded
                            >
                                <v-row class="pa-2">
                                    <v-col v-text="configOpt2.name" />
                                    <v-col
                                        cols="auto"
                                        v-if="configOpt2.price"
                                        class="text-right"
                                    >
                                        + ${{ configOpt2.price }}
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-item>
                    </div>
                </v-item-group>
            </div>
        </div>
        <!-- <div v-if="(itemFull.meta && itemFull.meta.applecare) || (configuredItem.option && configuredItem.option.meta && configuredItem.option.meta.applecare) "> -->
        <div v-if="applecare">
            <v-sheet
                class="mb-2"
                outlined
                rounded
            >
                <v-row class="pa-2">
                    <v-col>
                        <div class="grey--text text--darken-2 text-h5 font-weight-light">
                            AppleCare+
                            <v-chip class="" small label outlined>Required</v-chip>
                        </div>
                        3 years of accidental damage protection and expert technical support from Apple
                        
                    </v-col>
                    <v-col
                        cols="auto"
                        class="text-right d-flex align-center"
                    >
                        + ${{ applecare.price }}
                    </v-col>
                </v-row>
            </v-sheet>
        </div>
    </v-form>
</template>
<script>
import { reactive, computed, watch, onMounted } from '@vue/composition-api';
import numberHelper from '@/composables/common/number';
import useItem from '@/composables/purchasing/useItem';

export default {
    name: 'HardwareStep',
    components: {},
    props: {
        item: {
            type: Object, 
            default() {
                return {}
            }
        }
    },
    setup(props, context) {

        const { fmtNumber } = numberHelper();

        const {
            itemFull,
            configuredItem,
            configuredItemPrice,
            selectOption,
            selectConfig,
            setConfiguredItem
        } = useItem(context);

        const valid = reactive({
            form: true,
            colors: true
        });

        const chgColor = (e) => {
            if (e) {
                selectConfig('Color', e);
                valid.colors = true;
            }
        };

        const onlyOneOption = computed(() => itemFull.value.options && itemFull.value.options.length === 1);
        const applecare = computed(() => {
            if (configuredItem.value?.option?.meta?.applecare) {
                return configuredItem.value?.option?.meta?.applecare
            } else if (itemFull.value.meta && itemFull.value.meta.applecare) {
                return itemFull.value.meta.applecare
            }
            return false
        })
        const validate = () => {
            valid.form = context.refs.form.validate();

            if (configuredItem.value?.option?.meta?.colors && !configuredItem.value?.config?.Color) {
                valid.colors = false;
            }

            return Object.values(valid).every(x => x);
        };

        watch(onlyOneOption, (val) => {
            if (val) selectOption(itemFull.value.options[0]);
        });

        onMounted(() => {
            if (onlyOneOption.value) selectOption(itemFull.value.options[0]);
        });

        return {
            itemFull,
            selectOption,
            selectConfig,
            configuredItem,
            configuredItemPrice,
            fmtNumber,
            validate,
            valid,
            chgColor,
            onlyOneOption,
            setConfiguredItem,
            applecare
        }
    },
};
</script>