import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{class:[_vm.dense ? 'caption' : 'body-2', 'd-flex align-center'],attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,[_vm._v("mdi-account")])],1),_c(VCol,{domProps:{"textContent":_vm._s(_vm.userText)}}),_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,[_vm._v("mdi-account-group")])],1),_c(VCol,{domProps:{"textContent":_vm._s(_vm.divNameOnly(_vm.assignment.division.name))}}),_c(VCol,[(_vm.hasLocation)?[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',{class:[{'elevation-1 rounded-lg': hover && _vm.hasLocation}, 'pa-2'],staticStyle:{"cursor":"pointer"},on:{"click":_vm.openLoc}},[_c(VIcon,{attrs:{"color":hover ? 'green' : 'blue'}},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.locationText)+" ")],1)]}}],null,false,1217689095)})]:(!_vm.hasLocation && _vm.locationText.length < 15)?[_c('span',{staticClass:"pa-2"},[_c(VIcon,[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.locationText)+" ")],1)]:[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pa-2",attrs:{"cols":"auto"}},[_c(VIcon,[_vm._v("mdi-map-marker")])],1),_c(VCol,{staticStyle:{"white-space":"pre-line"},domProps:{"textContent":_vm._s(_vm.locationText)}})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }