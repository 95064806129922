<template>
    <v-container>
        <v-row
            align="center"
            justify="center"
            :style="['height:' + height + 'px']"
        >
            <v-col class="text-center" cols="6">
                <v-progress-linear
                    color="primary"
                    height="7"
                    indeterminate
                    rounded
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'ProgressLoader',
    props: {
        height: {
            type: Number,
            default: 200
        }
    }
};
</script>