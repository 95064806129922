import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{staticClass:"stretch-container"},[_c('div',{staticClass:"text-h4 mb-2"},[_vm._v("Orders")]),_c(VTabs,{staticClass:"mb-3",model:{value:(_vm.config.tab),callback:function ($$v) {_vm.$set(_vm.config, "tab", $$v)},expression:"config.tab"}},[_c(VTab,{key:"open",on:{"click":function($event){return _vm.$refs.rlOpen.loadRequests()}}},[_vm._v(" Open ")])],1),_c(VTabsItems,{model:{value:(_vm.config.tab),callback:function ($$v) {_vm.$set(_vm.config, "tab", $$v)},expression:"config.tab"}},[_c(VTabItem,{key:"open",attrs:{"eager":""}},[_c('RequestList',{ref:"rlOpen",attrs:{"filter":"open","load":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }