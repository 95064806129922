import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c('div',{staticClass:"text-h4 mb-6"},[_vm._v("Purchasing")]),_c(VRow,_vm._l((_vm.requestTypes),function(t,i){return _c(VCol,{key:i,attrs:{"cols":"3"}},[_c(VCard,{staticClass:"text-center",attrs:{"rounded":"xl","to":{name: 'purchasingType', params: {type: t.slug}}}},[_c(VCardText,{staticClass:"text-center"},[_c(VAvatar,{attrs:{"color":"orange darken-2","size":"75"}},[_c(VIcon,{attrs:{"color":"white","x-large":""},domProps:{"textContent":_vm._s(t.icon)}})],1),_c('div',{staticClass:"text-h5 mt-2",domProps:{"textContent":_vm._s(t.name)}})],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }