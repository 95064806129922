import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"unset"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"red darken-1","small":"","text":"","block":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v(" Remove ")],1)]}}]),model:{value:(_vm.config.showDialog),callback:function ($$v) {_vm.$set(_vm.config, "showDialog", $$v)},expression:"config.showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"pa-10 font-weight-regular"},[_vm._v(" Are you sure you want to remove "+_vm._s(_vm.item.item.name)+"? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.config.showDialog = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.removeItem}},[_c(VIcon,{attrs:{"color":"red darken-1","left":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v("Remove")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }