<template>
    <v-form
        v-if="configuredItem.item"
        v-model="formValid"
        ref="form"
        lazy-validation
    >
        <div
            v-if="configuredItem.item.related['Accessories']"
            class="mb-5"
        >
            <div
                v-for="(relItem, i) in configuredItem.item.related['Accessories']"
                :key="i"
            >
                <v-sheet
                    class="mb-2"
                    :ripple="false"
                    outlined
                    rounded
                >
                    <v-row class="pa-2 d-flex align-center">
                        <v-col cols="2">
                            <v-img
                                height="40"
                                :src="relItem.related_item.image"
                                contain
                            />
                        </v-col>
                        <v-col>
                            <div class="text-h6 font-weight-light" v-text="relItem.related_item.name" />
                            <!-- <div v-if="!relItem.meta.variable_price" class="grey--text mt-n1">${{ fmtNumber(relItem.related_item.est_price) }}</div> -->
                            <div class="grey--text mt-n1">{{ getPrice(relItem.related_item) }}</div>
                            <div
                                v-if="relItem.related_item.meta && relItem.related_item.meta.applecare && relItem.related_item.meta.applecare.required"
                            >
                                <v-chip class="" small label outlined>3 Year AppleCare+ Included</v-chip>
                            </div>
                        </v-col>
                        <v-col
                            class="text-h6 font-weight-light d-flex justify-end"
                            cols="3"
                        >
                            <template v-if="relItem.meta.multiple">
                                <v-text-field
                                    style="width: 120px"
                                    class="shrink centered-input"
                                    prepend-icon="mdi-minus"
                                    append-outer-icon="mdi-plus"
                                    placeholder="0"
                                    :value="configuredItem.accessories && configuredItem.accessories[relItem.related_item.slug] && configuredItem.accessories[relItem.related_item.slug].qty"
                                    @click:prepend="addRemove(relItem.related_item.slug, 'remove', true)"
                                    @click:append-outer="addRemove(relItem.related_item.slug, 'add', true)"
                                    @change="addRemove(relItem.related_item.slug, $event, true)"
                                    @keypress="onlyNumber($event)"
                                    dense
                                    outlined
                                    hide-details
                                />
                            </template>
                            <template v-else>
                                <v-btn
                                    v-if="configuredItem.accessories && configuredItem.accessories[relItem.related_item.slug] && configuredItem.accessories[relItem.related_item.slug].qty"
                                    color="error"
                                    @click="addRemove(relItem.related_item.slug, 'remove')"
                                    small
                                    outlined
                                >Remove</v-btn>
                                <v-btn
                                    v-else
                                    color="primary"
                                    @click="addRemove(relItem.related_item.slug, 'add')"
                                    small
                                    outlined
                                >Add</v-btn>
                            </template>
                        </v-col>
                    </v-row>
                    <div v-if="relItem.related_item.options.length === 1" class="mb-3 mt-n6">
                        <template
                            v-for="(v, k, i2) in relItem.related_item.options[0].meta"
                        >
                            <v-row
                                v-if="k === 'colors'"
                                :key="`acc-opt${i2}`"
                            >
                                <v-col class="offset-2" cols="7">
                                    <v-select
                                        label="Color"
                                        :items="Object.entries(v)"
                                        :value="configuredItem.accessories && configuredItem.accessories[relItem.related_item.slug] && configuredItem.accessories[relItem.related_item.slug].color"
                                        :error="invalid.includes(relItem.related_item.slug)"
                                        @change="chgColor(relItem.related_item.slug, $event)"
                                        outlined
                                        dense
                                        hide-details
                                        return-object
                                    >
                                        <template v-slot:selection="data">
                                            <v-row>
                                                <v-col cols="2">
                                                    <v-avatar
                                                        size="18"
                                                        :color="data.item[1].color"
                                                    />
                                                </v-col>
                                                <v-col class="d-flex align-center">
                                                    {{ data.item[0] }} 
                                                    <span v-if="data.item[1].price" class="ml-1"> &mdash; ${{ data.item[1].price }} </span>
                                                </v-col>
                                            </v-row>
                                        </template>

                                        <template v-slot:item="data">
                                            <v-row>
                                                <v-col cols="2">
                                                    <v-avatar
                                                        size="18"
                                                        :color="data.item[1].color"
                                                    />
                                                </v-col>
                                                <v-col class="d-flex align-center" v-text="data.item[0]" />
                                                <v-col v-if="data.item[1].price" class="d-flex align-center justify-end ml-auto"> ${{ data.item[1].price }} </v-col>
                                            </v-row>
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                            
                        </template>
                    </div>

                    <div
                        v-if="configuredItem.accessories && configuredItem.accessories[relItem.related_item.slug]"
                        class="mb-3 mx-1 mt-n6"
                    >
                        <template
                            v-for="(configOpt, k, i3) in relItem.related_item.meta.config || {}"
                            class="mb-3"
                        >
                            <v-row :key="`acc-opt${i3}`">
                                <v-col class="offset-2" cols="7">
                                    <div class="text-h6 font-weight-light" v-text="k" />
                                    <v-item-group
                                        :value="configuredItem.accessories && configuredItem.accessories[relItem.related_item.slug] && configuredItem.accessories[relItem.related_item.slug].config && configuredItem.accessories[relItem.related_item.slug].config[k] && configuredItem.accessories[relItem.related_item.slug].item.meta.config[k].find(x => x.name === configuredItem.accessories[relItem.related_item.slug].config[k].name)"
                                        @change="chgAccessoryConfig(relItem.related_item.slug, {[k]: $event})"
                                        mandatory
                                    >
                                        <div
                                            v-for="(configOpt2, i4) in relItem.related_item.meta.config[k]"
                                            :key="i4"
                                        >
                                            <v-item
                                                v-slot="{ active, toggle }"
                                                :value="configOpt2"
                                            >
                                                <v-sheet
                                                    class="mb-2"
                                                    :style="`cursor: pointer;${active ? 'border: 2px solid #0070c9' : 'padding: 1px'}`"
                                                    :ripple="false"
                                                    @click="toggle"
                                                    outlined
                                                    rounded
                                                >
                                                    <v-row class="pa-2">
                                                        <v-col v-text="configOpt2.name" />
                                                        <v-col
                                                            cols="auto"
                                                            v-if="configOpt2.price"
                                                            class="text-right"
                                                        >
                                                            + ${{ configOpt2.price }}
                                                        </v-col>
                                                    </v-row>
                                                </v-sheet>
                                            </v-item>
                                        </div>
                                    </v-item-group>
                                </v-col>
                            </v-row>
                            
                        </template>
                        <!-- <pre>
                            {{ relItem }}
                            {{ getPrice(relItem.related_item) }}
                        </pre> -->
                    </div>
                </v-sheet>
            </div>

            <v-dialog
                v-model="prompt.show"
                max-width="500px"
            >
                <v-card>
                    <v-card-title v-text="prompt.text" />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            @click="continuePrompt"
                            text
                        >Yes, continue</v-btn>
                        <v-btn
                            color="primary"
                            @click="prompt.show = false"
                            text
                        >Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-form>
</template>
<script>

import { ref, reactive } from '@vue/composition-api';
import numberHelper from '@/composables/common/number';
import useItem from '@/composables/purchasing/useItem';

export default {
    name: 'AccessoryStep',
    components: {},
    props: {},
    emits: ['nextStep'],
    setup(props, context) {

        const {
            configuredItem,
            selectConfig,
            setConfiguredItem,
            removeConfiguredItem,
            getPrice,
        } = useItem(context);

        const { fmtNumber, onlyNumber } = numberHelper();

        const formValid = ref(true);

        const invalid = ref([]);

        const prompt = reactive({
            slug: null,
            show: false,
            text: '',
            continued: [],
        });

        const checkPrompts = () => {
            const ci = configuredItem.value;
            if (!ci.item.related['Accessories']) return;
            
            for (const relItem of ci.item.related['Accessories']) {
                // check if accessory is already in prompt.continued
                if (
                    relItem.meta.prompt_none && (
                        !ci.accessories ||
                        !ci.accessories[relItem.related_item.slug] ||
                        !ci.accessories[relItem.related_item.slug].qty
                    ) &&
                    !prompt.continued.includes(relItem.related_item.slug) && 
                    !checkExistingContinuedPrompt(relItem)
                ) {
                    prompt.text = relItem.meta.prompt_none;
                    prompt.slug = relItem.related_item.slug;
                    prompt.show = true;
                    return false;
                }
            }
            return true;
        };

        const continuePrompt = () => {
            if (!prompt.continued.includes(prompt.slug)){
                // add general term to prompt.continued list
                // so that duplicate prompts can be skipped
                const checkTerms = ['monitor', 'keyboard', 'mouse'];
                for (let term of checkTerms) {
                    if (prompt.text.includes(term) && !prompt.continued.includes(term)) {
                        prompt.continued.push(term);
                    }
                }
                // add slug to continued list
                prompt.continued.push(prompt.slug);
            }
            
            if (checkPrompts()) {
                prompt.show = false;
                context.emit('nextStep');
            }
        };

        const checkExistingContinuedPrompt = (relItem) => {
            let skipPrompt = false;
            const ci = configuredItem.value;
            const checkTerms = [['monitor', 'display'], ['keyboard'], ['mouse', 'trackpad']];
            let terms = [];

            for (let t of checkTerms) {
                if (t.some(v => relItem.related_item.name.toLowerCase().includes(v))) {
                    terms = t;
                }
            }

            if (terms.some(v => prompt.continued.includes(v))) {
                skipPrompt = true;
            } else if (ci.accessories && Object.keys(ci.accessories).length > 0) {
                for (let key in ci.accessories) {
                    if (terms.some(v => key.toLowerCase().includes(v))) {
                        skipPrompt = true;
                        break;
                    }
                }
            }
            
            return skipPrompt;
        }

        const validate = () => {
            // remove any accessories with qty 0
            const cItem = configuredItem.value;

            if (cItem.accessories) {
                for (const [k, v] of Object.entries(cItem.accessories)) {
                    if (!v.qty) {
                        removeConfiguredItem({
                            type: 'accessories',
                            item: k
                        });
                    }
                }
            }

            return checkPrompts();
        };

        const getRelatedItemPayload = (item) => {

            const cItem = configuredItem.value;

            const cItemRelItem = cItem.item.related['Accessories'].find(x => x.related_item.slug === item).related_item;

            let itemPayload;

            if (cItem.accessories && cItem.accessories[item]) {
                // look up item
                itemPayload = cItem.accessories[item];
            } else {
                // create item in accessories key
                itemPayload = {
                    item: cItemRelItem,
                };
            }

            return {
                cItem: cItem,
                cItemRelItem: cItemRelItem,
                itemPayload: itemPayload
            }
        };


        const addRemove = (item, action, multiple=false) => {

            const relItemPayload = getRelatedItemPayload(item);
            const { cItem, cItemRelItem } = relItemPayload;
            let { itemPayload } = relItemPayload;
            
            // do validation if this item has options
            if (action === 'add' && cItemRelItem.options.length) {
                // check colors
                if (
                    cItemRelItem.options.some(x => x.meta.colors) && (
                        !cItem.accessories ||
                        !cItem.accessories[cItemRelItem.slug] ||
                        !cItem.accessories[cItemRelItem.slug].color
                    )
                ) {
                    if (!invalid.value.includes(cItemRelItem.slug)) invalid.value.push(cItemRelItem.slug);
                    return;
                }
            }


            itemPayload.qty = itemPayload.qty || 0;

            if (!multiple) {
                itemPayload.qty = action === 'add' ? 1 : 0;
            } else if (action === 'add') {
                itemPayload.qty = itemPayload.qty + 1
            } else if (action === 'remove') {
                itemPayload.qty = Math.max(itemPayload.qty - 1, 0);
            } else {
                itemPayload.qty = Math.max(action, 0);
            }

            if (itemPayload.qty !== 0) {

                setConfiguredItem({
                    'accessories': {
                        [item]: itemPayload
                    }
                });

            } else {

                removeConfiguredItem({
                    type: 'accessories',
                    item: item
                });
            }
            
        };

        const chgColor = (item, val) => {
            const relItemPayload = getRelatedItemPayload(item);
            const { itemPayload } = relItemPayload;

            itemPayload.color = val;

            setConfiguredItem({
                'accessories': {
                    [item]: itemPayload
                }
            });

            invalid.value = invalid.value.filter(x => x !== item);

        };
        
        const chgAccessoryConfig = (item, val) => {
            const relItemPayload = getRelatedItemPayload(item);
            const { itemPayload } = relItemPayload;

            itemPayload.config = val;

            setConfiguredItem({
                'accessories': {
                    [item]: itemPayload
                }
            })

        }

        return {
            configuredItem,
            formValid,
            fmtNumber,
            onlyNumber,
            validate,
            invalid,
            selectConfig,
            addRemove,
            prompt,
            continuePrompt,
            chgColor,
            getPrice,
            chgAccessoryConfig
        }
    },
};
</script>
<style scoped>
.centered-input >>> input {
    text-align: center;
}
</style>
