<template>
    <v-dialog
        v-if="config.dialog"
        v-model="config.dialog"
        width="950"
        @click:outside="reset"
    >
        <v-card>
            <v-card-title>
                <span
                    class="text-truncate"
                    style="max-width: 700px"
                    v-text="pr.summary"
                />
                <v-spacer />
                    <div 
                        v-if="config.selectedPur !== null"    
                        class="d-flex flex-column"
                    >
                        <v-chip
                            class="mb-2"
                            :color="statusColor(pr.tickets[config.selectedPur].data.status)"
                            v-text="pr.tickets[config.selectedPur].data.status"
                            label
                            outlined
                        />
                        <RequestActions
                            :ticket="pr.tickets[config.selectedPur]"
                            :selected="config.selectedPur"
                            filter="open"
                            @reset="reset"
                        />
                    </div>
            </v-card-title>
            <v-card-subtitle
                v-if="config.selectedPur !== null"
                class="pt-1 pb-1"
                v-text="pluralize('Item', pr.tickets[config.selectedPur].items.length)"
            />
            <v-card-text>
                <v-tabs
                    v-model="config.selectedPur"
                    class="mb-4"
                >
                    <v-tab
                        v-for="n in requestTickets"
                        :key="n.id" 
                        ripple
                    >
                        {{ n.key }}
                    </v-tab>
                        <v-tab-item
                            v-for="(n, index) in requestTickets" :key="n.id"
                        >
                            <v-tabs
                                v-model="config.activeTab[index]" slider-color="blue" v-if="config.selectedPur === index"
                                class="mb-4"
                            >
                                <v-tab key="detail">
                                    <v-icon left>mdi-information-outline</v-icon>Details
                                </v-tab>
                                <!-- <v-tab key="tickets">
                                    <v-icon left>mdi-jira</v-icon>Tickets
                                </v-tab> -->
                                <v-tab key="comments">
                                    <v-icon left>mdi-comment-outline</v-icon>Comments
                                </v-tab>
                                <v-tab key="attachments">
                                    <v-icon left>mdi-paperclip</v-icon>Attachments
                                </v-tab>
                                <v-tab key="charges">
                                    <v-icon left>mdi-currency-usd</v-icon>Charges
                                </v-tab>
                                <v-tab key="timeline">
                                    <v-icon left>mdi-clock-outline</v-icon>Timeline
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="config.activeTab[index]">
                                <v-tab-item key="detail">
                                    <RequestItems
                                        :request="pr.tickets[index]"
                                    />
                                    <RequestNote
                                        :request="pr"
                                    />
                                </v-tab-item>
                                <!-- <v-tab-item key="tickets">
                                    <RequestTicketItems
                                        :items="pr.items"
                                        
                                    />
                                </v-tab-item> -->
                                <v-tab-item key="comments">
                                    <RequestComments
                                        :ref="`reqComments-${index}`"
                                        :request="pr.tickets[index]"
                                        @newCommentValid="config.showAddComment = $event"
                                    />
                                </v-tab-item>
                                <v-tab-item key="attachments">
                                    <RequestAttachments
                                        :request="pr.tickets[index]"
                                    />
                                </v-tab-item>
                                <v-tab-item key="charges">
                                    <RequestCharges
                                        :request="pr"
                                    />
                                </v-tab-item>
                                <v-tab-item key="timeline">
                                    <RequestTimeline
                                        :request="pr"
                                    />
                                </v-tab-item>
                            </v-tabs-items>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="reset"
                >
                    Close
                </v-btn>
                <!-- <v-slide-x-transition>
                    <v-btn
                        v-show="config.showAddComment"
                        color="blue darken-1"
                        text
                        @click="addComment"
                    >
                        Add Comment
                    </v-btn>
                </v-slide-x-transition> -->
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import { reactive } from '@vue/composition-api';

import RequestItems from '@/components/purchasing/request/RequestItems';
import RequestCharges from '@/components/purchasing/request/RequestCharges';
import RequestTimeline from '@/components/purchasing/request/RequestTimeline';
import RequestComments from '@/components/purchasing/request/RequestComments';
import RequestAttachments from '@/components/purchasing/request/RequestAttachments';
import RequestNote from '@/components/purchasing/request/RequestNote';
import RequestActions from '@/components/purchasing/RequestActions';

// import RequestTicketItems from '@/components/purchasing/request/RequestTicketItems'


import statusHelper from '@/composables/purchasing/status';
import requestHelper from '@/composables/purchasing/request';
import stringHelper from '@/composables/common/string';


export default {
    name: 'ViewPurchaseDialog',
    components: {
        RequestItems,
        RequestCharges,
        RequestTimeline,
        RequestComments,
        RequestAttachments,
        RequestNote,
        RequestActions
        // RequestTicketItems
    },
    setup(props, { refs }) {

        const config = reactive({
            tab: null,
            dialog: false,
            showAddComment: false,
            selectedPur: null,
            requestPurList: [{id: 1, key: "PURTEST-123"}, {id: 2, key: "PURTEST-234"}],
            activeTab: [],
        });


        const { pluralize } = stringHelper();
        const { statusColor } = statusHelper();

        const { 
            loadRequest,
            loadedRequest,
            requestTickets
        } = requestHelper();

        const load = async (p) => {
            const req = await loadRequest(p.pk);
            if (!req) return;
            config.dialog = true;
        };

        const reset = () => {
            config.tab = null;
            config.dialog = null;
            config.activeTab = [];
            config.selectedPur = null;
        };

        const addComment = () => {
            refs.reqComments.addComment();
            config.showAddComment = false;
        };

        return {
            config,
            pr: loadedRequest,
            statusColor,
            pluralize,
            load,
            reset,
            addComment,
            requestTickets
        }
    }
};
</script>


<!-- 
    Todo: 
    1) Need to somehow separate each pur ticket with their items associated with the order
        -  Each PUR in invoice should be separated with their respective items
    2) Detail should show only the item that is in the PUR ticket

    3) Load comments from each pur ticket

    4) Load Attachments from each PUR ticket

    5) Upload attachments to PUR Ticket

    7) Editing a PUR ticket 

 -->