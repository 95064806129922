<template>
    <v-main>
        <v-container class="stretch-container">
            <div class="text-h4 mb-2">Orders</div>

            <v-tabs
                v-model="config.tab"
                class="mb-3"
            >
                <v-tab
                    key="open"
                    @click="$refs.rlOpen.loadRequests()"
                >
                    Open
                </v-tab>
                <!-- <v-tab
                    key="closed"
                    @click="$refs.rlClosed.loadRequests()"
                >
                    Closed
                </v-tab> -->
            </v-tabs>
            <v-tabs-items
                v-model="config.tab"
            >
                <v-tab-item
                    key="open"
                    eager
                >
                    <RequestList
                        filter="open"
                        ref="rlOpen"
                        load
                    />
                </v-tab-item>
                <!-- <v-tab-item
                    key="closed"
                    eager
                >
                    <RequestList
                        filter="closed"
                        ref="rlClosed"
                    />
                </v-tab-item> -->
            </v-tabs-items>
        </v-container>
    </v-main>
</template>
<script>
import { reactive } from '@vue/composition-api';

import RequestList from '@/components/purchasing/RequestList';


export default {
    components: {
        RequestList
    },
    setup() {

        const config = reactive({
            tab: null
        });
        
        return {
            config
        }
    },
};
</script>