<template>
    <v-form
        v-model="formValid"
        ref="form"
        class="pb-3"
        lazy-validation
    >
        <div class="text-h5 font-weight-light mb-2">Assignment</div>
        <v-row>
            <v-col cols="12">
                <!-- TODO: this should either restrict or pre-select the division of the requester -->
                <DivisionSelect
                    v-model="assignment.division"
                    :rules="$rules.objWithKey"
                    hide-details
                    dense
                />
            </v-col>
            <template v-if="!categoryAlt.hide.includes('usedBy')">
                <v-col>
                    <v-select
                        label="Who will this be used by?"
                        item-text="name"
                        class="mb-3"
                        :value="assignment.usedBy"
                        :items="userAssignmentOptions"
                        :rules="$rules.required"
                        @input="chgAssignment"
                        hide-details
                        dense
                    />
                </v-col>
                <v-col v-if="assignment.usedBy === 'A specific person'">
                    <SearchPeople
                        label="Assign to"
                        :autofocus="false"
                        :rules="$rules.required"
                        :value="assignment.assignTo"
                        @input="chgAssignment($event)"
                        employees-only
                        hide-details
                        dense
                    />
                </v-col>
            </template>
        </v-row>

        <template v-if="categoryAlt.installDevice.length || !categoryAlt.hide.includes('replaceDevice')">
            <div class="text-h5 font-weight-light mb-2 mt-4">Device</div>
            <v-row v-if="categoryAlt.installDevice.length">
                <v-col cols="12">
                    <v-select
                        label="What device will this be installed on?"
                        item-text="name"
                        :items="installDeviceOptions"
                        :rules="$rules.required"
                        :value="configuredItem.install && configuredItem.install.to"
                        @input="chgInstallDevice"
                        hide-details
                        dense
                    />
                </v-col>
                <v-col v-if="configuredItem.install && configuredItem.install.to === 'A specific device'" cols="12">
                    <SearchEndpoints
                        label="Search by user or tag #"
                        :rules="$rules.required"
                        :endpoint-type="categoryAlt.installDevice[0]"
                        :value="configuredItem.install.endpoint"
                        @input="chgInstallDevice"
                        dense
                        with-user
                    />
                </v-col>
            </v-row>
            <v-row v-if="!categoryAlt.hide.includes('replaceDevice')">
                <v-col>
                    <v-select
                        label="Will this replace another device?"
                        item-text="name"
                        :items="replacementOptions"
                        :rules="$rules.required"
                        :value="configuredItem.replacement && configuredItem.replacement.status"
                        @input="chgReplacement"
                        hide-details
                        dense
                    />
                </v-col>
                <template v-if="configuredItem.replacement && configuredItem.replacement.status && configuredItem.replacement.status.includes('Yes')">
                    <v-col cols="12" v-show="config.foundUserEndpoints">
                        <ExistingItems
                            class="mb-3"
                            :value="assignment.assignTo"
                            :endpoint-type="categoryAlt.slug"
                            :caption="`${categoryAlt.name} assigned to ${assignToName} (select any)`"
                            :select="configuredItem.replacement && configuredItem.replacement.endpoints"
                            @input="chgReplacement"
                            @foundEndpoints="config.foundUserEndpoints = true"
                        />
                    </v-col>
                    <v-col cols="12" v-if="configuredItem.replacement && !(configuredItem.replacement.endpoints || []).length">
                        <SearchEndpoints
                            label="Search by tag #"
                            :rules="$rules.required"
                            :value="configuredItem.replacement.endpoint"
                            :endpoint-type="categoryAlt.slug"
                            @input="chgReplacement"
                            dense
                        />
                    </v-col>
                </template>
            </v-row>
        </template>


        <div class="text-h5 font-weight-light mt-6 mb-2">Location</div>
        <LocationWidget
            v-model="assignment.location"
            ref="locWidg"
            :cartography="!categoryAlt.hide.includes('cartography')"
            @resetValidation="$refs.form.resetValidation()"
            hide-details
            dense
        >
            <template #alert="{ icon, alertType }">
                <v-alert
                    :type="alertType"
                    outlined
                    dense
                >
                    <div style="text-align: center; padding-bottom: 15px" v-if="icon">
                        <div class="overline">
                            Move the <img width="20" height="20" :src="icon" style="vertical-align: middle" /> icon on the floor plan below to the item's location
                        </div>
                        <span class="caption grey--text">You may zoom in and resize the icon as needed</span>
                    </div>
                </v-alert>
            </template>
        </LocationWidget>
    </v-form>
</template>
<script>

import { ref, reactive, watch, computed, onMounted } from '@vue/composition-api';
import DivisionSelect from '@/components/population/DivisionSelect';
import SearchPeople from '@/components/population/SearchPeople';
import SearchEndpoints from '@/components/it/inventory/SearchEndpoints';
import LocationWidget from '@/components/space/LocationWidget';
import ExistingItems from '@/components/it/inventory/ExistingItems';

import useItem from '@/composables/purchasing/useItem';
import objectHelper from '@/composables/common/object';

export default {
    name: 'InstallStep',
    components: {
        DivisionSelect,
        SearchPeople,
        SearchEndpoints,
        LocationWidget,
        ExistingItems
    },
    props: {},
    setup(props, context) {

        const store = context.root.$store;

        const { configuredItem, setConfiguredItem, categoryAlt } = useItem(context);

        const { omitKey } = objectHelper();

        const formValid = ref(true);

        const user = computed(() => store.getters['user']);

        const assignment = ref({
            division: {},
            usedBy: null,
            assignTo: null,
            location: {}
        });

        const config = reactive({
            foundUserEndpoints: false
        });

        const assignToName = computed(() => {
            const at = assignment.value.assignTo;
            if (!at) return '';
            return at.employee_id ? `${at.first_name} ${at.last_name}` : `${at.user.first_name} ${at.user.last_name}`;
        });

        const userAssignmentOptions = [{
            name: 'A specific person'
        }, {
            name: 'Myself'
        }, {
            name: 'Many people in the division (Shared)'
        }];

        const replacementOptions = [{
            name: 'No, this is a new device'
        }, {
            name: 'Yes, this is replacing an existing device'
        }];


        const installDeviceOptions = [{
            name: 'A specific device'
        }, {
            name: 'Not yet known'
        }];

        const chgAssignment = (val) => {
            let assignTo;
            switch (typeof(val)) {
                case 'string':
                    assignment.value.usedBy = val;
                    assignTo = null;
                    
                    if (val === 'Myself') {
                        assignTo = {
                            user: omitKey(['group_ids', 'groups', 'is_staff'], user.value)
                        };
                    }
        
                    break;
                case 'object':
                    assignTo = val;
                    break;
            }
            
            assignment.value.assignTo = assignTo;
        };

        const chgReplacement = (val) => {
            switch (typeof(val)) {
                case 'string': {

                    let payload = {replacement: {status: val}};

                    if (val.includes('No')) payload.noMerge = true;

                    setConfiguredItem(payload);
                    break;
                }
                case 'object':
                    if (Array.isArray(val)) {
                        setConfiguredItem({replacement: {endpoints: val}});
                    } else {
                        setConfiguredItem({replacement: {endpoint: val}});
                    }
                    break;
            }
        };

        const chgInstallDevice = (val) => {
            switch (typeof(val)) {
                case 'string':
                    setConfiguredItem({install: {to: val}});
                    break;
                
                case 'object':
                    setConfiguredItem({install: {endpoint: val}});
                    break;
            }
        };

        const validate = () => {
            const validations = [
                context.refs.form.validate(),
                context.refs.locWidg.validate()
            ];
            return validations.every(x => x);
        };

        watch(() => assignment.value, (newVal) => {
            setConfiguredItem({
                assignment: newVal
            });
        }, {deep: true});

        onMounted(() => {
            if (configuredItem.value.assignment) {
                assignment.value = configuredItem.value.assignment;
            }
        });
        
        return {
            config,
            configuredItem,
            formValid,
            validate,
            userAssignmentOptions,
            replacementOptions,
            installDeviceOptions,
            chgAssignment,
            chgReplacement,
            chgInstallDevice,
            assignment,
            assignToName,
            categoryAlt
        }
    },
};
</script>