<template>
    <v-list
        nav
    >
        <v-subheader class="overline">Categories</v-subheader>
        <v-list-item-group v-model="selected">
            <template v-for="(cat, i) in categories">
                <v-list-item
                    :key="i"
                    :to="{name: 'purchasingCategory', params: {to: slugParams.type, category: cat.slug}}"
                >
                    <v-list-item-icon v-if="cat.icon">
                        <v-icon v-text="cat.icon" />
                    </v-list-item-icon> 
                    <v-list-item-title
                        v-text="cat.name"
                    />
                </v-list-item>
            </template>
        </v-list-item-group>
    </v-list>
</template>
<script>
import { ref, computed, inject } from '@vue/composition-api';

export default {
    name: 'PurchaseCategories',
    components: {},
    setup(_, { root }) {

        const store = root.$store;

        const selected = ref(null);

        const categories = computed(() => store.getters['purchasing/getCategories']);

        const slugParams = inject('slugParams');

        return {
            selected,
            categories,
            slugParams
        }
    },
};
</script>