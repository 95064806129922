<template>
    <v-sheet
        class="pa-6"
        outlined
        rounded
    >
        <template v-if="config.loading">
            <div class="text-center body-1 px-10 pb-6 grey--text">
                Loading comments...
                <v-progress-linear
                    height="3"
                    indeterminate
                    rounded
                />
            </div>
        </template>
        <template v-else-if="!comments.length">
            <div class="text-center body-1 grey--text">No comments to show</div>
        </template>
        <template v-else>
            <CommentList
                created-value="created"
                comment-value="body"
                comment-id="id"
                :comments="comments"
            >
                <template v-slot:author="{ comment }">
                    <!-- {{ comment.created_by.first_name ? `${comment.created_by.first_name} ${comment.created_by.last_name}` : comment.created_by.username }} -->
                    {{ comment.author.fullname }}
                </template>
                
                <template
                    v-slot:action="{ comment }"
                >
                    <v-btn
                        v-if="comment.author.username === user.username"
                        color="error"
                        class="ml-1"
                        @click.stop="$refs.confirmDlg.show({id: comment.id})"
                        icon
                        small
                    >
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        small
                    >
                        <v-icon
                            @click="loadEditComment(comment.id)"
                            small
                        >mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <template v-slot:comment="{ comment }">
                    <template v-if="config.commentToEdit.id !== comment.id">
                        <div v-html="comment.body" />
                    </template>
                    <template v-else>
                        <MentionWidget
                            :value="config.commentToEdit.comment"
                            :preload-mentions="config.commentToEdit.mentions"
                            @save="editComment(comment.id, $event)"
                            @cancel="config.commentToEdit = {}"
                            outlined
                            show-save
                        />
                    </template>
                </template>
            </CommentList>
        </template>
        <MentionWidget
            class="mt-3"
            ref="newComment"
            outlined
            @valid="showAddComment($event)"
        />
        <ConfirmDialog
            ref="confirmDlg"
            title="Are you sure you want to delete this comment?"
            confirm-button-text="Delete"
            confirm-button-icon="mdi-trash-can-outline"
            @confirm="deleteComment"
        />
        <template>
            <div class="mt-3 text-center">
                <v-slide-x-transition>
                    <v-btn
                        v-show="config.showAddComment"
                        color="blue darken-1"
                        text
                        @click="addComment"
                        :loading="config.loadingCommentAction"
                    >
                        Add Comment
                    </v-btn>
                </v-slide-x-transition>

            </div>
            
        </template>
    </v-sheet>
</template>
<script>
import axios from 'axios';
import { ref, reactive, onMounted, computed } from '@vue/composition-api';

import MentionWidget from '@/components/common/MentionWidget';
import CommentList from '@/components/common/CommentList';
import ConfirmDialog from '@/components/common/ConfirmDialog';

import jiraHelper from '@/composables/common/jira';

export default {
    name: 'RequestComments',
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        },
    },
    components: {
        MentionWidget,
        CommentList,
        ConfirmDialog
    },
    setup(props, { refs, root }) {

        const store = root.$store;

        const user = computed(() => store.getters['user']);

        const config = reactive({
            loading: false,
            commentToEdit: {},
            showAddComment: false,
            loadingCommentAction: true
        });

        const comments = ref([]);

        const { loadAttachment } = jiraHelper();

        const load = async () => {
            config.loading = true;
            // const response = await axios.get(`/purchasing/load_comments/143/`);
            
            const response = await axios.get(`/jira/load_comments/${props.request.jira_key}/`);

            comments.value = response.data.comments;
            config.loading = false;
            config.loadingCommentAction = false;
        };

        const addComment = async () => {
            // await store.dispatch('purchasing/requestAction', {
            //     pk: props.request.jira_key,
            //     action: 'add_comment',
            //     comment: refs.newComment.getComment()
            // });
            config.loadingCommentAction = true;

            const response = await axios.post(`/jira/add_comment/${props.request.jira_key}/`, {
                comment: refs.newComment.getComment()
            });
            
            if (response.data.success) {
                refs.newComment.clear();
                load();
            }
            
        };


        const deleteComment = async (c) => {

            
            // await store.dispatch('purchasing/requestAction', {
            //     pk: props.request.jira_key,
            //     action: 'delete_comment',
            //     commentId: c.id
            // });
            config.loadingCommentAction = true;
            const response = await axios.post(`/jira/delete_comment/${props.request.jira_key}/${c.id}/`);
            
            if (response.data.success) {
                load();
            }

        };

        const loadEditComment = async (cid) => {

            if (config.commentToEdit.id === cid) {
                config.commentToEdit = {};
                return;
            }

            const response = await axios.get(`/jira/load_edit_comment/${props.request.jira_key}/${cid}/`);
            
            config.commentToEdit = response.data;

        };

        const editComment = async (cid, comment) => {
            config.loadingCommentAction = true;
            const response = await axios.post(`/jira/edit_comment/${props.request.jira_key}/${cid}/`, {
                comment: comment
            });
            
            if (response.data.success) {
                config.commentToEdit = {};
                load();
            }
        };

        const showAddComment = (evt) => {
            config.showAddComment = evt;
        }

        // created
        window.globalFn.loadAttachment = loadAttachment;
        
        onMounted(() => load());
        
        

        return {
            load,
            addComment,
            deleteComment,
            loadEditComment,
            editComment,
            showAddComment,
            loadAttachment,
            comments,
            config,
            user
        }
    }
};
window.globalFn = {}
</script>