import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"grey darken-2","block":_vm.block,"text":!_vm.iconOnly,"icon":_vm.iconOnly,"small":!_vm.iconOnly},on:{"click":_vm.selectItem}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":!_vm.iconOnly,"small":_vm.iconOnly}},[_vm._v("mdi-pencil")]),(!_vm.iconOnly)?[_vm._v("Edit")]:_vm._e()],2)]}}]),model:{value:(_vm.config.showDialog),callback:function ($$v) {_vm.$set(_vm.config, "showDialog", $$v)},expression:"config.showDialog"}},[(_vm.config.showDialog)?_c('ItemStepper',{attrs:{"cancel-text":"Close","finish-text":"Update","item":_vm.item,"loading":_vm.config.loading,"edit":"","ticketStatus":_vm.ticketStatus},on:{"cancel":_vm.cancel,"finish":_vm.editItem}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }