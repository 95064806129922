<template>
    <v-main>
        <v-container class="stretch-container">
            <v-row class="mt-3" dense>
                <v-col class="text-h4">Submit Purchase Request</v-col>
                <v-col class="d-flex align-center justify-end">
                    <v-btn
                        color="primary"
                        :to="{name: 'purchasingCart'}"
                        text
                    >
                        <v-icon left>mdi-cart</v-icon>
                        View / Edit Cart
                    </v-btn>
                </v-col>
            </v-row>
            
            <CartItems
                dense
            />

            <CartTotal />
            <v-sheet
                class="pa-5 mt-5 mb-5"
                outlined
                rounded
            >
                <div
                    :class="[config.heading, 'mb-2']"
                    v-text="purchaseRequestDivisions.length > 1 ? 'Primary Division' : 'Division'"
                />
                <DivisionSelect
                    :value="prExtra.division"
                    :rules="$rules.objWithKey"
                    :label="undefined"
                    @input="setCurrentDivision($event)"
                    dense
                    hide-details
                    no-label
                />
                

                <div :class="[config.heading, 'mb-2 mt-5']">Chart String</div>
                <ChartStringWidget
                    v-model="prExtra.chartString"
                    :amount="totalPrice"
                    ref="cs"
                />


                <div :class="config.heading">Notes</div>
                <v-textarea
                    v-model="prExtra.notes"
                    height="100"
                    hide-details
                    dense
                    outlined
                />

                <div
                    v-if="config.showDivisonPreApprove"
                    class="mt-5"
                >
                    <v-checkbox
                        v-model="prExtra.skipDivisionApprove"
                        label="This request has Division Approval"
                        hide-details
                        dense
                    />
                </div>

                <div class="text-center mt-10 mb-5">
                    <v-btn
                        color="primary"
                        :disabled="!config.formValid || config.submitLoading || !canSubmit"
                        :loading="config.submitLoading"
                        @click="submit"
                        large
                    >
                        <v-icon left>mdi-arrow-right-circle</v-icon>
                        Submit Purchase Request
                    </v-btn>
                </div>
            </v-sheet>
        </v-container>
        <LoaderDialog
            ref="submitLoadingDlg"
            :loading="config.submitLoading"
            loadingTitle="Creating purchase tickets..."
            finishLoadingTitle="The following tickets have been created"
            confirm-button-text="OK"
            confirm-button-icon="mdi-check-circle"
            confirm-button-color="blue darken-1"
            @confirm="confirmLoadingDlg"
        >
            <template 
                v-slot:dialog
            >
                <div v-if="tickets.length > 0">  
                    <v-row 
                    v-for="t in tickets" :key="t.jira_key"
                    dense
                >   
                    <v-col offset="1">
                        <!-- <div class="body-1"><a :href="getJiraLink(t.jira_key)" target="_blank">{{ t.jira_key }}</a> &#8212; {{ t.item }} </div> -->
                        <div class="body-1"><a :href="t.jira_link" target="_blank">{{ t.jira_key }}</a> &#8212; {{ t.item }} </div>
                    </v-col>
                </v-row>
                </div>
                

                <div v-if="errorTickets.length > 0">
                    <span>Error creating the items below, please contact a Surgery Connect administrator.</span>
                    <v-row 
                        v-for="t, i in errorTickets" :key="i"
                        dense
                    > 
                        <v-col offset="1" v-if="t.error">
                            <!-- <div class="body-1"><a :href="getJiraLink(t.jira_key)" target="_blank">{{ t.jira_key }}</a> &#8212; {{ t.item }} </div> -->
                            <div class="body-1">ERROR: {{ t.item }} &#8212; {{ t.msg }} </div>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </LoaderDialog>
    </v-main>
</template>
<script>

import axios from 'axios';

import { reactive, computed, onMounted, ref } from '@vue/composition-api';
import cartHelper from '@/composables/purchasing/cartHelper';

import objectHelper from '@/composables/common/object';


import ChartStringWidget from '@/components/purchasing/ChartStringWidget';
import CartTotal from '@/components/purchasing/cart/CartTotal';
import CartItems from '@/components/purchasing/cart/CartItems';
import DivisionSelect from '@/components/population/DivisionSelect';
import LoaderDialog from '@/components/common/LoaderDialog';


export default {
    components: {
        CartItems,
        ChartStringWidget,
        CartTotal,
        DivisionSelect,
        LoaderDialog
    },
    setup(_, context) {

        const store = context.root.$store;

        const {
            totalPrice,
            loadCart,
            purchaseRequestItemTotal,
            purchaseRequestDivisions
        } = cartHelper(context);

        const config = reactive({
            formValid: true,
            submitLoading: false,
            heading: 'text-h5 font-weight-light grey--text text--darken-2',
            showDivisonPreApprove: false
        });

        const prExtra = reactive({
            chartString: [{}],
            division: undefined,
            skipDivisionApprove: false,
            notes: null
        });

        const tickets = ref([])
        const errorTickets = ref([])

        const { isEmptyObj } = objectHelper();

        const setCurrentDivision = async (div) => {
            const response = await axios.post('/purchasing/is_authorized_for_division/', div);
            
            if (response.data.approver) {
                config.showDivisonPreApprove = true;
                prExtra.skipDivisionApprove = true;
            } else {
                config.showDivisonPreApprove = prExtra.skipDivisionApprove = false;
            }

            prExtra.division = div;
        };

        onMounted(() => {

            loadCart();

            if (purchaseRequestDivisions.value.length === 1) {
                setCurrentDivision(purchaseRequestDivisions.value[0]);
            }

            if (purchaseRequestItemTotal.value === 0) {
                context.root.$router.push({
                    name: 'purchasingCart'
                });
            }
        });

        const canSubmit = computed(() => (
                prExtra.division &&
                prExtra.chartString.every(x => !isEmptyObj(x))
        ));

        const submit = async () => {
            const isValid = context.refs.cs.validate();
            const loadingDlg = context.refs.submitLoadingDlg;

            if (!isValid) return;

            config.submitLoading = true;
            loadingDlg.show()

            let createdTickets = await store.dispatch('purchasing/submitPurchaseRequest', prExtra);
        
            tickets.value = createdTickets.created
            errorTickets.value = createdTickets.error
        
            config.submitLoading = false;
        };
        
        const confirmLoadingDlg = () => {
            context.refs.submitLoadingDlg.close();
            tickets.value = [];
            context.root.$router.push({ 
                name: 'purchasingOrders'
            });
        }

        const getJiraLink = (key) => {
            return `https://jira.surgery.columbia.edu/browse/${key}`;
        }  

        return {
            prExtra,
            totalPrice,
            config,
            submit,
            purchaseRequestDivisions,
            canSubmit,
            setCurrentDivision,
            tickets,
            confirmLoadingDlg,
            getJiraLink,
            errorTickets
        };
    }
};
</script>