import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"unset","persistent":""},model:{value:(_vm.config.showDialog),callback:function ($$v) {_vm.$set(_vm.config, "showDialog", $$v)},expression:"config.showDialog"}},[_c(VCard,[(_vm.loading)?[_c(VCardTitle,{domProps:{"textContent":_vm._s(_vm.loadingTitle)}}),_c(VCardText,[_c('ProgressLoader')],1)]:[(_vm.finishLoadingTitle)?_c(VCardTitle,{domProps:{"textContent":_vm._s(_vm.finishLoadingTitle)}}):_vm._e(),_c(VCardText,[_vm._t("dialog")],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.confirmButtonColor,"disabled":_vm.confirmButtonDisabled,"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirm.apply(null, arguments)}}},[(_vm.confirmButtonIcon)?_c(VIcon,{domProps:{"textContent":_vm._s(_vm.confirmButtonIcon)}}):_vm._e(),_vm._v(" "+_vm._s(_vm.confirmButtonText)+" ")],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }