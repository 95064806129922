<template>
    <div style="display: contents">
        <div
            v-for="(v, k, i) in purchaseRequest"
            :key="`pr${i}`"
        >
            <div class="text-h5 font-weight-light grey--text text--darken-2" v-text="k" />
            <div
                :class="[{'mb-12': !dense}, 'my-4']"
                :style="{'background: #000': dense}"
                v-for="(cartItem, i2) in v"
                :key="`ci${i2}`"
            >
                <CartItem
                    :item="cartItem"
                    :dense="dense"
                    :editable="editable"
                    @removeItem="$emit('removeItem', $event)"
                    @editItem="$emit('editItem')"
                    @openLoc="openLoc"
                />
                <v-divider
                    v-if="i2 < purchaseRequestItemTotal - 1"
                    :class="[!dense ? 'mt-12 mb-12' : 'mt-3 mb-3', 'grey lighten-3']"
                />
            </div>
        </div>
        <v-dialog
            v-model="config.locDlg"
            transition="dialog-bottom-transition"
            width="800"
            @click:outside="closeLoc"
            eager
            scrollable
        >
            <v-card height="700px"> 
                <v-toolbar dark color="grey darken-3">
                    <span class="text-h5 font-weight-light" v-text="config.locDlgTitle" />
                    <v-spacer />
                    <v-btn
                        @click="closeLoc"
                        icon
                        dark
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <div id="pspdfkit" style="width: 100%; height: 100%" />
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import { reactive } from '@vue/composition-api';

import locationHelper from '@/composables/space/locationHelper';
import cartHelper from '@/composables/purchasing/cartHelper';

import CartItem from '@/components/purchasing/cart/CartItem';

export default {
    components: {
        CartItem,
    },
    props: {
        dense: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    emits: ['removeItem', 'editItem'],
    setup(props, context) {
        const {
            purchaseRequest,
            purchaseRequestItemTotal,
        } = cartHelper(context);

        const {
            addAnnotation,
            loadPDF,
            unloadPDF
        } = locationHelper(context);

        const config = reactive({
            locDlg: false,
            locDlgTitle: ''
        });


        let pspdfInstance;

        const openLoc = async (loc) => {
            config.locDlg = true;
            config.locDlgTitle = loc.name;

            pspdfInstance = await loadPDF(pspdfInstance, loc.annotation.floor_plan.url);

            await addAnnotation(pspdfInstance, loc.annotation.bbox);
            
            pspdfInstance.setViewState(v => v.set('readOnly', true));
            
        };

        const closeLoc = () => {
            config.locDlg = false;
            config.locDlgTitle = '';
            unloadPDF();
        };

        return {
            purchaseRequest,
            purchaseRequestItemTotal,
            config,
            openLoc,
            closeLoc
        };
    }
};
</script>