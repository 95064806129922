<template>
    <v-menu
        v-model="config.menu"
        v-if="numActions"
        offset-y
        dense
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                text
                small
                block
            >
                <v-icon
                    v-if="showWarn"
                    color="orange"
                    class="mr-1"
                >mdi-alert-circle-outline</v-icon>
                Actions<v-icon>mdi-chevron-down</v-icon>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item-group>
                <template v-for="(v, k, i) in ticket.data.transitions">
                    <v-list-item
                        v-if="actions[k]"
                        :key="i"
                        @click.stop="menuClick(actions[k])"
                    >
                        <v-list-item-title>
                            <v-icon
                                v-if="actions[k].icon"
                                :color="actions[k].iconColor"
                                v-text="actions[k].icon"
                                left
                            />
                            {{ actions[k].name }}
                        </v-list-item-title>
                    </v-list-item>
                </template>

                <!--
                <PurchaseRequestActionDialog
                    v-for="(v, k, i) in item.actions"
                    :item="item"
                    :actionKey="k"
                    :key="i"
                    @clicked="openMenu = false"
                />
                -->
            </v-list-item-group>
        </v-list>
        <v-dialog
            v-model="config.dialog"
            :max-width="dialog.width || 600"
        >
            <template v-if="!dialog.component">
                <v-card>
                    <v-card-title>
                        <span v-text="dialog.title" />
                        <v-spacer />
                        <v-icon @click="config.dialog = false">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <span v-text="dialog.content" />
                        <div
                            class="mt-2"
                            v-if="dialog.showRequest"
                        >
                            <span class="body-1 " v-text="ticket.data.summary" />
                            <div class="mt-2">
                                Total: <strong v-text="fmtNumber(ticket.total_est_price, {currency: true})" />
                            </div>
                        </div>

                        <MentionWidget
                            v-if="dialog.comment"
                            class="mt-3"
                            ref="comment"
                            height="100"
                            :default-text="dialog.commentLabel || 'Comment (optional)'"
                            outlined
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <template v-for="(v, k, i) in dialog.actions">
                            <v-btn
                                :color="v.color"
                                :key="`act${i}`"
                                @click.stop="v.click"
                                text
                                block
                            >
                                <v-icon
                                    class="mr-1"
                                    v-if="v.icon"
                                    v-text="v.icon"
                                />
                                {{ k }}
                            </v-btn>
                        </template>
                    </v-card-actions>
                </v-card>
            </template>
            <template v-else>
                <component
                    v-if="config.dialog"
                    :is="dialog.component"
                    :request="ticket"
                    :filter="filter"
                    :selected="selected"
                    @close="close"
                    @reset="reset"
                />
            </template>
        </v-dialog>
    </v-menu>
</template>
<script>
import axios from 'axios';

import { ref, computed, reactive } from '@vue/composition-api';

import numberHelper from '@/composables/common/number';

import RequestEdit from '@/components/purchasing/actions/RequestEdit';
import RequestITApprove from '@/components/purchasing/actions/RequestITApprove';
import RequestFinanceApprove from '@/components/purchasing/actions/RequestFinanceApprove';
import RequestSubmitToVendor from '@/components/purchasing/actions/RequestSubmitToVendor';
import RequestSubmitToPurchasing from '@/components/purchasing/actions/RequestSubmitToPurchasing';
import MentionWidget from '@/components/common/MentionWidget';


export default {
    name: 'RequestActions',
    props: {
        ticket: {
            type: Object, 
            default() {
                return {}
            }
        },
        filter: {
            type: String
        },
        selected: {
            type: Number
        }
    },
    components: {
        RequestITApprove,
        RequestFinanceApprove,
        MentionWidget
    },
    emits: ['close', 'reset'],
    setup(props, { root, refs, emit }) {

        const store = root.$store;
        const numActions = computed(() => Object.keys(props.ticket.data.transitions).length);

        const showWarn = computed(() => Object.values(props.ticket.data.transitions).some(x => x.warning));
        
        const { fmtNumber } = numberHelper();

        const config = reactive({
            menu: false,
            dialog: false
        });

        const dialog = ref({});

        const close = () => {
            config.menu = false;
            config.dialog = false;
            dialog.value = {};
        };

        const reset = () => {
            emit('reset')
        }

        const requestAction = (payload) => {
            store.dispatch('purchasing/requestAction', {
                ticket_pk: props.ticket.pk,
                pr_pk: props.ticket.pr_pk,
                filter: props.filter,
                jira_key: props.ticket.jira_key,
                ...payload
            });
            emit('close');
            reset();
        };

        const divApprove = () => {
            requestAction({
                action: 'division_approve'
            });
        };

        const cancelRequest = async () => {
            const response = await axios.get(`/purchasing/load_request/${props.ticket.pr_pk}/`);

            if (!response.data.pk) return;

            let payload = {
                ...response.data,
                changelog: {
                    change: [],
                    remove: [],
                }
            }

            props.ticket.items.forEach(item => {
                payload.changelog.remove.push(item.pk)
            })

            requestAction({
                action: 'cancel',
                comment: refs.comment.getComment(),
                refreshNav: true,
                updateData: payload
            });
        };

        const chgStatus = (status) => {
            requestAction({
                action: 'change_status',
                comment: refs.comment.getComment(),
                status: status
            });
        };

        const actions = {
            'division_approve': {
                name: 'Division Approve',
                icon: 'mdi-check',
                iconColor: 'success',
                dialog: {
                    title: 'Approve Purchase Request',
                    showRequest: true,
                    actions: {
                        'Approve Purchase Request': {
                            color: 'success',
                            icon: 'mdi-check-circle-outline',
                            click: divApprove
                        }
                    }
                }
            },
            'it_approve': {
                name: 'IT Approve',
                icon: 'mdi-check',
                iconColor: 'success',
                dialog: {
                    component: RequestITApprove,
                    width: 1000
                }
            },
            'back_to_division': {
                name: 'Back to Division Review',
                icon: 'mdi-undo',
                dialog: {
                    title: 'Back to Division Review',
                    comment: true,
                    actions: {
                        'Back to Division Review': {
                            color: 'primary',
                            icon: 'mdi-undo',
                            click: () => chgStatus('Division Review')
                        }
                    }
                }
            },
            'back_to_it': {
                name: 'Back to IT Review',
                icon: 'mdi-undo',
                dialog: {
                    title: 'Back to IT Review',
                    comment: true,
                    actions: {
                        'Back to IT Review': {
                            color: 'primary',
                            icon: 'mdi-undo',
                            click: () => chgStatus('IT Review')
                        }
                    }
                }
            },
            'cancel': {
                name: 'Cancel',
                icon: 'mdi-close',
                iconColor: 'error',
                dialog: {
                    title: 'Cancel Purchase Request',
                    showRequest: true,
                    comment: true,
                    actions: {
                        'Cancel Purchase Request': {
                            color: 'error',
                            icon: 'mdi-close-circle-outline',
                            click: cancelRequest
                        }
                    }
                }
            },
            'edit': {
                name: 'Edit',
                icon: 'mdi-pencil',
                dialog: {
                    component: RequestEdit,
                    width: 1000
                }
            },
            'finance_approve': {
                name: 'Finance Approve',
                icon: 'mdi-check',
                iconColor: 'success',
                dialog: {
                    component: RequestFinanceApprove,
                    width: 1000
                }
            },
            'submit_to_vendor': {
                name: 'Submit to Vendor',
                icon: 'mdi-cart-arrow-right',
                dialog: {
                    component: RequestSubmitToVendor,
                    width: 1000
                }
            },
            'submit_to_purchasing': {
                name: 'Submit to Purchasing',
                icon: 'mdi-cart-arrow-right',
                dialog: {
                    component: RequestSubmitToPurchasing,
                    width: 1000
                }
            },
        };

        const menuClick = (act) => {
            dialog.value = act.dialog ? act.dialog : {};
            config.menu = false;
            config.dialog = true;
        };


        return {
            numActions,
            showWarn,
            config,
            actions,
            menuClick,
            dialog,
            fmtNumber,
            reset,
            close
        }
    }
};
</script>