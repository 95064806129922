import { computed } from '@vue/composition-api';

import numberHelper from '@/composables/common/number';

export default function useItem({ root }) {

    const store = root.$store;

    const itemFull = computed(() => store.getters['purchasing/getItem']);
    const configuredItem = computed(() => store.getters['purchasing/getConfiguredItem']);
    const configuredItemPrice = computed(() => store.getters['purchasing/getConfiguredItemPrice']);
    const category = computed(() => store.getters['purchasing/getCategory']);

    const categoryAlt = computed(() => {
        const cat = category.value;
        return {
            name: cat.name.replace(/Desktop|Laptop/, 'Computer'),
            slug: cat.slug.replace(/desktop|laptop/, 'computer'),
            hide: cat.meta?.hide || [],
            installDevice: cat.meta?.installDevice || []
        }
    });

    const configuredItemHide = computed(() => {
        const ci = configuredItem.value;
        return ci.item.meta?.hide || []
    });

    const selectOption = async (opt) => {
        await store.dispatch('purchasing/setConfiguredItemOption', opt);
    };

    const selectConfig = async (k, conf) => {
        await store.dispatch('purchasing/setConfiguredItem', {
            config: k,
            value: conf
        });
    };

    const selectAccessoryConfig = async (item, conf) => {
        await store.dispatch('purchasing/setConfiguredItemAccessory', {
            item: item,
            value: conf
        })
    };

    const setConfiguredItem = async (payload) => {
        await store.dispatch('purchasing/setConfiguredItem', payload);
    };

    const loadConfiguredItem = async (item) => {
        await store.dispatch('purchasing/loadConfiguredItem', item);
            
        if (!configuredItem.value.option && itemFull.value.default_option) {
            selectOption(itemFull.value.options.find(x => x.pk === itemFull.value.default_option));
        }
    };

    const removeConfiguredItem = async (payload) => {
        await store.dispatch('purchasing/removeConfiguredItem', payload);
    };

    const resetItem = async () => {
        await store.dispatch('purchasing/resetItem');
    };

    const { fmtNumber } = numberHelper();

    const getPrice = (item) => {
        let postPrice = '';
        
        if (item.has_options || item.has_config) {
            postPrice = '+';
        }
        
        // postPrice = item.has_options ? '+' : '';
        return (!item.meta.tbd_price ? fmtNumber(item.est_price_w_reqs, {currency: true}) : '$ TBD') + postPrice
    };

    const getAccessoryPrice = (acc) => {
        let price = 0;
        if (acc.color) {
            if (acc.color[1].price){
                price = Number.parseFloat(acc.color[1].price);
            } else {
                price = Number.parseFloat(acc.item.est_price_w_reqs);
            }
        } else {
            price = Number.parseFloat(acc.item.est_price_w_reqs);
        }

        if (acc.config) {
            for (let prop in acc.config) {
                if (acc.config[prop].price) {
                    price += Number.parseFloat(acc.config[prop].price);
                }
            }
        }

        return price
    };

    return {
        itemFull,
        configuredItem,
        configuredItemPrice,
        category,
        categoryAlt,
        configuredItemHide,
        selectOption,
        selectConfig,
        setConfiguredItem,
        loadConfiguredItem,
        removeConfiguredItem,
        resetItem,
        getPrice,
        selectAccessoryConfig,
        getAccessoryPrice
    }
}