import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"pa-6",attrs:{"outlined":"","rounded":""}},[(_vm.config.loading)?[_c('div',{staticClass:"text-center body-1 px-10 pb-6 grey--text"},[_vm._v(" Loading comments... "),_c(VProgressLinear,{attrs:{"height":"3","indeterminate":"","rounded":""}})],1)]:(!_vm.comments.length)?[_c('div',{staticClass:"text-center body-1 grey--text"},[_vm._v("No comments to show")])]:[_c('CommentList',{attrs:{"created-value":"created","comment-value":"body","comment-id":"id","comments":_vm.comments},scopedSlots:_vm._u([{key:"author",fn:function(ref){
var comment = ref.comment;
return [_vm._v(" "+_vm._s(comment.author.fullname)+" ")]}},{key:"action",fn:function(ref){
var comment = ref.comment;
return [(comment.author.username === _vm.user.username)?_c(VBtn,{staticClass:"ml-1",attrs:{"color":"error","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.confirmDlg.show({id: comment.id})}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1):_vm._e(),_c(VBtn,{attrs:{"icon":"","small":""}},[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.loadEditComment(comment.id)}}},[_vm._v("mdi-pencil")])],1)]}},{key:"comment",fn:function(ref){
var comment = ref.comment;
return [(_vm.config.commentToEdit.id !== comment.id)?[_c('div',{domProps:{"innerHTML":_vm._s(comment.body)}})]:[_c('MentionWidget',{attrs:{"value":_vm.config.commentToEdit.comment,"preload-mentions":_vm.config.commentToEdit.mentions,"outlined":"","show-save":""},on:{"save":function($event){return _vm.editComment(comment.id, $event)},"cancel":function($event){_vm.config.commentToEdit = {}}}})]]}}])})],_c('MentionWidget',{ref:"newComment",staticClass:"mt-3",attrs:{"outlined":""},on:{"valid":function($event){return _vm.showAddComment($event)}}}),_c('ConfirmDialog',{ref:"confirmDlg",attrs:{"title":"Are you sure you want to delete this comment?","confirm-button-text":"Delete","confirm-button-icon":"mdi-trash-can-outline"},on:{"confirm":_vm.deleteComment}}),[_c('div',{staticClass:"mt-3 text-center"},[_c(VSlideXTransition,[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.config.showAddComment),expression:"config.showAddComment"}],attrs:{"color":"blue darken-1","text":"","loading":_vm.config.loadingCommentAction},on:{"click":_vm.addComment}},[_vm._v(" Add Comment ")])],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }