<template>
    <v-sheet
        class="px-5 pb-5 pt-2"
        :elevation="!outlined ? 1 : undefined"
        :outlined="outlined"
        rounded
    >
        <v-row
            v-for="(item, i) in items"
            class="body-1 mb-6"
            :key="item.pk"
            dense
        >
            <v-col
                cols="auto"
                class="text-h4 font-weight-light grey--text"
                v-text="`${i + 1}.`"
            />

            <v-col cols="1">
                <ItemImage
                    :height="40"
                    :item="item.item"
                />
            </v-col>


            <v-col class="pl-4">

                <!--   NAME   -->
                <v-row
                    align="center"
                    dense
                >
                    <v-col
                        cols="9"
                        class="text-h5"
                        v-text="item.item.name"
                    />
                    <template v-if="!itemsBreakdown[item.item.pk].option">
                        <v-col>
                            <v-select
                                item-text="name"
                                :items="sourceList"
                                :value="itemsBreakdown[item.item.pk].source"
                                @change="chgSource(item.item.pk, 'item', $event)"
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                prefix="$"
                                :value="item.item.est_price"
                                @input="chgPrice(i, 'item', $event)"
                                @keypress="onlyNumber($event, {decimal: true})"
                                dense
                                hide-details
                            />
                        </v-col>
                    </template>
                </v-row>


                <template v-for="(v, k, i2) in itemsBreakdown[item.item.pk]">
                    <v-row
                        align="center"
                        class="body-2"
                        :key="`ib${i2}`"
                        dense
                    >
                        <!--    OPTION    -->
                        <template v-if="k === 'option'">
                            <v-col cols="12" class="text-h6 text--secondary">Option</v-col>
                            <v-col cols="9" v-text="item.option.name" />
                            <v-col>
                                <v-select
                                    item-text="name"
                                    :value="itemsBreakdown[item.item.pk].source"
                                    :items="sourceList"
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                    prefix="$"
                                    :value="item.option.est_price"
                                    :rules="$rules.required"
                                    @keypress="onlyNumber($event, {decimal: true})"
                                    @input="chgPrice(i, 'option', $event)"
                                    dense
                                    hide-details
                                />
                            </v-col>
                        </template>


                        <!--   COLOR   -->
                        <template v-else-if="k === 'color'">
                            <v-col cols="12" class="text-h6 text--secondary">Color</v-col>
                            <v-col cols="9">
                                <v-icon
                                    v-if="itemsBreakdown[item.item.pk].color.color"
                                    :color="itemsBreakdown[item.item.pk].color.color"
                                    left
                                    small
                                >mdi-circle</v-icon>
                                {{ itemsBreakdown[item.item.pk].color.name }}
                            </v-col>
                            <v-col>
                                <!--
                                <v-select
                                    v-show="itemsBreakdown[item.item.pk].color.price"
                                    item-text="name"
                                    :items="sourceList"
                                    dense
                                    hide-details
                                    disabled
                                />
                                -->
                            </v-col>
                            <v-col>
                                <v-text-field
                                    prefix="$"
                                    value="Incl."
                                    disabled
                                    dense
                                    hide-details
                                />
                            </v-col>
                        </template>



                        <!--    CONFIG    -->
                        <template v-else-if="k === 'config'">
                            <v-col cols="12" class="text-h6 text--secondary">Configuration</v-col>
                            <v-col
                                v-for="(c, k2, i3) in itemsBreakdown[item.item.pk].config"
                                cols="12"
                                :key="i3"
                                :class="['py-2', {'grey lighten-3 rounded': i3 % 2}]"
                            >
                                <v-row
                                    align="center"
                                    dense
                                >
                                    <v-col
                                        class="font-weight-bold text-end"
                                        cols="2"
                                        v-text="`${k2}:`"
                                    />
                                    <v-col cols="7" v-text="c.name" />
                                    <v-col>
                                        <!--
                                        <v-select
                                            v-show="c.price"
                                            item-text="name"
                                            :items="sourceList"
                                            :value="c.source || 'Vendor'"
                                            dense
                                            hide-details
                                        />
                                        -->
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-if="c.price"
                                            prefix="$"
                                            :value="c.price"
                                            @keypress="onlyNumber($event, {decimal: true})"
                                            @input="chgPrice(i, 'config', $event, k2)"
                                            dense
                                            hide-details
                                        />
                                        <v-text-field
                                            v-else
                                            prefix="$"
                                            value="Incl."
                                            disabled
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                
                            </v-col>
                        </template>



                        <!--    SOFTWARE    -->
                        <template v-else-if="k === 'software' && v.length">
                            <v-col cols="12" class="text-h6 text--secondary">Software</v-col>
                            <v-col
                                v-for="(sw, si) in v"
                                cols="12"
                                :key="si"
                                :class="['py-2', {'grey lighten-3 rounded': si % 2}]"
                            >
                                <v-row
                                    align="center"
                                    dense
                                >
                                    <v-col cols="1" v-if="sw.image">
                                        <v-img
                                            height="20"
                                            :src="sw.image"
                                            contain
                                        />
                                    </v-col>
                                    <v-col cols="8" v-text="sw.name" />
                                    <v-col>
                                        <v-select
                                            item-text="name"
                                            :items="sourceList"
                                            :value="sw.source || 'Vendor'"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            prefix="$"
                                            :value="sw.est_price"
                                            @keypress="onlyNumber($event, {decimal: true})"
                                            @input="chgPrice(i, 'software', $event, si)"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </template>


                        <!--    ACCESSORIES    -->
                        <template v-else-if="k === 'accessories'">
                            <v-col cols="12" class="text-h6 text--secondary">Accessories</v-col>
                            <v-col
                                v-for="(av, ak, ai) in v"
                                cols="12"
                                :key="ai"
                                :class="['py-2', {'grey lighten-3 rounded': ai % 2}]"
                            >
                                <v-row
                                    align="center"
                                    dense
                                >
                                    <!--
                                    <v-col cols="1" v-if="av.item.image">
                                        <v-img
                                            height="20"
                                            :src="av.item.image"
                                            contain
                                        />
                                    </v-col>
                                    -->
                                    <v-col class="pl-4" cols="9">
                                        <strong v-text="`${av.qty || 1} x`" />
                                        {{ av.item.name }}
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            item-text="name"
                                            :items="sourceList"
                                            :value="av.source"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            prefix="$"
                                            :value="Math.round((av.item.est_price * av.qty || 1) * 100) / 100"
                                            @keypress="onlyNumber($event, {decimal: true})"
                                            @change="chgPrice(i, 'accessory', $event, ak)"
                                            @focus="$emit('pendingChange', true)"
                                            @blur="$emit('pendingChange', false)"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </template>




                        <!--    OTHER    -->
                        <template v-else-if="k === 'other' && v.applecare && v.applecare.price">
                            <v-col cols="12" class="text-h6 text--secondary">Other</v-col>
                            <v-col cols="12">
                                <v-row
                                    align="center"
                                    dense
                                >
                                    <v-col cols="9">
                                        AppleCare+
                                        <div class="text--secondary">3 years of accidental damage protection and expert technical support from Apple</div>
                                    </v-col>
                                    <v-col>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            prefix="$"
                                            :value="v.applecare.price"
                                            @keypress="onlyNumber($event, {decimal: true})"
                                            @input="chgPrice(i, 'other', $event, 'applecare')"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </template>
                    </v-row>

                </template>
            </v-col>
        </v-row>

        



        <v-col cols="12">
            <v-divider />
        </v-col>


        <!--    TOTAL    -->
        <v-row
            v-if="fPriceDiff.isDiff"
            class="body-1 font-weight-bold"
            dense
        >
            <v-col
                class="text-end text--secondary mr-4"
            >
                Estimated Total
            </v-col>
            <v-col
                cols="auto"
                class="mr-9"
            >
                <div class="text--secondary" v-text="fmtNumber(request.total_est_price, {currency: true})" />
            </v-col>
        </v-row>
        <v-row
            class="body-1 font-weight-bold"
            dense
        >
            <v-col
                class="text-end mr-4"
            >
                Final Total
                <div
                    v-if="fPriceDiff.dPct"
                    :class="['mt-n1', 'caption', fPriceDiff.dPct > 0 ? 'success--text' : 'error-text']"
                >
                    {{ fPriceDiff.dPct > 0 ? '+' : '' }}{{ fmtNumber(fPriceDiff.dPct) }}%
                </div>
            </v-col>
            <v-col
                cols="auto"
                class="mr-9"
            >
                {{ fmtNumber(itemsBreakdown.total, {currency: true}) }}
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>

import { computed, onMounted } from '@vue/composition-api';

import ItemImage from '@/components/purchasing/ItemImage';

import numberHelper from '@/composables/common/number';
import requestHelper from '@/composables/purchasing/request';

export default {
    name: 'RequestQuote',
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },

    components: {
        ItemImage
    },

    emits: ['pendingChange'],

    setup(props) {

        const { fmtNumber, onlyNumber } = numberHelper();

        const {
            requestItems: items,
            itemsBreakdown,
            chgPrice,
            chgSource
        } = requestHelper();

        const fPriceDiff = computed(() => {
            const oValue = Number(props.request.total_est_price);
            const dValue = Math.round((oValue - itemsBreakdown.value.total) * 100) / 100;
            const dPct = Math.round(((dValue / oValue) * -100) * 100) / 100;
            const a = {
                isDiff: dValue !== 0,
                dValue: dValue * -1,
                dPct: dPct
            }
            return a;
        });

        const getChanges = () => {
            return {
                diff: fPriceDiff.value,
                items: items.value,
                itemTotals: itemsBreakdown.value.itemTotals,
                total: itemsBreakdown.value.total
            }
        };

        onMounted(() => {
            items.value = props.request.items;
        });

        const sourceList = [{
            name: 'Vendor'
        }, {
            name: 'Stock'
        }];

        return {
            fmtNumber,
            onlyNumber,
            items,
            itemsBreakdown,
            chgPrice,
            chgSource,
            fPriceDiff,
            getChanges,
            sourceList
        }
    }
};
</script>