import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"px-5 pb-5 pt-2",attrs:{"outlined":"","rounded":""}},[_c('div',{staticClass:"mb-2",staticStyle:{"max-height":"800px","overflow-y":"auto","overflow-x":"hidden"}},[_vm._l((_vm.attachments),function(v,k,i){return _c('div',{key:i,class:['mb-2', _vm.config.dragEnter === k && _vm.config.dragLeave !== k ? _vm.dropTargetClass : ''],staticStyle:{"cursor":"grab"},on:{"drop":function($event){$event.preventDefault();return _vm.dropEvent($event, k)},"dragenter":function($event){$event.preventDefault();_vm.config.dragEnter = k},"dragover":function($event){$event.preventDefault();_vm.config.dragEnter = k},"dragleave":function($event){_vm.config.dragEnter = null}}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"text-h6 grey--text text--darken-1 font-weight-light",attrs:{"cols":"auto"},domProps:{"textContent":_vm._s(k)}}),(k === 'Invoice' && _vm.hideInvoices.length)?_c(VCol,{staticClass:"d-flex align-center justify-end"},[_c(VBtn,{attrs:{"color":"primary","text":"","x-small":""},on:{"click":function($event){_vm.config.showHiddenInvoices = !_vm.config.showHiddenInvoices}}},[_vm._v(" "+_vm._s(_vm.config.showHiddenInvoices ? ("Hide " + (_vm.hideInvoices.length) + " Prev. Invoices") : ("Show " + (_vm.hideInvoices.length) + " Prev. Invoices"))+" ")])],1):_vm._e()],1),_c('div',{staticClass:"mb-2"},[_vm._l((v),function(f){return [(_vm.config.showHiddenInvoices || !_vm.hideInvoices.some(function (x) { return x.pk === f.pk; }))?_c(VHover,{key:f.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VRow,{key:("f" + (f.id)),class:['rounded-lg d-flex align-center px-1', {'grey lighten-3': hover}],attrs:{"draggable":"","dense":""},on:{"dragstart":function($event){return _vm.dragStart($event, f.id, k)}}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.attachmentIcon(f.name))}})],1),_c(VCol,[_c('a',{attrs:{"target":"_blank"},domProps:{"textContent":_vm._s(f.name)},on:{"dblclick":function($event){return _vm.getAttachment(f)}}})]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"error","disabled":f.meta && f.meta.no_delete,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.confirmDlg.show({id: f.id})}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)]}}],null,true)}):_vm._e()]})],2)],1)}),_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.config.dragLeave),expression:"config.dragLeave"}]},_vm._l((_vm.otherAttachmentTypes),function(a,i){return _c('div',{key:("oa" + i),class:['mb-2', _vm.config.dragEnter === a.name && _vm.config.dragLeave !== a.name ? _vm.dropTargetClass : ''],on:{"drop":function($event){$event.preventDefault();return _vm.dropEvent($event, a.name)},"dragenter":function($event){$event.preventDefault();_vm.config.dragEnter = a.name},"dragover":function($event){$event.preventDefault();_vm.config.dragEnter = a.name},"dragleave":function($event){_vm.config.dragEnter = null}}},[_c('div',{staticClass:"text-h6 grey--text text--lighten-1 font-weight-light",domProps:{"textContent":_vm._s(a.name)}})])}),0)])],2),_c('FileDrop',{ref:"fileDrop",attrs:{"attachment-types":_vm.attachmentTypes,"loading-upload":_vm.config.loadingUpload},on:{"upload":_vm.upload}}),_c('PDFViewer',{ref:"pdfView",attrs:{"width":"1000px"}}),_c('ConfirmDialog',{ref:"confirmDlg",attrs:{"title":"Are you sure you want to delete this attachment?","confirm-button-text":"Delete","confirm-button-icon":"mdi-trash-can-outline"},on:{"confirm":_vm.removeAttachment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }