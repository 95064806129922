import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.requests.requests || !Object.keys(_vm.requests.requests).length || _vm.config.loading)?_c(VSheet,{staticClass:"pa-5 text-center text-h6 font-weight-light grey--text",attrs:{"outlined":"","rounded":""}},[(_vm.config.loading)?[_c(VProgressCircular,{attrs:{"indeterminate":""}})]:[_vm._v(" No "+_vm._s(_vm.filter)+" purchase requests ")]],2):_vm._e(),_vm._l((_vm.requests.requests),function(v,k,i){return _c('div',{key:i,staticClass:"pa-3"},[_c('div',{staticClass:"text-h5 mb-3 grey--text text--darken-1 font-weight-light",domProps:{"textContent":_vm._s(_vm.divNameOnly(k))}}),_c(VDataTable,{staticClass:"elevation-2 row-pointer",attrs:{"headers":_vm.headers,"items":v,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.loadRow},scopedSlots:_vm._u([{key:"item.summary",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-3",domProps:{"textContent":_vm._s(item.summary)}})]}},{key:"item.total_est_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.fmtNumber(item.total_est_price))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"textContent":_vm._s(_vm.getDateFormat(item.created_at, 'PP'))}})]}}],null,true)})],1)}),_c('ViewPurchaseDialog',{ref:"pDlg"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }