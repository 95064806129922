<template>
    <div style="display: contents">
        <template v-if="item.image">
            <v-img
                :height="height"
                :src="item.image"
                contain
            />
        </template>
        <template v-else>
            <v-card-text
                class="text-center"
                :style="`height: ${height}px`"
            >
                <v-avatar
                    color="orange darken-2"
                    :size="height * .75"
                >
                    <v-icon
                        v-text="category.icon || item.category.icon"
                        color="white"
                        :size="height * .4"
                    />
                </v-avatar>
            </v-card-text>
        </template>
    </div>
</template>
<script>
import useItem from '@/composables/purchasing/useItem';

export default {
    name: 'ItemImage',
    props: {
        height: {
            type: Number,
            default: 130
        },
        item: {
            type: Object, 
            default() {
                return {}
            }
        }
    },
    setup(props, context) {
        const {
            category
        } = useItem(context);

        return {
            category
        }
    },
};
</script>