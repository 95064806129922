<template>
    <v-row
        class="text-h4 text-right"
    >
        <v-col class="grey--text">
            Est. total
        </v-col>
        <v-col cols="auto" v-text="getTotalPrice({currency: true, tbd: true})" />
    </v-row>
</template>
<script>
// import { ref, toRefs } from '@vue/composition-api';
import cartHelper from "@/composables/purchasing/cartHelper";


export default {
    name: 'CartTotal',
    props: {},
    setup(props, context) {
        const {
            getTotalPrice
        } = cartHelper(context);

        return {
            getTotalPrice
        }
    },
};
</script>