<template>
    <div>
        <v-autocomplete
            v-model="model"
            item-text="name"
            :autofocus="autofocus"
            :loading="showLoader && loading"
            :items="items"
            :label="label"
            :rules="rules"
            :multiple="multiple"
            :search-input.sync="search"
            :hide-details="hideDetails"
            :dense="dense"
            :rounded="rounded"
            :outlined="outlined"
            @input="handleInput($event)"
            return-object
            hide-no-data
        />
        <div v-if="showEmployeePopulate">
            <v-alert
                dense
                outlined
                type="warning"
            >
                No employee profile found for <strong v-text="foundPerson.name" />
            </v-alert>
            <v-form
                ref="newEmployeeForm"
                v-model="employeeFormValid"
                :lazy-validation="employeePopulateCreateButton"
            >
                <DivisionSelect
                    v-model="newEmployee.division"
                    :rules="$rules.objWithKey"
                />
                <v-text-field
                    v-model="newEmployee.title"
                    label="Title"
                    :rules="$rules.required"
                />
                <v-select
                    v-model="newEmployee.employeeType"
                    label="Employee Type"
                    item-text="name"
                    :items="employeeTypes"
                    :rules="$rules.required"
                    return-object
                ></v-select>
                <div class="text-center" v-if="employeePopulateCreateButton">
                    <v-btn
                        @click="submitNewEmployee"
                        :disabled="!employeeFormValid"
                        :loading="employeeFormLoading"
                        small
                    >Create Employee Profile</v-btn>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import DivisionSelect from '@/components/population/DivisionSelect';
import { ref, onMounted, watch } from '@vue/composition-api';

export default {
    name: 'SearchPeople',
    components: {
        DivisionSelect,
    },
    props: {
        label: { type: String },
        multiple: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: true },
        chips: { type: Boolean, default: false },
        clearOnInput: { type: Boolean, default: false },
        minChar: { type: Number, default: 2 },
        usersOnly: { type: Boolean, default: false },
        employeesOnly: { type: Boolean, default: false },
        employeePopulate: { type: Boolean, default: false },
        employeePopulateCreateButton: { type: Boolean, default: true },
        ldapPopulate: { type: Boolean, default: false },
        limit: { type: Number, default: 10 },
        hideDetails: { type: Boolean, default: true },
        rounded: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        dense: { type: Boolean, default: false },
        showLoader: { type: Boolean, default: true },
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        rules: {
            type: Array,
            default() {
                return []
            }
        }
    },
    emits: ['input'],
    setup(props, { root, refs, emit }){
        //  Data
        const model = ref(null);
        const loading = ref(false);
        const items = ref([]);
        const search = ref(null);
        const select = ref(null);
        const showEmployeePopulate = ref(false);
        const foundPerson = ref(null);
        const employeeTypes = ref([]);
        const employeeFormValid = ref(true);
        const employeeFormLoading = ref(false);
        const newEmployee = ref({});

        //  Refs
        // const newEmployeeForm = ref(null);

        //  Methods
        const querySelections = async (v) => {
            if (items.value.length && (model.value && model.value.name === v)) return;
            loading.value = true;
            let url = `/population/search_people/?q=${v}`;
            if (props.usersOnly) url += `&users_only=1`;
            if (props.employeesOnly) url += '&employees_only=1';
            if (props.ldapPopulate) url += `&ldap_populate=1`;
            if (props.limit) url += `&limit=${props.limit}`;
            const response = await axios.get(url);
            items.value = response.data.people;
            loading.value = false;
        };
        
        const loadEmployeeTypes = async () => {
            const response = await axios.get(`/population/load_employee_types/`);
            employeeTypes.value = response.data;
        };

        const handleInput = (e) => {
            if (!e) return;
            foundPerson.value = e;
            if (props.employeePopulate) {
                if (!e.employee_id) {
                    emit('input', {}); // send empty object just in case prev result is set
                    loadEmployeeTypes();
                    populateEmployeeFromLdap(e.username);
                    showEmployeePopulate.value = true;
                    return;
                } else {
                    showEmployeePopulate.value = false;
                }
            }

            if (props.clearOnInput) {
                root.$nextTick(() => {
                    model.value = null
                });
            }
            emit('input', e);
        };

        const submitNewEmployee = async () => {
            const isValid = await refs.newEmployeeForm.validate();
            
            if (isValid) {
                employeeFormLoading.value = true;
                
                const payload = {...foundPerson.value, ...newEmployee.value};
                const response = await axios.post(`/population/new_employee_profile/`, payload);

                model.value = response.data;
                search.value = model.value.name;
                newEmployee.value = {};
                showEmployeePopulate.value = false;
                employeeFormLoading.value = false;
            }
        };

        const populateEmployeeFromLdap = async (username) => {
            const response = await axios.get(`/population/search_uni/${username}/`);
            if (!response.data.error) {
                if (response.data.title) {
                    root.$set(newEmployee.value, 'title', response.data.title);
                }
            }
        };

        const emitNewEmployee = () => {
            let newEmp = {...model.value, ...newEmployee.value};
            if (newEmp.user_id) {
                delete newEmp.user_id;
                newEmp.employee_id = null; // set null so downstream can know they need to create it
            }
            emit('input', newEmp);
        };

        const clear = () => {
            model.value = null;
            showEmployeePopulate.value = false;
            foundPerson.value = null;
            newEmployee.value = {};
        };

        //  Watch
        watch(search, (val) => {
            val && val !== select.value && val.length > props.minChar && querySelections(val);
        });
        
        watch(newEmployee, () => {
            if (props.employeePopulate && employeeFormValid.value && !props.employeePopulateCreateButton) {
                emitNewEmployee();
            }
        });

        watch(employeeFormValid, (val) => {
            if (!props.employeePopulateCreateButton && val) {
                emitNewEmployee();
            }
        });

        onMounted(() => {
            if (props.value && props.value.cu_uni) {
                items.value = [props.value];
                model.value = items.value[0];
            }
        });

        return {
            model,
            loading,
            items,
            search,
            select,
            showEmployeePopulate,
            foundPerson,
            employeeTypes,
            employeeFormValid,
            employeeFormLoading,
            newEmployee,
            querySelections,
            loadEmployeeTypes,
            handleInput,
            submitNewEmployee,
            populateEmployeeFromLdap,
            emitNewEmployee,
            clear
        }
    }
};
</script>