import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{staticClass:"stretch-container"},[_c(VRow,[_c(VCol,[_c('div',{staticClass:"text-h4 mb-1",domProps:{"textContent":_vm._s(_vm.requestType.name)}})]),_c(VCol,{staticClass:"d-flex align-end justify-end mr-5",attrs:{"cols":"auto"}},[_c('CartWidget')],1)],1),_c(VRow,[_c(VCol,{staticStyle:{"min-width":"230px"},attrs:{"cols":"2"}},[_c('PurchaseCategories')],1),_c(VCol,[(_vm.category.name)?[_c('div',{staticClass:"text-h5",domProps:{"textContent":_vm._s(_vm.category.name)}}),_c(VDivider,{staticClass:"mt-2 mb-4"}),_c('ItemList',{attrs:{"loading":_vm.loading}})]:[_c(VCol,{staticClass:"mx-auto"},[_c(VSheet,{staticClass:"pa-6 text-h6 font-weight-light d-flex align-center justify-center",attrs:{"height":"450","outlined":"","rounded":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-cursor-default-click-outline")]),_vm._v("Select a category from the list ")],1)],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }