<template>
    <router-link
        :to="{name: 'purchasingCart'}"
    >
        <v-badge
            style="cursor: pointer"
            :content="purchaseRequestItemTotal"
            :value="purchaseRequestItemTotal"
            overlap
        >
            <v-icon
                large
            >mdi-cart</v-icon>
        </v-badge>
    </router-link>
</template>
<script>
import { computed, onMounted } from '@vue/composition-api';

export default {
    name: 'CartWidget',
    setup(_, { root }) {

        const store = root.$store;
        const purchaseRequest = computed(() => store.getters['purchasing/getPurchaseRequest']);
        const purchaseRequestItemTotal = computed(() => store.getters['purchasing/getPurchaseRequestItemTotal']);

        computed(() => store.getters['purchasing/getCategories']);

        onMounted(() => {
            store.dispatch('purchasing/loadCart');
        });

        return {
            purchaseRequest,
            purchaseRequestItemTotal
        }
    },
};
</script>