<template>
    <div style="display: contents">
        <template
            v-for="(c, i) in chartStrings"
        >
            <v-row
                :class="{'mb-5': !dense}"
                :key="i"
                no-gutters
            >
                <v-col>
                    <ChartStringInput
                        ref="cs"
                        :value="c"
                        :percent="Number(c.percent) || 100"
                        :amount="amount"
                        :readonly="readonly"
                        :disabled="disabled"
                        @input="csInput($event, i)"
                    />
                </v-col>
                <v-col
                    v-if="chartStrings.length > 1 && !readonly && !disabled"
                    class="pl-3"
                    cols="auto"
                >
                    <v-btn
                        color="error"
                        icon
                        outlined
                        small
                        @click="csDel(i)"
                    >
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </v-col>
                <slot
                    name="extra"
                    :chartString="c"
                />
            </v-row>
        </template>
        <div
            v-if="showAdd || (config.csAdded && chartStrings.length + 1 <= max && !readonly && !disabled)"
            class="text-center mt-5"
        >
            <v-btn
                color="primary"
                @click="csAdd"
                text
            ><v-icon left>mdi-plus</v-icon>Add Chart String</v-btn>
        </div>
    </div>
</template>
<script>
import { ref, reactive, onMounted } from '@vue/composition-api';

import ChartStringInput from '@/components/purchasing/ChartStringInput';

export default {
    name: 'ChartStringWidget',
    components: {
        ChartStringInput
    },
    props: {
        value: {
            type: Array, 
            default() {
                return []
            }
        },
        max: {
            type: Number,
            default: 4
        },
        amount: {
            type: Number,
            default: undefined
        },
        dense: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showAmount: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input'],
    setup(props, { emit, refs }) {

        const config = reactive({
            csAdded: false
        });

        const chartStrings = ref([]);

        onMounted(async () => {
            chartStrings.value = props.value.length ? props.value : [{}];
        });

        const csAdd = () => {
            chartStrings.value.push({});

            fixPct();

            emit('input', chartStrings.value);
        };

        const csDel = (i) => {
            chartStrings.value.splice(i, 1);

            fixPct();

            emit('input', chartStrings.value);
        };

        const csInput = (e, i=false) => {
            chartStrings.value.splice(i, 1, e.value);

            if (e.value.percent) fixPct(e.value, i);

            emit('input', chartStrings.value);

            config.csAdded = true;
        };

        const fixPct = (e=null, i=null) => {
            const csv = chartStrings.value;
            const csLength = csv.length;

            if (!e && !i) {
                // add or del - distribute everything evenly
                chartStrings.value = csv.map(x => {
                    x.percent = 100 / csLength;
                    return x;
                });
                return;
            }

            // normalize pct value
            const pct = Math.min(Math.max(e.percent, 1), 99);
            chartStrings.value.splice(i, 1, {...e, percent: pct});


            let remain;

            const csLeft = csLength - (i + 1);

            const totalPct = Math.round(chartStrings.value.reduce((total, x) => total += x.percent || 0, 0));

            if (totalPct === 100) return;

            if (csLeft) {

                const prevPct = chartStrings.value.slice(0, i).reduce((total, x) => (total += x.percent || 0), 0) + pct;

                remain = 100 - prevPct;

                chartStrings.value = chartStrings.value.map((x, i2) => {
                    if (i2 > i && remain > 0) {
                        x.percent = remain / csLeft;
                    } else if (remain <= 0 && i2 !== i) {
                        x.percent = (100 - pct) / (csLength - 1);
                    }
                    return x;
                });
            } else {
                remain = 100 - pct;

                chartStrings.value = chartStrings.value.map((x, i2) => {
                    if (i2 !== i) x.percent = remain / (csLength - 1);
                    return x;
                });

            }
        };

        const validate = () => {
            const csInputValid = refs.cs.every(x => x.validate());
            const csValid = chartStrings.value.every(x => x.dept && x.project && x.activity && x.initiative && x.segment);

            return [csInputValid, csValid].every(x => x);
        };

        return {
            chartStrings,
            csAdd,
            csInput,
            csDel,
            config,
            validate
        }
    },
};
</script>