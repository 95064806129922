<template>
    <v-card>
        <v-card-title>
            Edit Purchase Request
            <v-spacer />
            <v-icon
                @click="$emit('close')"
            >mdi-close</v-icon>
        </v-card-title>
        
        <v-fade-transition mode="out-in">
            <v-card-subtitle
                v-if="pr.tickets"
                :key="pr.tickets[selected].pk"
                v-text="pr.tickets[selected].data.summary"
            />
        </v-fade-transition>
        
        <v-card-text v-if="config.loading" class="text-center">
            <v-progress-circular
                color="primary"
                indeterminate
            />
        </v-card-text>
        <v-card-text v-else>
            <v-sheet
                class="pa-2"
                outlined
                rounded
                v-if="pr.tickets"
            >
                <v-row
                    v-for="(item, i) in pr.tickets[selected].items"
                    class="body-1"
                    align="center"
                    :key="i"
                    dense
                >
                    <v-col cols="1">
                        <ItemImage
                            :height="60"
                            :item="item.item"
                        />
                    </v-col>
                    
                    <v-col class="pl-4">
                        {{ item.item.name }}
                    </v-col>
                    <v-col
                        cols="auto"
                        class="font-weight-bold"
                        v-text="fmtNumber(item.est_price, {currency: true})"
                    />
                    <v-col cols="auto">
                        <EditItem
                            :item="item"
                            :block="false"
                            @editItem="editItem"
                            icon-only
                            :ticketStatus="pr.tickets[selected].data.status"
                        />
                        <v-btn
                            v-if="allowRemove(item)"
                            color="red darken-1"
                            @click.stop="openRemoveItemDlg(item.pk)"
                            icon
                        >
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="11" offset="1" class="mt-n5">
                        <ItemDetails
                            :item="item"
                            :expand="false"
                            :expand-outline="false"
                            dense
                        />
                    </v-col>
                </v-row>
            </v-sheet>
            <v-fade-transition mode="out-in">
                <div
                    class="text-right body-1 pa-2"
                    :key="prTotal"
                >
                    Total <strong v-text="fmtNumber(prTotal, {currency: true})" />
                </div>
            </v-fade-transition>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <v-btn
                color="blue darken-1"
                @click="$emit('close')"
                text
            >
                Cancel
            </v-btn>
            <v-btn
                v-if="config.isDirty"
                color="blue darken-1"
                :loading="config.submitLoading"
                :disabled="config.submitLoading"
                @click="submit"
                text
            >
                Update
            </v-btn>
        </v-card-actions>
        <v-dialog
            v-model="config.removeItemDlg"
            width="unset"
        >
            <v-card>
                <v-card-title>
                    Are you sure you want to remove this item?
                </v-card-title>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        @click="config.removeItemDlg = false"
                        text
                    >Cancel</v-btn>
                    <v-btn
                        color="error"
                        @click="removeItem"
                        text
                    >
                        <v-icon>mdi-trash-can-outline</v-icon>
                        Remove
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import axios from 'axios';


import { ref, reactive, onMounted, computed } from '@vue/composition-api';

import ItemImage from '@/components/purchasing/ItemImage';
import ItemDetails from '@/components/purchasing/ItemDetails';
import EditItem from '@/components/purchasing/EditItem';

import useItem from '@/composables/purchasing/useItem';
import numberHelper from '@/composables/common/number';


export default {
    name: 'RequestEdit',
    components: {
        ItemImage,
        ItemDetails,
        EditItem
    },
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        },
        filter: {
            type: String
        },
        selected: {
            type: Number
        },
    },
    emits: ['close', 'reset'],
    setup(props, context) {

        const store = context.root.$store;

        const config = reactive({
            loading: false,
            submitLoading: false,
            removeItemDlg: false,
            removeItemKey: null,
            isDirty: false,
            // summaryTmp: null
        });

        // const summary = computed(() => config.summaryTmp || props.request.data.summary);

        const { fmtNumber } = numberHelper();

        const {
            configuredItem,
            resetItem
        } = useItem(context);


        const pr = ref({});

        const load = async () => {
            config.loading = true;
            const response = await axios.get(`/purchasing/load_request/${props.request.pr_pk}/`);

            if (!response.data.pk) return;

            pr.value = {
                ...response.data,
                changelog: {
                    change: [],
                    remove: [],
                }
            }

            config.loading = false;
        };


        const allowRemove = (item) => {
            if ((pr.value.tickets[props.selected].items.length || 0) > 1) {
                if (item.addon) {
                    return true;
                }
            }

            return false;
        }

        // const updateSummary = async () => {
        //     const response = await axios.post('/purchasing/get_summary/', {
        //         request: pr.value
        //     });

        //     config.summaryTmp = response.data.summary;

        // };

        const changelogPush = (cl, key) => {
            pr.value.changelog[cl].indexOf(key === -1) && pr.value.changelog[cl].push(key);
        };


        const editItem = () => {
            const idx = pr.value.tickets[props.selected].items.findIndex(x => x.pk === configuredItem.value.pk);

            configuredItem.value.est_price = configuredItem.value.item.totalPrice || 0;

            pr.value.tickets[props.selected].items.splice(idx, 1, configuredItem.value);

            changelogPush('change', configuredItem.value.pk);

            config.isDirty = true;

            // updateSummary();

            resetItem();
        };


        const removeItem = () => {
            const idx = pr.value.tickets[props.selected].items.findIndex(x => x.pk === config.removeItemKey);

            pr.value.tickets[props.selected].items.splice(idx, 1);
            
            changelogPush('remove', config.removeItemKey);

            config.removeItemDlg = false;
            config.removeItemKey = null;

            config.isDirty = true;

            // updateSummary();
        };


        const openRemoveItemDlg = (itemKey) => {
            config.removeItemKey = itemKey;
            config.removeItemDlg = true;
        };

        const submit = async () => {
            config.submitLoading = true;
            const response = await axios.post(`/purchasing/edit/${props.request.pr_pk}/`, {
                request: pr.value
            });

            if (response.data.success) {
                await store.dispatch('purchasing/loadRequests', {filter: props.filter});
                store.commit('setSuccessSnack', 'Purchase request has been updated');
                context.emit('reset');
            }
            config.submitLoading = false;
        };

        const prTotal = computed(() => {
            if (!pr.value.tickets) return 0.0;

            return pr.value.tickets[props.selected].items && pr.value.tickets[props.selected].items.reduce((sum, item) => (sum += Number(item.est_price)), 0.0)
        });

        onMounted(() => load());

        return {
            config,
            fmtNumber,
            pr,
            prTotal,
            editItem,
            allowRemove,
            removeItem,
            openRemoveItemDlg,
            submit,
            // summary
        }
    }
};
</script>