<template>
    <div>
        <div class="mb-5">
            <v-row>
                <v-col cols="12" class="text-h5 orange--text text--darken-2 mb-n4">
                    Hardware
                </v-col>
                <template v-if="configuredItem.option">
                    <v-col cols="12">
                        <div class="text-h6 font-weight-light" v-text="configuredItem.option.name" />
                        <div class="grey--text mt-n1">
                            ${{ fmtNumber(configuredItem.option.est_price) }}
                        </div>
                    </v-col>
                    <v-col
                        v-for="(v, k, i) in config.all"
                        cols="6"
                        :key="`config${i}`"
                    >
                        <div class="overline" v-text="k" />
                        <div class="text-h6 font-weight-light d-flex align-center">
                            <template v-if="k === 'Color' && configuredItem.option && configuredItem.option.meta && configuredItem.option.meta.colors[v]">
                                <v-avatar
                                    class="mr-2"
                                    size="30"
                                    :color="configuredItem.option.meta.colors[v].color"
                                />
                                {{ v }}
                            </template>
                            <template v-else>
                                {{ v.name }}
                            </template>
                        </div>
                        <div
                            v-if="v.price"
                            class="grey--text mt-n1"
                        >+ ${{ fmtNumber(v.price) }}</div>
                    </v-col>
                </template>
                <template v-else>
                    <v-col cols="12" v-if="configuredItem.item">
                        <div class="text-h6 font-weight-light" v-text="configuredItem.item.name" />
                        <div class="grey--text mt-n1">
                            <template v-if="!configuredItem.item.meta.tbd_price">
                                ${{ fmtNumber(configuredItem.item.est_price) }}
                            </template>
                            <template v-else>
                                $ TBD
                            </template>
                        </div>
                    </v-col>
                </template>
                <template v-if="configuredItem.accessories && Object.keys(configuredItem.accessories).length">
                    <v-col cols="12" class="text-h5 orange--text text--darken-2">
                        Accessories
                    </v-col>
                    <v-col cols="12 mt-n3">
                        <v-row
                            v-for="(ac, k, i) in configuredItem.accessories"
                            :key="`acc${i}`"
                            align="center"
                        >
                            <v-col cols="2" v-if="ac.item.image" class="justify-center d-flex">
                                <v-img
                                    height="30"
                                    style="max-width: 50px"
                                    :src="ac.item.image"
                                    contain
                                />
                            </v-col>
                            <v-col cols="10">
                                <div class="text-h6 font-weight-light">
                                    <span v-if="ac.qty > 1">{{ ac.qty }} x </span>{{ ac.item.name }}
                                </div>
                                <div class="mt-n1" v-if="configuredItem.accessories[ac.item.slug].color">
                                    <v-avatar
                                        size="12"
                                        :color="configuredItem.accessories[ac.item.slug].color[1].color"
                                        tile
                                    />
                                    {{ configuredItem.accessories[ac.item.slug].color[0] }}
                                </div>
                                <div class="mt-n1" v-if="ac.item.meta.applecare && ac.item.meta.applecare.required">
                                    <v-chip class="" small label outlined>3 Year AppleCare+ Included</v-chip>
                                </div>
                                <!-- <div class="grey--text mt-n1">+ ${{ fmtNumber(ac.qty * ac.item.est_price) }}</div> -->
                                <div class="grey--text mt-1">+ ${{ fmtNumber(getAccessoryPrice(ac)) }}</div>
                                <div v-if="ac.config">
                                    <ul>
                                        <li class="caption text--secondary mt-1" v-for="(opt,acKey) in ac.config" :key="acKey">
                                            {{ acKey }}: {{ opt.name }} <span v-if="opt.price">(+${{ fmtNumber(opt.price) }})</span>
                                        </li>
                                    </ul>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </template>
                <template v-if="configuredItem.config && config.additionalSoftware && config.additionalSoftware.length">
                    <v-col cols="12" class="text-h5 orange--text text--darken-2">Software</v-col>
                    <v-col cols="12">
                        <v-row
                            v-for="(sw, i) in config.additionalSoftware"
                            align="center"
                            :key="`sw${i}`"
                        >
                            <v-col cols="2" v-if="sw.image" class="justify-center d-flex">
                                <v-img
                                    height="30"
                                    style="max-width: 50px"
                                    :src="sw.image"
                                    contain
                                />
                            </v-col>
                            <v-col>
                                <div class="text-h6 font-weight-light" v-text="sw.name" />
                                <div class="grey--text mt-n1">+ ${{ fmtNumber(sw.est_price) }}</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </template>
                <template v-if="configuredItem.config && config.softwareNotes">
                    <v-col cols="12">
                        <div class="overline">Software Notes</div>
                        <div v-text="config.softwareNotes" />
                    </v-col>
                </template>
                <template v-if="configuredItem.config && config.accounts && config.accounts.length">
                    <v-col cols="12" class="text-h5 orange--text text--darken-2">
                        Accounts
                    </v-col>
                    <v-col cols="12 mt-n3">
                        <v-row
                            v-for="(account, k, i) in config.accounts"
                            :key="`account${i}`"
                            align="center"
                        >
                            <v-col cols="2" v-if="account.image" class="justify-center d-flex">
                                <v-img
                                    height="30"
                                    style="max-width: 50px"
                                    :src="account.image"
                                    contain
                                />
                            </v-col>
                            <v-col cols="10">
                                <div class="text-h6 font-weight-light">{{ account.name }}</div>
                                <div class="grey--text mt-n1">+ ${{ fmtNumber(account.est_price) }}</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </template>
                <template v-if="configuredItem.config && config.accountNotes">
                    <v-col cols="12">
                        <div class="overline">Account Notes</div>
                        <div v-text="config.accountNotes" />
                    </v-col>
                </template>
            </v-row>
        </div>

        <template v-if="configuredItem.notes">
            <div class="text-h5 orange--text text--darken-2">Notes</div>
            <v-row class="mb-5">
                <v-col cols="12"
                >
                    <div
                        style="white-space: break-spaces; max-height: 200px; overflow-y: auto"
                        v-text="configuredItem.notes"
                    />
                </v-col>
            </v-row>
        </template>

        <div class="text-h5 orange--text text--darken-2">Install</div>
        
        <v-row v-if="configuredItem.assignment">
            <v-col v-if="configuredItem.assignment.division">
                <div class="overline">Division</div>
                <div class="text-h6 font-weight-light" v-text="configuredItem.assignment.division.long_name" />
            </v-col>

            <v-col v-if="assignTo">
                <div class="overline">Assign to</div>
                <div class="text-h6 font-weight-light" v-text="assignTo" />
            </v-col>

            <v-col cols="12">
                <div class="overline">Location</div>
                <div class="text-h6 font-weight-light" v-text="locationString" />
            </v-col>
        </v-row>
        
        <v-row v-if="configuredItem.install">
            <v-col cols="12">
                <template v-if="configuredItem.install.endpoint">
                    <div class="overline">Install on device</div>
                    <div 
                        class="text-h6 font-weight-light"
                        v-text="configuredItem.install.endpoint.full_name"
                    />
                </template>
            </v-col>
        </v-row>


        <v-row v-if="configuredItem.replacement">
            <v-col cols="12">
                <template v-if="replaceEndpoints.length">
                    <div class="overline">Replace existing device(s)</div>
                    <div 
                        v-for="(ep, i) in replaceEndpoints"
                        :key="i"
                        class="text-h6 font-weight-light"
                        v-text="ep.full_name"
                    />
                </template>
            </v-col>
        </v-row>
        
    </div>
</template>
<script>

import { computed } from '@vue/composition-api';
import useItem from '@/composables/purchasing/useItem';
import numberHelper from '@/composables/common/number';

export default {
    name: 'ReviewStep',
    components: {},
    props: {},
    setup(props, context) {

        const store = context.root.$store;

        const { fmtNumber } = numberHelper();

        const { configuredItem, getAccessoryPrice } = useItem(context);

        const config = computed(() => {

            if (!configuredItem.value.config) return {};

            const { 
                Software:additionalSoftware, 
                'Software Notes':softwareNotes, 
                Accounts:accounts,
                'Account Notes': accountNotes,
                ...all 
            } = configuredItem.value.config;

            return {
                additionalSoftware: additionalSoftware,
                softwareNotes: softwareNotes,
                accounts: accounts,
                accountNotes: accountNotes,
                all: all
            }
        });

        const user = computed(() => store.getters['userFullName']);

        const assignTo = computed(() => {
            const a = configuredItem.value.assignment;
            if (!a || !a.usedBy) return '';
            if (a.usedBy.includes('Many')) {
                return a.usedBy;
            } else if (a.usedBy.includes('Myself')) {
                return user.value;
            } else if (a.assignTo) {
                return a.assignTo.name;
            }
            return '';
        });

        const replaceEndpoints = computed(() => {
            const r = configuredItem.value.replacement || {};
            if (r.endpoints && r.endpoints.length) return r.endpoints;
            if (r.endpoint) return [r.endpoint];
            return [];
        });

        const locationString = computed(() => {
            const l = configuredItem.value.assignment.location;
            if (!l.building) return '';
            const building = l.building.short_name || l.building.name;

            if (building !== 'Remote / Other') {
                return `${building}-${l.floor}-${l.room}`;
            } else {
                let address = l.address;
                if (l.address2) address += ` ${l.address2}`;
                return `${building}: ${address}, ${l.city}, ${l.state} ${l.zip_code}`;
            }
        });

        
        return {
            configuredItem,
            fmtNumber,
            config,
            assignTo,
            locationString,
            replaceEndpoints,
            getAccessoryPrice
        }
    },
};
</script>