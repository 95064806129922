export default function numberHelper() {

    const fmtNumber = (num, opt={}) => {
        num = Number(num)
            .toLocaleString('en-US', opt.showDec ? {maximumFractionDigits: 2, minimumFractionDigits: 2} : {})
            .replace('.00', '');

        if (opt.currency) num = `$${num}`;
        
        return num;
    };

    const pctNumber = (num, pct, maxDec=2) => {

        num = (num / 100) * pct;
        num = num.toFixed(maxDec)

        if (maxDec > 2) {
            num = num.endsWith('0') ? parseFloat(num).toFixed(maxDec - 1) : num;
        }

        return num;
    };

    const onlyNumber = (e, opt={}) => {
        let keyCode = (e.keyCode ? e.keyCode : e.which);

        if (keyCode < 48 || keyCode > 57) {
            if (opt.decimal && keyCode === 46) return;
            e.preventDefault();
        } else {
            const newVal = e.target.value + e.key;
            if (opt.max && newVal > opt.max) {
                e.preventDefault();
            }
        }
    };

    return {
        fmtNumber,
        pctNumber,
        onlyNumber
    }
}