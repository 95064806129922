<template>
    <v-row
        :class="config.rowTopMargin"
        no-gutters
    >
        <v-col
            md="2"
            lg="2"
            sm="12"
        >
            <ItemImage
                :height="config.imgHeight"
                :item="item.item"
            />
        </v-col>
        <v-col
            md="10"
            lg="10"
            sm="12"
            
        >
            <v-row :class="{'mb-1': !dense}">
                <v-col>
                    <div
                        :class="config.heading"
                        v-text="item.item.name"
                    />
                    <div
                        v-if="item.option && item.option.name"
                        class="caption grey--text text--darken-2"
                        v-text="item.option.name"
                    />
                </v-col>
                <v-col
                    :class="[config.heading, 'text-right']"
                >
                    ${{ !item.item.meta.tbd_price ? fmtNumber(item.item.totalPrice) : 'TBD' }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col
                    md="9"
                    lg="9"
                    sm="12"
                >
                    <CartAssignmentDetails
                        :assignment="item.assignment"
                        :dense="dense"
                        @openLoc="$emit('openLoc', $event)"
                    />
                </v-col>
                <v-spacer />
                <!--
                <v-col sm="12" md="1">
                    <v-select
                        v-model="qty"
                        class="ma-0"
                        hint="Quantity"
                        :items="itemQty"
                        :menu-props="{ maxHeight: '150' }"
                        persistent-hint
                        dense
                    />
                </v-col>
                -->
                <v-col v-if="editable" sm="12" md="1">
                    <EditItem
                        :item="item"
                        @editItem="$emit('editItem')"
                    />
                    <RemoveCartItem
                        :item="item"
                        @removeItem="$emit('removeItem', item.id)"
                    />
                </v-col>
            </v-row>
            <ItemDetails
                :dense="dense"
                :expand="!dense"
                :item="item"
            />
            <!--<pre v-text="item.config" />-->
        </v-col>
    </v-row>
</template>
<script>
import { ref, computed } from '@vue/composition-api';

import ItemImage from '@/components/purchasing/ItemImage';
import ItemDetails from '@/components/purchasing/ItemDetails';
import CartAssignmentDetails from '@/components/purchasing/cart/CartAssignmentDetails';
import EditItem from '@/components/purchasing/EditItem';
import RemoveCartItem from '@/components/purchasing/cart/RemoveCartItem';

import numberHelper from '@/composables/common/number';

export default {
    components: {
        ItemImage,
        ItemDetails,
        CartAssignmentDetails,
        EditItem,
        RemoveCartItem
    },
    props: {
        item: {
            type: Object,
        },
        dense: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    emits: ['removeItem', 'editItem', 'openLoc'],
    setup(props) {
        // const store = root.$store;

        const itemQty = ref(['1', '2', '3', '4', '5', '6']);
        const qty = ref('1');

        const config = computed(() => {
            let conf = {
                imgHeight: 130,
                heading: 'text-h5',
                rowTopMargin: 'mt-10'
            };

            if (props.dense) {
                conf = {
                    ...conf,
                    imgHeight: 70,
                    heading: 'body-1 font-weight-bold',
                    rowTopMargin: 'mt-1'
                }
            }
            return conf;
        });

        const { fmtNumber } = numberHelper();

        return {
            itemQty,
            qty,
            fmtNumber,
            config
        };
    },
};
</script>
