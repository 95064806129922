<template>
    <v-dialog
        v-model="config.showDialog"
        max-width="1000"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="grey darken-2"
                @click="selectItem"
                :block="block"
                :text="!iconOnly"
                :icon="iconOnly"
                :small="!iconOnly"
            >
                <v-icon
                    :left="!iconOnly"
                    :small="iconOnly"
                >mdi-pencil</v-icon>
                <template v-if="!iconOnly">Edit</template>
            </v-btn>
        </template>
        <ItemStepper
            v-if="config.showDialog"
            cancel-text="Close"
            finish-text="Update"
            :item="item"
            :loading="config.loading"
            @cancel="cancel"
            @finish="editItem"
            edit
            :ticketStatus="ticketStatus"
        />
    </v-dialog>
</template>
<script>
import { reactive } from '@vue/composition-api';

import useItem from '@/composables/purchasing/useItem';

import ItemStepper from '@/components/purchasing/ItemStepper';

export default {
    name: 'EditItem',
    components: {
        ItemStepper
    },
    props: {
        item: {
            type: Object, 
            default() {
                return {}
            }
        },
        block: {
            type: Boolean,
            default: true
        },
        iconOnly: {
            type: Boolean,
            default: false
        },
        ticketStatus: {
            type: String,
            default: ''
        }
    },

    emits: ['editItem'],

    setup(props, context) {

        const config = reactive({
            showDialog: false,
            loading: false
        });

        const {
            itemFull,
            configuredItem,
            configuredItemPrice,
            selectOption,
            loadConfiguredItem,
            setConfiguredItem,
            resetItem
        } = useItem(context);

        const selectItem = async () => {
            config.loading = true;

            await loadConfiguredItem(props.item);

            config.loading = false;
        };

        const cancel = () => {
            config.showDialog = false;
            resetItem();
        };

        const editItem = () => {
            setConfiguredItem({item: {...configuredItem.value.item, totalPrice: configuredItemPrice.value}});
            context.emit('editItem');
            config.showDialog = false;
        };

        return {
            config,
            itemFull,
            selectItem,
            selectOption,
            configuredItem,
            configuredItemPrice,
            cancel,
            editItem
        }
    },
};
</script>