<template>
    <v-sheet
        class="px-5 pb-5 pt-2"
        outlined
        rounded
    >
        <div class="mb-2" style="max-height: 800px; overflow-y: auto; overflow-x: hidden">
            <div
                v-for="(v, k, i) in attachments"
                :class="['mb-2', config.dragEnter === k && config.dragLeave !== k ? dropTargetClass : '']"
                :key="i"
                style="cursor: grab"
                @drop.prevent="dropEvent($event, k)"
                @dragenter.prevent="config.dragEnter = k"
                @dragover.prevent="config.dragEnter = k"
                @dragleave="config.dragEnter = null"
            >
                <v-row dense>
                    <v-col
                        cols="auto"
                        class="text-h6 grey--text text--darken-1 font-weight-light"
                        v-text="k"
                    />
                    <v-col
                        v-if="k === 'Invoice' && hideInvoices.length"
                        class="d-flex align-center justify-end"
                    >
                        <v-btn
                            color="primary"
                            @click="config.showHiddenInvoices = !config.showHiddenInvoices"
                            text
                            x-small
                        >
                            {{ config.showHiddenInvoices ? `Hide ${hideInvoices.length} Prev. Invoices` : `Show ${hideInvoices.length} Prev. Invoices` }}
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mb-2">
                    <template v-for="f in v">
                        <v-hover
                            v-if="config.showHiddenInvoices || !hideInvoices.some(x => x.pk === f.pk)"
                            v-slot:default="{ hover }"
                            :key="f.id"
                        >
                            <v-row
                                :class="['rounded-lg d-flex align-center px-1', {'grey lighten-3': hover}]"
                                :key="`f${f.id}`"
                                draggable
                                @dragstart="dragStart($event, f.id, k)"
                                dense
                            >
                                <v-col cols="auto">
                                    <v-icon
                                        v-text="attachmentIcon(f.name)"
                                    />
                                </v-col>
                                <v-col>
                                    <!-- <a
                                        v-if="f.name.toLowerCase().endsWith('.pdf')"
                                        v-text="f.name"
                                        @click="$refs.pdfView.loadPDF(f.attachment)"
                                    /> -->
                                    <!-- <a
                                        v-else
                                        :href="f.attachment"
                                        v-text="f.name"
                                        target="_blank"
                                    /> -->
                                    <a
                                        @dblclick="getAttachment(f)"  
                                        v-text="f.name"
                                        target="_blank"
                                    />
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn
                                        color="error"
                                        :disabled="f.meta && f.meta.no_delete"
                                        @click.stop="$refs.confirmDlg.show({id: f.id})"
                                        icon
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-hover>
                    </template>
                </div>
            </div>
            <v-expand-transition>
                <div v-show="config.dragLeave">
                    <div
                        v-for="(a, i) in otherAttachmentTypes"
                        :class="['mb-2', config.dragEnter === a.name && config.dragLeave !== a.name ? dropTargetClass : '']"
                        :key="`oa${i}`"
                        @drop.prevent="dropEvent($event, a.name)"
                        @dragenter.prevent="config.dragEnter = a.name"
                        @dragover.prevent="config.dragEnter = a.name"
                        @dragleave="config.dragEnter = null"
                    >
                        <div
                            class="text-h6 grey--text text--lighten-1 font-weight-light"
                            v-text="a.name"
                        />
                        
                    </div>
                </div>
            </v-expand-transition>
        </div>
        <FileDrop
            ref="fileDrop"
            :attachment-types="attachmentTypes"
            :loading-upload="config.loadingUpload"
            @upload="upload"
        />
        <PDFViewer
            ref="pdfView"
            width="1000px"
        />
        <ConfirmDialog
            ref="confirmDlg"
            title="Are you sure you want to delete this attachment?"
            confirm-button-text="Delete"
            confirm-button-icon="mdi-trash-can-outline"
            @confirm="removeAttachment"
        />
    </v-sheet>
</template>
<script>
import axios from 'axios';
import { reactive, ref, onMounted, computed } from '@vue/composition-api';

import dateTimeHelper from '@/composables/common/dateTime';
import attachmentHelper from '@/composables/common/attachment';
import stringHelper from '@/composables/common/string';

import PDFViewer from '@/components/common/PDFViewer';
import FileDrop from '@/components/common/FileDrop';
import ConfirmDialog from '@/components/common/ConfirmDialog';

export default {
    name: 'RequestAttachments',
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        },
    },
    components: {
        PDFViewer,
        FileDrop,
        ConfirmDialog
    },
    setup(props, { refs }) {

        const { getDateFormat, getDateDistanceIso } = dateTimeHelper();

        const { attachmentIcon } = attachmentHelper();

        const { pluralize } = stringHelper();

        const config = reactive({
            loadingUpload: false,
            dragEnter: null,
            dragLeave: null,
            showHiddenInvoices: false,
        });

        const dropTargetClass = 'dragover-active rounded-lg pa-1';

        const attachments = ref([]);

        const attachmentTypes = ref({});

        const upload = async (formData) => {
            config.loadingUpload = true;

            const response = await axios.post(`/purchasing/upload_attachments/${props.request.pr_pk}/${props.request.jira_key}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                load();
                refs.fileDrop.reset();
            }

            config.loadingUpload = false;
        };

        const load = async () => {

            // const response = await axios.get(`/purchasing/load_attachments/143/`);
            const response = await axios.get(`/jira/rest_load_attachments/${props.request.jira_key}/`)
            attachments.value = response.data.attachments;
            attachmentTypes.value = response.data.attachmentTypes;
        };

        const dragStart = (e, attId, k) => {
            config.dragLeave = k;
            e.dataTransfer.setData('text/plain', attId);
        };

        const dropEvent = async (e, k) => {
            config.dragLeave = config.dragEnter = null;
        
            const response = await axios.post(`/jira/move_attachment/`, {
                issueKey: props.request.jira_key,
                id: e.dataTransfer.getData('text/plain'),
                to: k
            });

            if (response.data.success) load();
        };

        const removeAttachment = async (att) => {
            const attId = att.id;
            const response = await axios.post(`/jira/delete_attachment/${props.request.jira_key}/${attId}/`, {
                id: attId,
                extra: false
            });

            if (response.data.success) load();
        };

        const getAttachment = async (att) => {
            const attachmentId = att.id;
            const response = await axios.get(`/jira/get_attachment/${attachmentId}/`, {responseType: 'blob'});
            if (!window.navigator.msSaveOrOpenBlob){
                const contentDisposition = response.headers['content-disposition'];
                
                const url = URL.createObjectURL(new Blob([response.data],{type:response.headers['content-type']}));
                const link = document.createElement('a');
                const fileName = contentDisposition.match(/filename=(?<filename>[^,;]+)/)[1];
                
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert('Fail: what version of IE are you using?');
            }
        }

        // const otherAttachmentTypes = computed(() => {
        //     return [...Object.entries({...attachmentTypes.value, uncat: {name: 'Uncategorized'}}).map(([k, v]) => {
        //         return {
        //             slug: k,
        //             name: v.name
        //         }
        //     }).filter(x => !Object.keys(attachments.value).includes(x.name)),
                
        //     ]
        // });

        const otherAttachmentTypes = computed(() => {
            return [...Object.entries({...attachmentTypes.value}).map(([k, v]) => {
                return {
                    slug: k,
                    name: v.name
                }
            }).filter(x => !Object.keys(attachments.value).includes(x.name)),
                
            ]
        });

        const hideInvoices = computed(() => {
            //const maxRev = Math.max(attachments.value['Invoice'].map(x => x.invoice_rev), 0);
            // return attachments.value['Invoice'].map(x => x.meta?.invoice_rev || 0);
            if (attachments.value['Invoice']) {
                const maxRev = Math.max(...attachments.value['Invoice'].map(x => x.meta?.invoice_rev || 0), 0);

                return attachments.value['Invoice'].filter(x => x.meta.invoice_rev < maxRev);
            }
            return [];
        });

        onMounted(() => load());

        return {
            config,
            attachments,
            getDateFormat,
            getDateDistanceIso,
            attachmentIcon,
            pluralize,
            attachmentTypes,
            otherAttachmentTypes,
            dropTargetClass,
            hideInvoices,
            getAttachment,
            load,
            upload,
            dropEvent,
            dragStart,
            removeAttachment
        }
    }
};
</script>