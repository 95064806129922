<template>
    <div>
        <v-sheet
            v-if="!requests.requests || !Object.keys(requests.requests).length || config.loading"
            class="pa-5 text-center text-h6 font-weight-light grey--text"
            outlined
            rounded
        >
            <template v-if="config.loading">
                <v-progress-circular
                    indeterminate
                />
            </template>
            <template v-else>
                No {{ filter }} purchase requests
            </template>
        </v-sheet>
        <div
            v-for="(v, k, i) in requests.requests"
            class="pa-3"
            :key="i"
        >
            <div
                class="text-h5 mb-3 grey--text text--darken-1 font-weight-light"
                v-text="divNameOnly(k)"
            />
            <v-data-table
                class="elevation-2 row-pointer"
                :headers="headers"
                :items="v"
                @click:row="loadRow"
                disable-pagination
                hide-default-footer
            >
                <template v-slot:item.summary="{ item }">
                    <div class="body-3" v-text="item.summary" />
                </template>

                <template v-slot:item.total_est_price="{ item }">
                    ${{ fmtNumber(item.total_est_price) }}
                </template>

                <template v-slot:item.created_at="{ item }">
                    <div v-text="getDateFormat(item.created_at, 'PP')" />
                    <!--
                        <div class="caption grey--text">{{ getDateDistanceIso(item.created_at) }} ago</div>
                    -->
                </template>
                <!-- <template v-slot:item.status="{ item }">
                    <v-fade-transition mode="out-in">
                        <v-chip
                            :key="item.status"
                            :color="statusColor(item.status)"
                            v-text="item.status"
                            outlined
                            small
                        />
                    </v-fade-transition>
                </template> -->
                <!-- <template v-slot:item.action="{ item }">
                    <RequestActions
                        :item="item"
                        :filter="filter"
                    />
                </template> -->
            </v-data-table>
        </div>
        <ViewPurchaseDialog
            ref="pDlg"
        />
    </div>
</template>
<script>
import { computed, reactive, onMounted } from '@vue/composition-api';

import stringHelper from '@/composables/common/string';
import numberHelper from '@/composables/common/number';
import dateTimeHelper from '@/composables/common/dateTime';
import statusHelper from '@/composables/purchasing/status';

import ViewPurchaseDialog from '@/components/purchasing/ViewPurchaseDialog';
// import RequestActions from '@/components/purchasing/RequestActions';


export default {
    name: 'RequestList',
    components: {
        ViewPurchaseDialog,
    },
    props: {
        filter: {
            type: String
        },
        load: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { root, refs }) {

        const store = root.$store;

        const requests = computed(() => store.getters['purchasing/getPurchaseRequests']);

        const { divNameOnly } = stringHelper();
        const { getDateFormat, getDateDistanceIso } = dateTimeHelper();
        const { fmtNumber } = numberHelper();
        const { statusColor } = statusHelper();

        const config = reactive({
            loading: false
        });

        const headers = [
            {
                text: 'Summary',
                value: 'summary'
            },
            {
                text: 'Created',
                value: 'created_at',
                width: 120
            },
            {
                text: 'Amount $',
                value: 'total_est_price',
                width: 120
            },
            // {
            //     text: 'Status',
            //     value: 'status',
            //     width: 1
            // },
            // {
            //     text: '',
            //     value: 'action',
            //     sortable: false,
            //     width: 1
            // }
        ];

        const loadRow = (e) => {
            refs.pDlg.load(e);
        };

        const loadRequests = async () => {
            config.loading = true;
            await store.dispatch('purchasing/loadRequests', {filter: props.filter});
            config.loading = false;
        };

        onMounted(() => {
            if (props.load) loadRequests();
        });

        return {
            fmtNumber,
            divNameOnly,
            getDateFormat,
            getDateDistanceIso,
            requests,
            headers,
            loadRow,
            config,
            statusColor,
            loadRequests
        }
    },
};
</script>
<style scoped>
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>