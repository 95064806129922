<template>
    <div style="display: contents">
        <v-sheet
            class="pa-2"
            :outlined="outlined && !elevation"
            :elevation="!elevation ? undefined : 1"
            rounded
        >
            <v-row
                v-for="item in request.items"
                class="body-1"
                align="center"
                :key="item.pk"
                dense
            >
                <v-col cols="1">
                    <ItemImage
                        :height="60"
                        :item="item.item"
                    />
                </v-col>
                
                <v-col class="pl-4 text-h6">
                    {{ item.item.name }}
                </v-col>
                <v-col
                    cols="auto"
                    class="text-h6"
                >
                    {{ fmtNumber(item.price, {currency: true}) }}
                </v-col>
                <!--
                    <div
                        class="body-2 mt-n2 text--secondary"
                        v-text="fmtNumber(item.item.est_price, {currency: true})"
                    />
                -->
                <v-col cols="11" offset="1" class="pl-4 mt-n5">
                    <ItemDetails
                        :item="item"
                        :expand="false"
                        :expand-outline="false"
                        dense
                    />
                </v-col>
            </v-row>
        </v-sheet>
        <slot
            name="total"
            :total="request.total_est_price"
        >
            <div class="text-right body-1 pa-2">
                Total <strong v-text="fmtNumber(request.total_est_price, {currency: true})" />
            </div>
        </slot>
    </div>
</template>
<script>

import ItemImage from '@/components/purchasing/ItemImage';
import ItemDetails from '@/components/purchasing/ItemDetails';

import numberHelper from '@/composables/common/number';


export default {
    name: 'RequestItems',
    components: {
        ItemImage,
        ItemDetails
    },
    props: {
        request: {
            type: Object, 
            default() {
                return {}
            }
        },
        outlined: {
            type: Boolean,
            default: true
        },
        elevation: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const { fmtNumber } = numberHelper();

        return {
            fmtNumber
        }
    }
};
</script>