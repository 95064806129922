<template>
    <v-form
        v-model="formValid"
        ref="form"
        lazy-validation
    >
        <v-row>
            <v-col>
                <v-text-field
                    label="Department"
                    :value="value.dept"
                    :hide-details="hideDetails"
                    :rules="$rules.required"
                    :readonly="readonly"
                    :disabled="disabled"
                    @change="chgVal('dept', $event)"
                    dense
                />
            </v-col>
            <v-col>
                <v-text-field
                    label="Project"
                    :value="value.project"
                    :hide-details="hideDetails"
                    :rules="$rules.required"
                    :readonly="readonly"
                    :disabled="disabled"
                    @change="chgVal('project', $event)"
                    dense
                />
            </v-col>
            <v-col>
                <v-text-field
                    label="Activity"
                    :value="value.activity"
                    :hide-details="hideDetails"
                    :rules="$rules.required"
                    :readonly="readonly"
                    :disabled="disabled"
                    @change="chgVal('activity', $event)"
                    dense
                />
            </v-col>
            <v-col>
                <v-text-field
                    label="Initiative"
                    :value="value.initiative"
                    :hide-details="hideDetails"
                    :rules="$rules.required"
                    :readonly="readonly"
                    :disabled="disabled"
                    @change="chgVal('initiative', $event)"
                    dense
                />
            </v-col>
            <v-col>
                <v-text-field
                    label="Segment"
                    :value="value.segment"
                    :hide-details="hideDetails"
                    :rules="$rules.required"
                    :readonly="readonly"
                    :disabled="disabled"
                    @change="chgVal('segment', $event)"
                    dense
                />
            </v-col>
            <v-col
                v-if="percent && percent !== 100"
            >
                <v-text-field
                    label="Percent"
                    suffix="%"
                    :value="percent.toString().indexOf('.') > -1 ? percent.toFixed(2) : percent"
                    :key="key"
                    :hide-details="hideDetails"
                    :readonly="readonly"
                    :disabled="disabled"
                    @change="chgVal('percent', $event)"
                    @keypress="onlyNumber($event, {decimal: true})"
                    dense
                />
            </v-col>
            <v-col
                v-if="amount && percent !== 100"
            >
                <v-text-field
                    label="Amount"
                    prefix="$"
                    :value="pctNumber(amount, percent)"
                    :hide-details="hideDetails"
                    :disabled="disabled"
                    readonly
                    dense
                />
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import { ref, toRefs } from '@vue/composition-api';
import numberHelper from '@/composables/common/number';

export default {
    name: 'ChartStringInput',
    emits: ['input'],
    props: {
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        percent: {
            type: Number,
            default: null
        },
        amount: {
            type: Number,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit, refs }) {
        
        const { pctNumber, onlyNumber } = numberHelper();

        const { value: csValue } = toRefs(props);

        const formValid = ref(true);

        const cs = ref({
            dept: null,
            project: null,
            activity: null,
            initiative: null,
            segment: null
        });

        const key = ref(0);

        const chgVal = (t, v) => {
            cs.value[t] = v;
            
            const csComplete = !Object.values({...csValue.value, ...cs.value}).some(x => !x || x.percent);

            if (t === 'percent' || csComplete && refs.form.validate()) {

                if (t !== 'percent' && csValue.value.percent) {
                    cs.value = {...csValue.value, ...cs.value, percent: csValue.value.percent};
                } else if (t === 'percent') {
                    cs.value = {...csValue.value, percent: v};
                }
                
                emit('input', {
                    value: cs.value,
                    type: t
                });

                key.value += 1;
            }
        };

        const validate = () => {
            return refs.form.validate();
        };

        return {
            cs,
            chgVal,
            pctNumber,
            onlyNumber,
            formValid,
            csValue,
            key,
            validate
        }
    },
};
</script>